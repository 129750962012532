import { useState, useEffect, useRef } from "react";
import grapesjs from "grapesjs";
import { ViewerPlugin } from "../plugin/ViewerPlugin";
import Icon from "../../../components/Icon/Icon";
import { defaultCss } from "../../../helpers/builderFunctions";
import useCart from "../hooks/useCart";
import { getComponent } from "../../../helpers/builderFunctions";

const ViewerEditor = (Props) => {
    const [viewerEditor, setViewerEditor] = useState(null);
    const [imageModalOpen, setImageModalOpen] = useState(false);
    const imageModalContentRef = useRef(null);
    const [projectData, setProjectData] = useState(null);
    const { addToCart, getAllProducts } = useCart();
    const navbar = useRef(null);
    const footer = useRef(null);
    
    useEffect(() => {        
        document.addEventListener("keyup", (ev) => {
            if(ev.keyCode === 27)  
                setImageModalOpen(false);
        })
        
        if(!Props.projectData) {
            setProjectData(localStorage.getItem('gjsProject'));
            return;   
        }
        
        setProjectData(Props.projectData);
    }, [])

    useEffect(() => {
        if (viewerEditor) return;

        const e = grapesjs.init({
            container: "#viewer-editor",
            fromElement: true,
            storageManager: {
                type: 'local',
                autosave: false,
                autoload: true
            },
            projectData: projectData,
            selectorManager: {
                componentFirst: 1,
            },
            storageManager: {
                type: "local",
                options: {
                    local: { key: "gjsProject" }
                }  
            },
            deviceManager: {
                devices: [
                    {
                        width: "100vw"
                    },
                ]
            },
            pageManager: {
                pages: [
                    {
                        id: Math.random().toString(36).slice(2),
                        name: "Home page",
                        component: ``,
                    }
                ]
            },
            panels: { defaults: [] },
            plugins: [ViewerPlugin],
            pluginsOpts: {
                [ViewerPlugin]: {
                    setImageModalOpen: setImageModalOpen,
                    imageModalContentRef: imageModalContentRef,
                    addToCart: addToCart,
                    getAllProducts: getAllProducts,
                    navbar: navbar,
                    footer: footer
                }
            },
            protectedCss: defaultCss
        });

        setViewerEditor(e);
    }, [viewerEditor, projectData])

    return (
        <>
            <div className='image-modal' style={{visibility: imageModalOpen ? "visible" : "hidden"}}>
                <div className='image-modal__content' ref={imageModalContentRef}/>
                <Icon name="x" color="black" className="action" onClick={() => setImageModalOpen(false)}/>
            </div>
            <div id="viewer-editor"/>
        </>
    );
}

export default ViewerEditor;