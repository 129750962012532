import { useEffect } from "react";
import Icon from "../Icon/Icon";
import "./Modal.scss";

const Modal = ({ children, isOpen, setIsOpen, className = "" }) => {

    useEffect(() => {
        const handleEscClose = (ev) => {
            if (ev.keyCode === 27) setIsOpen(false);
        };
        document.addEventListener("keydown", handleEscClose);
        return () => document.removeEventListener("keydown", handleEscClose);
    }, [setIsOpen]);

    if (!isOpen) return null;

    return (
        <div className="modal-overlay">
            <div className={`modal grid-x align-center`}>
                <div className={className}>
                    <Icon name="x" className="actionI modal__close" onClick={() => setIsOpen(false)} />
                    {children}
                </div>
            </div>
        </div>
    );
}

export default Modal;
