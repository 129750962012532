import { useEffect, useState } from "react";

const useSelectedComponent = (editor) => {
    const [selectedComponent, setSelectedComponent] = useState(null);
    useEffect(() => {
        if(!editor) return;
        editor.on("component:select", (component) => setSelectedComponent(component))
    }, [editor])
    
    return { selectedComponent };
}

export default useSelectedComponent;