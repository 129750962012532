export const ColorOnlyStyleType = (editor) => {
    editor.StyleManager.addType('colorOnly', {
        create({ props, change }) {
            const input = document.createElement("input");
            input.className = 'gjs-input--color-only';
            input.type='color';
            input.addEventListener('change', event => change({ event }));
            input.addEventListener('input', event => change({ event, partial: true }));
            return input;
        }
    })
}