import { useContext, useRef, useState } from "react";
import { useQuery } from "react-query";
import Icon from "../../../components/Icon/Icon";
import { makeRequest } from "../../../helpers/handlers";
import { makeRequest as makeRequestToModule } from "../moduleBuilder/handlers";
import { BuilderContext, TEMPLATE_SECTION } from "../../../helpers/constants";
import Error from "../../../components/Error";
import { getNestedComponents } from "../../../helpers/builderFunctions";
import { getUser, getJWToken } from "../../../helpers/handle_token";

const CreateTemplateModal = ({ isOpen, setIsOpen }) => {
    const { editor } = useContext(BuilderContext);
    const nameRef = useRef(null);
    const typeRef = useRef(null);
    const [err, setErr] = useState("");
    const { isLoading, data } = useQuery(["getTemplateTypes"], () => {
        if (localStorage.getItem("isModuleBuilderActive"))
            return makeRequestToModule("GET", "templates", "getTypes", {});
        return makeRequest(TEMPLATE_SECTION.SECTION, TEMPLATE_SECTION.GET_TYPES)
    });
    function handleTemplateCreate(ev) {
        ev.preventDefault();
        const wrapper = editor.DomComponents.getWrapper(),
            templateClass = Math.random().toString(36).slice(2),
            projectData = getNestedComponents(editor, wrapper, templateClass);
        projectData['cssRules'] = [];


        editor.Css.getRules().forEach(rule => {
            const declaration = rule.getDeclaration(),
                parts = declaration.split('{');

            let newRuleDeclaration = parts[0] + '.' + templateClass + '{' + parts[1];
            if(rule.getAtRule())
                newRuleDeclaration = rule.getAtRule() + '{' + newRuleDeclaration + '}';

            
            const newRule = editor.CssComposer.addRules(newRuleDeclaration);
            
            projectData['cssRules'].push(newRule);
            editor.CssComposer.remove(newRule);
        });

        if (localStorage.getItem("isModuleBuilderActive"))
            return;
        if (!typeRef.current.value) {
            setErr("Choose category")
            return;
        }
        if (!nameRef.current.value) {
            setErr("Choose a name")
            return;
        }

        if (projectData['components'].length !== 1 && typeRef.current.value !== "whole_page") {
            setErr("There are none or more than one main component in this project");
            return;
        }

        makeRequest(TEMPLATE_SECTION.SECTION, TEMPLATE_SECTION.CHECK, { name: nameRef.current.value })
            // checkTemplate(nameRef.current.value)
            .then((data) => {
                if (data["code"] === 1152) {
                    nameRef.current.focus()
                    setErr(data["message"])
                    return;
                }

                let partNames = [];
                if (typeRef.current.value === 'whole_page') {
                    editor.DomComponents.getComponents().forEach(section => {
                        partNames.push(section.getAttributes()['section-name']);
                    })
                }
                
                console.log({
                    name: nameRef.current.value,
                    type: typeRef.current.value,
                    projectData: JSON.stringify(projectData),
                    partNames: partNames,
                    username: getUser(getJWToken())
                });

                if (data["code"] === 0) {
                    makeRequest(TEMPLATE_SECTION.SECTION, TEMPLATE_SECTION.CREATE, {
                        name: nameRef.current.value,
                        type: typeRef.current.value,
                        projectData: JSON.stringify(projectData),
                        partNames: partNames,
                        username: getUser(getJWToken())
                    })
                        .then(() => {
                            if (data["code"] === 0) {
                                setIsOpen(false);
                            }
                        });
                }
            })
    }



    if (!isOpen)
        return <></>;

    return (
        <form className="grid-y align-center-middle" onSubmit={(ev) => handleTemplateCreate(ev)}>
            <div className="frm grid-x align-center-middle" onClick={() => { setIsOpen(false) }}>
                <div className="frm-second make-new-template cell small-7 large-6" onClick={(ev) => ev.stopPropagation()}>
                    <div style={{marginBottom: "15px"}} className="grid-x align-right grid-margin-y">
                        <Icon className="actionI align-right" color="lightgray" tooltip="close" name="x" onClick={() => setIsOpen(false)} />
                    </div>
                    <div style={{marginBottom: "20px"}} className="grid-x grid-margin-x align-middle">
                        <div className="cell template-title">
                            Make a template
                        </div>
                        <div style={{ marginBottom: "22px" }} className="cell">
                            <div className="grid-x grid-margin-x text-right align-center-middle">
                                <div className="cell small-4">
                                    <span className="frm-title ">Name : </span>
                                </div>
                                <div className="make-new-template cell small-5">
                                    <input
                                        className="action__input"
                                        placeholder="Template name:"
                                        ref={nameRef}
                                        type="text"
                                        style={{marginBottom: "0"}}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="cell">
                            <div className="grid-x grid-margin-x align-center-middle">
                                {/* value={templateName.length > 10 ? templateName.substring(0, 10) + '...' : templateName} */}
                                <div className="cell small-4   text-right">
                                    <span className="frm-title ">Category : </span>
                                </div>
                                <select className="select__make-template cell small-5" onChange={(ev) => { typeRef.current.value = ev.target.value }} ref={typeRef}>
                                    <option value=""></option>
                                    {!isLoading &&
                                        data['data']?.map(type => {
                                            return (
                                                <option key={type["name"]} value={type['name']}>{type["name"]}</option>
                                            )
                                        })
                                    }
                                </select>

                            </div>
                        </div>
                    </div>
                    <Error message={err}></Error>
                    <div className="grid-x align-center-middle">
                        <button className="action" type="submit" style={{ marginBottom: "2%" }}>Submit</button>
                    </div>
                </div>
            </div>
        </form>
    );
}

export default CreateTemplateModal;