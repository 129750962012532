export const getSizeSector = () => ({
    name: `Size`,
    open: false,
})

export const getPositionSector = () => ({
    name: 'Position',
    open: false
})


export const getSizeSectorProperties = () => [
    {
        name: "Width",
        type: "integer",
        units: ["px", "%", "vw", "rem", "em"],
        property: "width",
        default: "100%",
        min: 0
    }, {
        name: "Height",
        type: "integer",
        units: ["px", "%", "vh", "rem", "em"],
        property: "height",
        default: "100%",
        min: 0
    }, {
        name: "Flex basis",
        type: "integer",
        units: ["%", "px"],
        property: "flex-basis",
        min: 0,
    }
]

export const getOffsetSector = () => ({
    name: `Offset`,
    open: false,
})

export const getOffsetSectorProperties = () => [
    {
        name: "Margin",
        type: "composite",
        properties: [
            {
                type: "integer",
                units: ["px", "%", "rem", "em"], 
                default: "0px", 
                property: "margin-top",
            }, 
            {
                type: "integer",
                units: ["px", "%", "rem", "em"], 
                default: "0px", 
                property: "margin-right",
            }, 
            {
                type: "integer",
                units: ["px", "%", "rem", "em"], 
                default: "0px", 
                property: "margin-bottom",
            },
            {
                type: "integer",
                units: ["px", "%", "rem", "em"], 
                default: "0px", 
                property: "margin-left",
            }, 
        ]
    },
    {
        name: "Padding",
        type: "composite",
        properties: [
            {
                type: "integer",
                units: ["px", "%", "rem", "em"], 
                default: "0px", 
                property: "padding-top",
            }, 
            {
                type: "integer",
                units: ["px", "%", "rem", "em"], 
                default: "0px", 
                property: "padding-right",
            }, 
            {
                type: "integer",
                units: ["px", "%", "rem", "em"], 
                default: "0px", 
                property: "padding-bottom",
            },
            {
                type: "integer",
                units: ["px", "%", "rem", "em"], 
                default: "0px", 
                property: "padding-left",
            }, 
        ]
    },
    
]

export const getPositionSectorProperties = () => [
    {
        name: "Position", 
        type: "select",
        property: "position",
        defaults: "relative",
        options: [
            {value: "absolute", name: "Absolute"},
            {value: "relative", name: "Relative"},
            {value: "static", name: "Static"},
            {value: "fixed", name: "Fixed"},
            {value: "sticky", name: "Sticky"}
        ]
    }, {
        name: "Z-index",
        type: "integer",
        property: "z-index",
        defaults: "auto"
    }
]