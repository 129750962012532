import { useEffect, useState } from "react"
import { getComponent } from "../../../helpers/builderFunctions";

const useAnchor = (editor, selectedComponent) => {
    const [linkId, setLinkId] = useState(null);

    useEffect(() => {
        // close modal on escape and cancel anchor selecting
        document.addEventListener("keydown", (event) => {
            switch (event.keyCode) {
                //ESC
                case 27:
                    setLinkId(null);
                    if(editor)
                        editor.Modal.close();
                    break;
                default:
                    break;
            }
        })
    }, [])

    useEffect(() => {
        if(!editor) return;

        editor.Commands.add('component:anchor', (editor) => {
            const editorModel = editor.getModel(),
                selectedComponent = editorModel.getSelected();
            setLinkId(selectedComponent['ccid']);
        });
    }, [editor])

    useEffect(() => {
        if(!linkId || !selectedComponent) return;
        const attributes = selectedComponent.getAttributes();

        let linkElement = getComponent(editor, `#${linkId}`);
        linkElement.addAttributes({ "href": `#${attributes["id"]}`, "data-href-type": "element" });
        setLinkId(null);
        editor.select(linkElement);
    }, [selectedComponent])

    return { linkId };
}

export default useAnchor;