import React from "react";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import RouteManager from "./RouteManager";
import { GoogleOAuthProvider } from "@react-oauth/google";
import ModuleRoute from "./ModuleRoute";
import useScript from "./helpers/hooks/useScript";

library.add(fas)

const App: React.FC = () => {

    useScript("https://unpkg.com/@popperjs/core@2/dist/umd/popper.min.js");
    useScript("https://unpkg.com/@popperjs/core@2"), () => {
        useScript("https://unpkg.com/tippy.js@6/dist/tippy-bundle.umd.js");
        useScript("https://unpkg.com/tippy.js@6");  
    };
    useScript("https://unpkg.com/grapesjs");
    
{/* <script src="https://unpkg.com/grapesjs"></script> */}
{/* <script src="path/to/grapesjs-style-bg.min.js"></script> */}

    return (
        <div id="App" >
            <meta name="viewport" content="width=device-width , initial-scale=1, maximum-scale=5 "/>
            <GoogleOAuthProvider clientId="424425392601-hrfcncdpfrueul5ns3818hc3367n3fbh.apps.googleusercontent.com">
                {process.env.REACT_APP_BUILDER_TYPE === "module" ? <ModuleRoute /> : <RouteManager />}
            </GoogleOAuthProvider>
        </div>
    );
}
export default App;