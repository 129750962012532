import { useState, useEffect } from "react";
import "..//Builder.scss";
import ViewerEditor from "./ViewerEditor";
import Builder from "../Builder";
import { makeRequest, updateTemplates } from "./handlers";
import "./ModuleBuilder.scss";


const ModuleBuilder = () => {
    const [publishData, setPublishData] = useState(null);
    const [projectData, setProjectData] = useState(null);
    const [isProjectEditable, setIsProjectEditable] = useState(false);

    useEffect(() => {
        // updateTemplates();
        localStorage.setItem("gjsProject", "");
        localStorage.setItem("isModuleBuilderActive", true);
        makeRequest("GET", "projects", "getData")
        .then((response) => {
            setIsProjectEditable(!!response['data']['isEditable']);
            if(response["code"] !== 0) {
                alert(response["message"]);
                return;
            }
            
            const storageDataKey = !!response['data']['isEditable'] ? 'gjsProject' : 'publishData';

            localStorage.setItem('gjsProject', JSON.stringify(response['data'][storageDataKey]));
            if(response['data']['pageUrls'])
                localStorage.setItem('pageUrls', JSON.stringify(response['data']['pageUrls']));
            setProjectData(response['data']['gjsProject']);
            setPublishData(response['data']['publishData']);
        })
    }, [])

    if(!publishData && !isProjectEditable) return <></>;

    if(!isProjectEditable)
        return <ViewerEditor projectData={publishData}/>;
    
    return <Builder builderMode={"advanced"} projectName={"Project"}/>
}

export default ModuleBuilder;