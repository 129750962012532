import UndoManager from "../UndoManager";

export const UndoPlugin = (editor, { undoManager }) => {
    return;
    //? Don't add the rendering of the site to the undo
    editor.on("load", () => {
        undoManager.current = new UndoManager(editor);
        editor.Commands.add("tagndrop:undo", () => undoManager.current.revertLastChange());
        editor.Commands.add("tagndrop:redo", () => undoManager.current.revertLastChange("redo"));

        editor.on("component:update", (component) => console.log('update'));

        editor.on("component:mount", (component) => {
            //! Change text triggers mount
            undoManager.current.add({
                type: "add",
                component: component
            })
        })

        editor.on("component:remove", (component) => {
            undoManager.current.add({ 
                type: "remove",
                component: component,
                collection: component.collection,
            })
        })

        editor.on("page:add", page => undoManager.addPage(page['id']));
        editor.on("page:remove", page => undoManager.removePage(page['id']));
    })
}