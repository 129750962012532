import React, { useEffect, useState, useRef } from "react";
import { useQuery } from "react-query";
import { makeRequest } from "../../../../helpers/handlers";
import SectionBlock from "../SectionManager/SectionBlock";
import Loader from "../../../../components/Loader/Loader";
import { makeRequest as makeRequestToModule} from "../../moduleBuilder/handlers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TEMPLATE_SECTION } from "../../../../helpers/constants";

const ChooseSectionManager = ({ sectionType, selectedSection, setSelectedSection }) => {
    const [comparable, setComparable] = useState("");
    const [searchTerm, setSearchTerm] = useState("");
    const [templates, setTemplates] = useState([]);
    const [noSectionsMessage, setNoSectionsMessage] = useState("");
    const userSearch = useRef(null);

    const { isLoading, data, refetch } = useQuery(["getTemplates", comparable, sectionType], () => {
        if (!localStorage.getItem("isModuleBuilderActive"))
            return makeRequest(TEMPLATE_SECTION.SECTION, TEMPLATE_SECTION.GET, { comparable: `%${comparable.toLowerCase()}%`, type: sectionType});
        return makeRequestToModule("GET", "templates", "get", { type: sectionType });
    });

    useEffect(() => {
        refetch();
    }, [comparable, sectionType]);

    useEffect(() => {
        if (!data) return;
        if(data["code"] !== 0) {
            alert(data["message"]);
            return;
        }
        if (localStorage.getItem("isModuleBuilderActive"))
            setTemplates(data["data"]);
        else
            setTemplates(data["data"]["templates"]);
    }, [data]);

    useEffect(() => {
        setSelectedSection(templates[0]);
    }, [templates])
    
    const handleSearch = (e) => {
        e.preventDefault();
        if (userSearch.current) {
            setSearchTerm(userSearch.current.value);
            setNoSectionsMessage("");
        }
    };

    const filteredTemplates = templates.filter((template) => {
        return template.name.toLowerCase().includes(searchTerm.toLowerCase());
    });

    if (!sectionType)
        return <></>;

    return (
        <div className="grid-x grid-margin-y grid-margin-x align-center-middle text-center">
            <div className="cell small-12 medium-10">
                <h4 className="bold grid-y">
                    CHOOSE A
                    <span>
                        {sectionType === 'whole_page' ? 'PAGE' : sectionType.toUpperCase()} TEMPLATE
                    </span>
                    TO ADD TO YOUR SITE
                </h4>
                {/* <form onSubmit={handleSearch}>
                    <div className="grid-x align-center align-center-middle text-center">
                        <input
                            className="cell small-10 search__input"
                            ref={userSearch}
                            maxLength={50}
                            placeholder={`Search for ${sectionType} template`}
                        />
                        <button type="submit" className="button cell small-2 search__button">
                            <FontAwesomeIcon className="" id="search-icon" icon={["fas", "search"]} />
                        </button>
                    </div>
                </form> */}
            </div>
            <div className="cell">
                <div style={{overflowY: "auto" }} className="grid-x align-center section__container">
                    <div style={{ maxHeight: "100%" }} className="cell small-12 medium-12">
                        <div className="grid-x align-center align-middle advanced--holder">
                            {isLoading ? (
                                <Loader />
                            ) : filteredTemplates.length === 0 ? (
                                <p style={{ color: "red" }}>There are no sections with that name</p>
                            ) : (
                                filteredTemplates.map((section, index) => {
                                    // let projectData = section["project_data"];
                                    if (typeof section['project_data'] === "string")
                                        section['project_data'] = JSON.parse(section['project_data'])
                                    
                                    return (
                                        <SectionBlock
                                            key={index}
                                            selectedSection={selectedSection}
                                            setSelectedSection={setSelectedSection}
                                            data={section}
                                            // projectData={projectData}
                                            // name={section["name"]}
                                            // sectionType={section['type_name']}
                                        />
                                    );
                                })
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ChooseSectionManager;
