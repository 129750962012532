import { useTimer } from 'react-timer-hook';
import { useNavigate } from 'react-router';
import "./timer.scss";
import { useEffect, useState } from 'react';
import { makeRequest } from '../../helpers/handlers';
import Cookies from 'js-cookie';
import { PROJECT_SECTION } from '../../helpers/constants';

const DemoTimer = () => {
    const [distance, setDistance] = useState(0);
    const time = new Date();
    time.setSeconds(time.getSeconds() + distance);
    const navigate = useNavigate();
    const Timer = ({ expiryTimestamp }) => {
        const {
            seconds,
            minutes,
            hours,
            isRunning
        } = useTimer({ expiryTimestamp });
        return <>
            {String(hours).padStart(2, 0)}:{String(minutes).padStart(2, 0)}:{String(seconds).padStart(2, 0)}
        </>;
    }


    useEffect(() => {
        if (!Cookies.get("temporarySession")) return;
        makeRequest(PROJECT_SECTION.SECTION, PROJECT_SECTION.GET_DEMO_DATA, { sessionId: Cookies.get("temporarySession") })
            .then((res) => {
                if (res["code"] !== 0) {
                    Cookies.remove('temporarySession');
                    navigate("/", { state: { err: res["message"] } });
                }
                localStorage.setItem("gjsProject", res['data']['projectData'])
                Cookies.set("demoCreationTime", res['data']['expTime']);
            })
            .catch((err) => {
                navigate("/error", { state: { err: err } });
            })
        setInterval(() => {
            const currTime = new Date().getTime(),
                currDistance = Cookies.get("demoCreationTime") - Math.floor(currTime / 1000);
            setDistance(currDistance);
            if (currDistance <= 0) {
                Cookies.remove("temporarySession");
                navigate("/", { state: { err: "Session expired! Progress lost." } });
            }
        }, 1000)
    }, []);

    if (!Cookies.get("temporarySession") || distance === 0) return <></>;

    return (
        <div className="project-header">
            Time left: <Timer expiryTimestamp={time} />
        </div>
    );
    // const [sessionNbf, setSessionNbf] = useState();
    // const timeOnCreation = new Date(sessionNbf * 1000);
    // const timeOnCreationIsSeconds = Math.floor(timeOnCreation.getTime() / 1000);
    // timeOnCreation.setSeconds(sessionNbf - timeOnCreationIsSeconds);
    // const {
    //     seconds,
    //     minutes,
    //     hours,
    //     isRunning
    // } = useTimer({
    //     timeOnCreation, onExpire: () => {
    //         localStorage.clear();
    //         Cookies.remove("temporarySession");
    //         navigate("/", { state: { err: "Session expired! Progress lost." } });
    //     }
    // });
    // const [isClosed, setIsClosed] = useState(false);
    // const navigate = useNavigate();
    // const formattedHours = String(hours).padStart(2, '0');
    // const formattedMinutes = String(minutes).padStart(2, '0');
    // const formattedSeconds = String(seconds).padStart(2, '0');
    // const [hasRendered, setHasRendered] = useState(false);

    // useEffect(() => {
    //     getDemoNbf(Cookies.get("temporarySession"))
    //         .then((res) => {
    //             if (res["code"] !== 0) {
    //                 navigate("/error", { state: { err: res["message"] } });
    //             }

    //             setSessionNbf(res["data"]);
    //         })
    //         .catch((err) => {
    //             navigate("/error", { state: { err: err } });
    //         })

    //     const timeoutId = setTimeout(() => {
    //         setHasRendered(true);
    //     }, 2000);

    //     return () => clearTimeout(timeoutId);
    // }, [])

    // if (hours > 0 || minutes > 2) {
    //     return (
    //         <div>
    //                 <div className='clock_box'>
    //                     <span>{formattedHours}</span>:<span>{formattedMinutes}</span>:<span>{formattedSeconds}</span>
    //                 </div>
    //             </div>
    //         );
    // }

    // return (
    //     <div>
    //         <div>
    //             {!isClosed &&
    //                 <div className="clock__alert--modal grid-x align-center align-middle">
    //                     <h1 className="cell text-center red bold">
    //                         Your session is about to expire
    //                     </h1>
    //                     <h3 className="cell text-center">
    //                         You have <span className='red'>{formattedMinutes} </span>minutes  and <span className='red'>{formattedSeconds} </span>seconds  left
    //                     </h3>
    //                     <button onClick={() => { setIsClosed(true) }} className="cell large-3 button">Continue</button>
    //                 </div>
    //             }
    //             <div className='clock_box'>
    //                 <span>{formattedHours}</span>:<span>{formattedMinutes}</span>:<span>{formattedSeconds}</span>
    //             </div>
    //         </div>

    //     </div>
    // );
}

export default DemoTimer;
