import "./Icon.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconPrefix } from "@fortawesome/fontawesome-svg-core";
import { IconName } from "@fortawesome/fontawesome-svg-core";

interface Props{
    id?: string,
    color: string,
    tooltip?: string,
    name: IconName,
    onClick?: () => void,
    prefix?: IconPrefix,
    className?: string
}

const Icon:React.FC<Props> = (Props) => {
    return(
        <div id={Props.id} className="icon" style={{color: Props.color}} onClick={Props.onClick} >
            <span className="icon-info">{Props.tooltip}</span>
            <FontAwesomeIcon style={{display:"grid"}} className={"icons " + Props.className } icon={[(Props.prefix ? Props.prefix : "fas"), Props.name]} />
        </div>
    );
}

export default Icon;