import { useContext, useState, useEffect } from "react";
import { BuilderContext } from "../../../../../helpers/constants";
import Icon from "../../../../../components/Icon/Icon";

const BuilderDeviceButtons = () => {
    const { editor } = useContext(BuilderContext);
    const [activeButton, setActiveButton] = useState('desktop');

    useEffect(() => {
        if (editor) {
            editor.setDevice('Desktop');
        }
    }, [editor]);

    const iconClass = (buttonId) => (activeButton === buttonId ? 'action_color' : '');
    const handleButtonClick = (device, buttonId) => {
        if (editor) {
            editor.setDevice(device);
            setActiveButton(buttonId);
        }
    };

    return (
        <div className={`cell small-3 medium-offset-${localStorage.getItem("isModuleBuilderActive") ? "0" : "0"}`} id="js-builder__devices">
            {/* small - middle */}
            <div className="cell small-1 grid-x align-center-middle builder--center__dropdown">
                <span className="square">
                    <a className="builder--center__dropdown--toggle after before">
                        Select Device<span className="builder__dropdown--arrow-right">&#9656;</span>
                    </a>
                </span>
                <div className="builder--center__dropdown--menu">
                    <button
                        id="default"
                        className="device__icon"
                        aria-label="desktop"
                        onClick={() => handleButtonClick("Desktop", "desktop")}
                    >
                        <Icon
                            className={`actionI_screen-size ${iconClass('desktop')}`}
                            color="lightgray"
                            name="desktop"
                            tooltip="Desktop"
                        />
                    </button>
                    <button
                        className="device__icon"
                        aria-label="tablet"
                        onClick={() => handleButtonClick("Tablet", "tablet")}
                    >
                        <Icon
                            className={`actionI_screen-size ${iconClass('tablet')}`}
                            color="lightgray"
                            name="tablet"
                            tooltip="Tablet"
                        />
                    </button>
                    <button
                        className="device__icon"
                        aria-label="mobile"
                        onClick={() => handleButtonClick("Phone", "mobile")}
                    >
                        <Icon
                            className={`actionI_screen-size ${iconClass('mobile')}`}
                            color="lightgray"
                            name="mobile"
                            tooltip="Mobile"
                        />
                    </button>
                </div>
            </div>
            {/* large  */}
            <div className="cell large-2 grid-x align-center-middle builder__center__No-Dropdown">
                <button
                    id="default"
                    className="device__icon"
                    aria-label="desktop"
                    onClick={() => handleButtonClick("Desktop", "desktop")}
                >
                    <Icon
                        className={`actionI_screen-size ${iconClass('desktop')}`}
                        color="lightgray"
                        name="desktop"
                        tooltip="Desktop" />
                </button>
                <button
                    className="device__icon"
                    aria-label="tablet"
                    onClick={() => handleButtonClick("Tablet", "tablet")}
                >
                    <Icon
                        className={`actionI_screen-size ${iconClass('tablet')}`}
                        color="lightgray"
                        name="tablet"
                        tooltip="Tablet"
                    />
                </button>
                <button
                    className="device__icon"
                    aria-label="mobile"
                    onClick={() => handleButtonClick("Phone", "mobile")}
                >
                    <Icon
                        className={`actionI_screen-size ${iconClass('mobile')}`}
                        color="lightgray"
                        name="mobile"
                        tooltip="Mobile" />
                </button>
            </div>
        </div>
    );
}
export default BuilderDeviceButtons;