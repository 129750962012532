const useCookies = () => {
    function setCookie(key, value) {
        const duration = 7;
        const date = new Date();
        date.setTime(date.getTime() + (duration * 24 * 60 * 60 * 1000));
        let expires = "expires="+ date.toUTCString();
        document.cookie = key + "=" + value + ";" + expires + ";path=/";
    }

    function getCookie(key) {
        key += "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let cookieArr = decodedCookie.split(';');
        for(let i = 0; i < cookieArr.length; i++) {
            let currCookie = cookieArr[i];
            while (currCookie.charAt(0) == ' ') {
                currCookie = currCookie.substring(1);
            }
            if (currCookie.indexOf(key) == 0) {
                return currCookie.substring(key.length, currCookie.length);
            }
        }

        return "";
    }

    return { setCookie, getCookie };
}

export default useCookies;