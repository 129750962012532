import "./Builder.scss";
import "tippy.js/dist/tippy.css";
import { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router";
import { isUserAdmin, getJWToken } from "../../helpers/handle_token";
import { BuilderContext } from "../../helpers/constants";
import useSelectedComponent from "./hooks/useSelectedComponent";
import BuilderTopSection from "./page-components/BuilderTopSection/BuilderTopSection";
import BasicBuilder from "./BasicBuilder";
import AdvancedBuilder from "./AdvancedBuilder";
import AdminBuilder from "./AdminBuilder";
import BuilderTutorial from "./page-components/BuilderTutorial/BuilderTutorial";
import ReplaceElementModal from "./page-components/ReplaceElementModal/ReplaceElementModal";
import BugReportModal from "./page-components/BugReportModal/BugReportModal";
import Icon from "../../components/Icon/Icon";
import BuilderPages from "./page-components/BuilderTopSection/components/BuilderPages";


const Builder = (props) => {
    const [editor, setEditor] = useState(null);
    const [sectionOptions, setSectionOptions] = useState(null);
    const [manager, setManager] = useState("");
    const [isAdmin, setIsAdmin] = useState(false);
    const { selectedComponent } = useSelectedComponent(editor);
    const [builderMode, setBuilderMode] = useState(null);
    const [projectName, setProjectName] = useState("");
    const [replacingElement, setReplacingElement] = useState(false);
    const [currentTutorialPage, setCurrentTutorialPage] = useState(-1);
    const [bugReportModalVisible, setBugReportModalVisible] = useState(false);
    const navbar = useRef(null);
    const footer = useRef(null);
    const location = useLocation();
    const navigate = useNavigate();
    const undoManager = useRef(null);


    function changeManager(newManager) {
        if (managerRef.current === newManager)
            newManager = "";
        setManager(newManager);
    }

    const managerRef = useRef(manager);

    useEffect(() => {
        managerRef.current = manager;
    }, [manager])

    useEffect(() => {
        if (Object.keys(props).length === 0 && location.state == null)
            navigate("/projects");
        const builderModeSetter = props.builderMode != null ? props.builderMode : location.state.builderMode,
            projectNameSetter = props.projectName != null ? props.projectName : location.state.projectName;

        //TODO: check localStorage
        if (!builderModeSetter || !projectNameSetter)
            navigate("/projects")

        setBuilderMode(builderModeSetter);
        setProjectName(projectNameSetter);

        setIsAdmin(isUserAdmin(getJWToken()));
    }, [props, location, navigate]);

    return (
        <BuilderContext.Provider value={{
            editor, setEditor,
            manager, changeManager,
            managerRef,
            sectionOptions, setSectionOptions,
            selectedComponent,
            projectName, setProjectName,
            replacingElement, setReplacingElement,
            builderMode, isAdmin,
            currentTutorialPage, setCurrentTutorialPage,
            bugReportModalVisible, setBugReportModalVisible,
            navbar, footer,
            undoManager
        }}>
            <div className="builder">

                <BuilderPages />
                <BuilderTopSection />

                {builderMode !== "admin"
                    &&
                    <BugReportModal
                        visible={bugReportModalVisible}
                        onClose={() => setBugReportModalVisible(false)}
                    />
                }
                <ReplaceElementModal />

                <div className="builder-container" style={{ userSelect: "none" }}>
                    <div className="grid-x" style={{ position: "relative", display: "flex", flexWrap: "nowrap", height: "100%", maxWidth: "100%" }}>
                        {/* {builderMode === "basic" && <BasicBuilder />} */}
                        {builderMode === "advanced" && <AdvancedBuilder />}
                        {builderMode === "admin" && <AdminBuilder />}
                    </div>
                </div>
                <BuilderTutorial type='topSection' currentPage={currentTutorialPage} setCurrentPage={setCurrentTutorialPage} />
                {builderMode !== "admin" && (
                    <div
                        className="bug-report-button"
                        onClick={() => setBugReportModalVisible(prevVisible => !prevVisible)}
                    >
                        <Icon className="icon" name="comment-medical" />
                        <span className="text">Send feedback</span>
                    </div>
                )}


            </div >
        </BuilderContext.Provider>
    );
}

export default Builder;
