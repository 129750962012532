import ReactDOMServer from "react-dom/server";
import Icon from '../../../components/Icon/Icon';
import { appendAddSectionPluses, getComponent } from "../../../helpers/builderFunctions";

export const PanelPlugin = (editor, { builderMode, managerRef, changeManager, undoManager }) => {
    const panelManager = editor.Panels;
    
    panelManager.addPanel({
        el: "#js-builder__panels--utility",
        id: "js-builder__panels--utility",
        visible: true,
        buttons: [
            {
                id: "js-builder__utility--show-code",
                label: ReactDOMServer.renderToString(<Icon className="actionI" color="lightgray" name="code" tooltip="Show code" />),
                command: "core:open-code",
                class: "cell small-4",
            },
            {
                id: "js-builder__utility--show-layers",
                label: ReactDOMServer.renderToString(<Icon className="actionI" color='lightgray' name='layer-group' tooltip='Show layers' />),
                command: () => {
                    if (managerRef.current === "layerManager") {
                        changeManager("");
                        return;
                    }
                    changeManager("layerManager")
                },
                class: "cell small-4",
            }, {
                id: "js-builder__actions--clear",
                label: ReactDOMServer.renderToString(<Icon className="actionI" color="white" name="trash" tooltip="Clear canvas" />),
                command: () => {
                    if (!window.confirm("Do you want to clear the canvas"))
                    return;
                                
                const bodyPlaceholder = getComponent(editor, ".bodyPlaceholder");
                if(!bodyPlaceholder) {
                    editor.DomComponents.clear();
                    editor.CssComposer.clear();
                    editor.store();
                    return;
                }
                bodyPlaceholder.empty();
                appendAddSectionPluses(editor, bodyPlaceholder);
                
                
                },
                class: "cell small-4",
            }, 
        ]
    });
    
    panelManager.addPanel({
        el: "#js-builder__panels--utility__copy",
        id: "js-builder__panels--utility__copy",
        visible: true,
        buttons: [
            {
                id: 'js-builder__utility--show-layers', 
                label: ReactDOMServer.renderToString(<Icon className="actionI" color='lightgray' name='layer-group' tooltip='Show layers' />),
                command: () => {
                    if (managerRef.current === "layerManager") {
                        changeManager("");
                        return;
                    }
                    changeManager("layerManager")
                },
                class: 'cell small-4'
            },
            {
                // id: "js-builder__utility--show-code",
                // label: ReactDOMServer.renderToString(<Icon className="actionI" color="lightgray" name="code" tooltip="Show code" />),
                // command: "core:open-code",
                // class: "cell small-4",
            },
            {
                // id: "js-builder__devices--zip",
                // label: ReactDOMServer.renderToString(<Icon className="actionI" tooltip="Download ZIP" color="lightgray" name="file-zipper" />),
                // class: "cell small-4",
                // command: () => {
                //     if (!localStorage.getItem("projectId")) {
                //         return alert("Project needs to be saved first before downloading it.")
                //     }
                    
                //     makeRequest(PROJECT_SECTION.SECTION, PROJECT_SECTION.GENERATE_FILE, {html: editor.getHtml(), css: editor.getCss(), projectId: localStorage.getItem("projectId") })
                //     .then((response) => {
                //         const zip = new JSZip();
                //         zip.file('index.html', response["data"]["html"]);
                //         zip.file('index.css', response["data"]["css"]);
                //         zip.file('index.js', response["data"]["js"]);

                //         zip.generateAsync({ type: 'blob' })
                //             .then(function (blob) {
                //                 saveAs(blob, `${response["data"]["name"]}.zip`);
                //             });
                //     })
                // }
            }, {
                // id: "js-builder_utility--preview",
                // label: ReactDOMServer.renderToString(<Icon className="actionI" color="lightgray" name="eye" tooltip="Preview site" />),
                // command: "preview"
            }, {
                id: "js-builder__actions--clear",
                label: ReactDOMServer.renderToString(<Icon className="actionI" color="white" name="trash" tooltip="Clear canvas" />),
                class: "cell small-4",
                command: () => {
                    if (!window.confirm("Do you want to clear the canvas"))
                        return;
                    const bodyPlaceholder = getComponent(editor, ".bodyPlaceholder");
                    if(!bodyPlaceholder) {
                        editor.CssComposer.clear();
                        editor.DomComponents.clear();
                        editor.store();
                        return;
                    }
                    bodyPlaceholder.empty();
                    appendAddSectionPluses(editor, bodyPlaceholder);
                }
            },
        ]
    });


    //DA NE SE MAHA !!!!!!!!!!!!!!!!!!!!!!!

    // panelManager.addPanel({
    //     el: "#builder__panels--devices",
    //     id: "builder__panels--devices",
    //     visible: true,
    //     buttons: [{
    //         id: "js-builder__devices--desktop",
    //         label: ReactDOMServer.renderToString(<Icon className="actionI" color="lightgray" name="desktop" tooltip="Desktop"/>),
    //         command: () => editor.setDevice("Desktop")
    //     }, {
    //         id: "js-builder__devices--tablet",
    //         label: ReactDOMServer.renderToString(<Icon className="actionI" color="lightgray" name="tablet" tooltip="Tablet"/>),
    //         command: () => editor.setDevice("Tablet")
    //     }, {
    //         id: "js-builder__devices--phone",
    //         label: ReactDOMServer.renderToString(<Icon className="actionI large-3 " color="lightgray" name="mobile" tooltip="Mobile"/>),
    //         command: () => editor.setDevice("Phone")
    //     }]
    // },)

    panelManager.addPanel({
        el: "#js-builder__panels--actions",
        id: "js-builder__panels--actions",
        visible: true,
        buttons: [
            builderMode && {
                id: "js-builder__actions--show-containers",
                label: ReactDOMServer.renderToString(<Icon className="actionI" color="white" name="border-all" tooltip="Show borders" />),
                command: "sw-visibility"
            }, {
            // id: "js-builder__actions--lock",
            // label: ReactDOMServer.renderToString(<Icon className="actionI" color="white" name="lock" tooltip="Lock" />),
            // command: () => {
            //     const selected = editor.getSelected();
            //     selected.set("draggable", !selected.get("draggable"));
            // }
        }, {
            id: "js-builder__actions--undo",
            label: ReactDOMServer.renderToString(<Icon className="actionI actionUndoButton" color="white" name="undo" tooltip="Undo" />),
            command: "core:undo"
        }, {
            id: "js-builder__actions--redo",
            label: ReactDOMServer.renderToString(<Icon className="actionI actionRedoButton" color="white" name="redo" tooltip="Redo" />),
            command: "core:redo"
        }]
    })

    //copy
    panelManager.addPanel({
        el: "#js-builder__panels--actions__copy",
        id: "js-builder__panels--actions__copy",
        visible: true,
        buttons: [{
            id: "js-builder__actions--show-containers",
            label: ReactDOMServer.renderToString(<Icon className="actionI" color="white" name="border-all" tooltip="Show borders" />),
            command: "sw-visibility"
        }, {
            // id: "js-builder__actions--lock",
            // label: ReactDOMServer.renderToString(<Icon className="actionI" color="white" name="lock" tooltip="Lock" />),
            // command: () => {
            //     const selected = editor.getSelected();
            //     selected.set("draggable", !selected.get("draggable"));
            // }
        }, {
            id: "js-builder__actions--undo",
            label: ReactDOMServer.renderToString(<Icon className="actionI" color="white" name="undo" tooltip="Undo" />),
            command: "core:undo"
        }, {
            id: "js-builder__actions--redo",
            label: ReactDOMServer.renderToString(<Icon className="actionI" color="white" name="redo" tooltip="Redo" />),
            command: "core:redo"
        }]
    })

}
