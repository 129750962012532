import DemoTimer from "../../../../components/DemoTimer/DemoTimer";
import "./BuilderTopSection.scss";
import { BuilderContext } from "../../../../helpers/constants";
import ExitButton from "./components/ExitButton";
import BuilderDeviceButtons from "./components/BuilderDeviceButtons";
import BuilderActionButtons from "./components/BuilderActionButtons";
import React, { useContext, useState } from "react";
import PublishButtons from "./components/PublishButtons";
import TagNDropLogo from "../../../../images/TagNDrop_logo.png";


const BuilderTopSection = () => {
    const { editor } = useContext(BuilderContext);



    return (
        <div className="builder__top-section" style={{ userSelect: "none" }}>
            <div id="builder__panels" className="grid-x gjs-two-bg align-center-middle">
                <div className="timer--builder">
                    <DemoTimer />
                </div>
                <img className="cell small-1 tnd__logo" src = {TagNDropLogo} alt="a" />
                <BuilderDeviceButtons />
                <BuilderActionButtons />
                <div className="cell show-for-large small-2 medium-2 grid-margin-x grid-margin-y">
                    <PublishButtons isDropdown={false} />
                </div>
                <ExitButton editor={editor} />
            </div>
        </div>
    );
}

export default BuilderTopSection;
