import { useState, useEffect, useContext } from "react";
import NewSectionModal from "./page-components/PageCreation/NewSectionModal";
import "./Builder.scss";
import BuilderContainer from "./page-components/BuilderContainer";
import Icon from "../../components/Icon/Icon";
import { BuilderContext } from "../../helpers/constants";
import { addLinkToNavBar, setLayer, appendAddSectionPluses } from "../../helpers/builderFunctions";

const AdvancedBuilder = () => {
    const { editor, setSectionOptions, selectedComponent, manager, changeManager, navbar, footer } = useContext(BuilderContext);
    const [sectionPlaceholder, setSectionPlaceholder] = useState({})
    
    function addClickToAddSectionButton() {
        const addSectionButton = editor.addComponents({
            editable: false,
            droppable: false,
            tagName: "div",
            attributes: {
                class: "addSectionButton",
                "data-sectionType": "body",
                "data-dont-show": "all",
                'data-type': 'button',
                'data-selectable': true,
            },
            components: {
                tagName: "h1",
                components: {
                    type: "text",
                    attributes: {
                        "data-unhoverable": true
                    },
                    components: "+ Click to add a new section"
                }
            },
        }, {
            at: editor.DomComponents.getComponents().models.length - 1
        })[0]        
        
        editor.LayerManager.setName(addSectionButton, "+ Click to add a new section");
        setLayer(addSectionButton);

        return addSectionButton;
    }

    function setPlaceholders() {
        editor.UndoManager.skip(() => {
            const components = editor.DomComponents.getComponents(),
            placeholderNames = [
                "navbar",
                "body",
                "footer"
            ];
            for (let index in placeholderNames) {
                const currName = placeholderNames[index];
                //? Checks whether the placeholder exists or should be added
                sectionPlaceholder[currName] = components.filter((component) => component.getClasses().includes(`${currName}Placeholder`))[0];
                if(sectionPlaceholder[currName]) {
                    continue;
                }
                //? Adds the placeholder if it doesn't exist
                sectionPlaceholder[currName] = editor.addComponents({
                    tagName: "div",
                    attributes: {
                        class: `${currName}Placeholder`,
                        'data-type': 'placeholder',
                    },
                    components: []
                })[0];
                //? Add the plusBelow and plusAbove buttons
                if(currName === 'body') {
                    appendAddSectionPluses(editor, sectionPlaceholder[currName]);
                }
            }
            setSectionPlaceholder(sectionPlaceholder);

            let clickToAddSectionButton = components.filter((component) => component.getClasses().includes(`addSectionButton`))[0];
            if(!clickToAddSectionButton)
                clickToAddSectionButton = addClickToAddSectionButton();

            // console.log(clickToAddSectionButton);

            const isNavbarEmpty = sectionPlaceholder['navbar'].get('components').length === 0,
                isBodyEmpty = sectionPlaceholder['body'].get('components').length == 2,
                isFooterEmpty = sectionPlaceholder['footer'].get('components').length === 0;
            if(isNavbarEmpty && isBodyEmpty && isFooterEmpty)
                setSectionOptions({ type: "whole_page" });
        });
    }

    useEffect(() => {
        if (!editor)
            return;
        editor.on("page:select", (page) => {
            setPlaceholders();
            //? add navbar and/or footer if they are not present on the page
            editor.UndoManager.skip(() => {
                if(navbar.current && sectionPlaceholder['navbar']) {
                    sectionPlaceholder['navbar'].empty();
                    sectionPlaceholder['navbar'].append(navbar.current);
                }
        
                if(footer.current && sectionPlaceholder['footer']) {
                    sectionPlaceholder['footer'].empty();
                    sectionPlaceholder['footer'].append(footer.current);
                }
            })
        })
        editor.on("page:remove:before", (page) => {
            if(!navbar.current)
                return;
            editor.UndoManager.skip(() => {
                //? remove the link that points to the deleted page
                const links = navbar.current.find('a'),
                    linkToDelete = links.filter((link) => link.getAttributes()['href'] === `page:${page['attributes']['id']}`)[0];
                
                //? remove the links' cell
                if(linkToDelete)
                    linkToDelete.parent().remove();
                console.log(linkToDelete);
            
                const pageUrls = JSON.parse(localStorage.getItem("pageUrls"));
                if(!pageUrls) return;
    
                //? remove the current page url from local storage
                const validUrls = pageUrls.filter((url) => url['id'] !== page['id']);
                localStorage.setItem("pageUrls", JSON.stringify(validUrls));
            })
        })
        editor.on("page:add", (page) => {
            if(!navbar.current)
                return;
            const pageId = page['attributes']['id'];
            editor.UndoManager.skip(() => {
                addLinkToNavBar(navbar.current, page['attributes']['name'], pageId);
            })
        })
        editor.on("page:update", (page) => {
            if(!navbar.current)
                return;

            //? change the text inside the link that points to the updated page
            const links = navbar.current.find('a'),
                linkToChange = links.filter((link) => link.getAttributes()['href'] == `page:${page['attributes']['id']}`)[0];

            if(!linkToChange) return;
            editor.UndoManager.skip(() => {
                linkToChange.empty();
                linkToChange.append(page['attributes']['name']);
            })
        })
        editor.on('load', () => {
            setPlaceholders();

            //? Check every page for a navbar and a footer
            const pages = editor.Pages.getAll();
            let index = 0;
            do{
                editor.Pages.select(pages[index]['id']);
                
                const navbarPlaceholderChildren = sectionPlaceholder['navbar'].components();
                if(navbarPlaceholderChildren.length > 0 && !navbar.current)
                    navbar.current = navbarPlaceholderChildren['models'][0];
                
                const footerPlaceholderChildren = sectionPlaceholder['footer'].components();
                if(footerPlaceholderChildren.length > 0 && !footer.current)
                    footer.current = footerPlaceholderChildren['models'][0];

                index += 1;
            }
            while(index < pages.length && (!navbar.current || !footer.current));

            editor.Pages.select(pages[0]['id']);
        })
    }, [editor])

    return (
        <>
            <NewSectionModal sectionPlaceholder={sectionPlaceholder} />
            <div style={{ width: "100%"}}>
                <BuilderContainer />
            </div>
            <div style={{ width: (!manager ? "0vw" : "30vw"), height: "100%", flexWrap: "nowrap", margin: 0, padding: 0 }} className="builder__section-manager grid-x text-center align-center align-middle">
                <div style={{ width: (!manager ? "0%" : "100%"), height: "100%", overflow: "auto" }}>
                    <div className="builder-manager" style={{ display: manager === "styleManager" ? "block" : "none" }}>
                        <h4 style={{ color: "white" }}>
                            Selected:
                            <span className="selected-component-type">
                                {selectedComponent && (
                                    selectedComponent?.getAttributes()["section-name"] ? (
                                        `${selectedComponent?.getAttributes()["section-name"]}`
                                    ) : (
                                        `${selectedComponent?.get("type")}`
                                    )
                                )}
                            </span>
                        </h4>
                        <div id="js-builder__settings-manager" />
                        <div id="js-builder__style-manager" />
                    </div>
                    <div style={{ display: manager === "layerManager" ? "block" : "none" }} id="js-builder__layer-manager" className="builder__manager" />
                    <div className="side-bar__arrow side-bar__grid--elements">
                        <Icon style="transition: all 0.3s; align-self: flex-end;" name="arrow-left" className={`${(manager === "blockManager" || manager === "sectionManager" || manager === "styleManager" || manager === "settingsManager" || manager === "layerManager" ? "arrow__rotate__to__right" : "")} side-bar__icon arrow`} onClick={() => {
                                changeManager("");
                        }} />
                    </div>
                </div>
            </div>
        </>
    );
}

export default AdvancedBuilder;