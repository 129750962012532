import { useState, useEffect } from "react";
import { makeRequest } from "../../../../../helpers/handlers";
import { PROJECT_SECTION } from "../../../../../helpers/constants";
import RelogModal from "../../RelogModal";
import Cookies from "js-cookie";
import { isTokenExpired, getJWToken, isRegistered, getUser } from "../../../../../helpers/handle_token";
import { useNavigate } from "react-router";

const ExitButton = ({ editor }) => {
    const [isHovered, setIsHovered] = useState(false);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const navigate = useNavigate();

    function exitProject() {
        if(!editor)
            return;
        editor.select(null);
        if (localStorage.getItem("isModuleBuilderActive")) {
            window.location.reload();
            return;
        }

        if (isRegistered(getJWToken())) {
            localStorage.clear();
            navigate("/projects");
            return;
        }
        navigate("/");
    }

    useEffect(() => {
        window.addEventListener("popstate", (event) => {
            makeRequest(PROJECT_SECTION.SECTION, PROJECT_SECTION.SAVE, {
                projectData: localStorage.getItem("gjsProject"), 
                projectId: localStorage.getItem('projectId'),
                username: getUser(getJWToken())
            });
            exitProject();
        })
    }, [])

    
    useEffect(() => {    
        if (localStorage.getItem("isModuleBuilderActive") ||
            !isTokenExpired(getJWToken()) || 
            Cookies.get("temporarySession")) {
            return;
        }
        setIsHovered(true);
        setIsButtonDisabled(true);
    }, [getJWToken(), isTokenExpired(getJWToken()), Cookies.get("temporarySession")]);
    
    if (localStorage.getItem("isModuleBuilderActive")) return <></>;

    return (
        <div className="cell small-2 medium-1 ">
            <div className="grid-x align-center-middle">
                <button className="action-inverted" disabled={isButtonDisabled} onClick={() => exitProject()}>
                    EXIT
                </button>
                <RelogModal isOpen={isHovered} setIsOpen={setIsHovered} setIsButtonDisabled={setIsButtonDisabled} exitProject={exitProject} />
            </div>
        </div>
    );
}

export default ExitButton;