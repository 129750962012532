import "./Builder.scss";
import { useEffect, useState, React, useContext } from "react";
import BuilderContainer from "./page-components/BuilderContainer";
import Icon from "../../components/Icon/Icon";
import NewSectionModal from "./page-components/PageCreation/NewSectionModal";
import { BuilderContext } from "../../helpers/constants";

const BasicBuilder = () => {
    const {editor,  manager, setSectionOptions, changeManager, selectedComponent} = useContext(BuilderContext);
    const [isStyleManagerOpen, setIsStyleManagerOpen] = useState(false);

    function addDefaultTemplate() {
        if(editor.DomComponents.getComponents().length > 1) return;

        setSectionOptions({ type: "whole_page" });
    }

    useEffect(() => {
        if(!editor) return;

        editor.on('page:select', () => {
            addDefaultTemplate();
        })
        addDefaultTemplate();
    }, [editor])

    useEffect(() => {
        setIsStyleManagerOpen(manager === "styleManager");
    }, [manager])

    useEffect(() => {
        if(!selectedComponent) return;
        console.log(selectedComponent.getAttributes()['section-name']);
        console.log(selectedComponent.get('type'))
        console.log(selectedComponent.getAttributes())
    }, [selectedComponent])

    return (
        <>
            <NewSectionModal sectionPlaceholder={{}}/>
            {/* <MovableBox title="Style Manager" isOpen={isStyleManagerOpen} setIsOpen={setIsStyleManagerOpen}>
                <Icon className="action" name="arrow-left" onClick={() => changeManager("editManager")} />
                <div className="head-container grid-x grid-container align-center align-middle">
                    <div className="cell large-10" id="js-builder__settings-manager" />
                    <div className="cell large-10" id="js-builder__style-manager" />
                </div>
            </MovableBox> */}
            <BuilderContainer/>

            <div style={{ width: (!manager ? "5vw" : "30vw"), height: "100%", flexWrap: "nowrap", margin: 0, padding: 0 }} className="builder__section-manager grid-x text-center align-center align-middle">
                <div style={{ width: (!manager ? "0%" : "100%"), height: "100%", overflow: "auto" }}>
                    <div className="builder-manager" style={{ display: manager === "styleManager" ? "block" : "none" }}>
                        {/* <h4 style={{ color: "white" }}>
                            Selected:
                            <span className="selected-component-type">
                                {selectedComponent && (
                                    selectedComponent?.getAttributes()["section-name"] ? (
                                        `${selectedComponent?.getAttributes()["section-name"]}`
                                    ) : (
                                        `${selectedComponent?.get("type")}`
                                    )
                                )}
                            </span>
                        </h4> */}
                        <div id="js-builder__settings-manager" />
                        <div id="js-builder__style-manager" />
                    </div>
                </div>
                <div className="side-bar">
                    <div className="grid-y side-bar__grid " style={{ justifyContent: "space-between" }}>
                        <div className="side-bar__grid--elements">
                            <Icon id="js-style-manager__button" name="paintbrush" tooltip="Style" className={`${(manager === "styleManager" ? "action" : "actionI")} side-bar__icon`} onClick={() => changeManager("styleManager")} />
                        </div>
                        <div className="side-bar__arrow side-bar__grid--elements">
                            <Icon style="transition: all 0.3s; align-self: flex-end;" name="arrow-left" className={`${(manager === "blockManager" || manager === "sectionManager" || manager === "styleManager" || manager === "settingsManager" || manager === "layerManager" ? "arrow__rotate__to__right" : "")} side-bar__icon arrow`} onClick={() => {
                                if (!manager)
                                    changeManager("styleManager");
                                else
                                    changeManager("");
                            }} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default BasicBuilder;