import SectionBlock from "./SectionBlock";
import Loader from "../../../../components/Loader/Loader";
import { useState, useEffect, useContext } from "react";
import { useQuery } from "react-query";
import Icon from "../../../../components/Icon/Icon";
import "../../Builder.scss";
import { addSection } from "../../../../helpers/builderFunctions";
import { deletePreviewedSection } from "../../../../helpers/builderFunctions";
import { BuilderContext, TEMPLATE_SECTION } from "../../../../helpers/constants";
import { makeRequest } from "../../../../helpers/handlers";

const SectionManager = () => {
    const { editor, manager, isAdmin } = useContext(BuilderContext);
    const [selectedSection, setSelectedSection] = useState([]);
    const [comparable, setComparable] = useState("%%");
    const [offset, setOffset] = useState(0);
    const [isDeleted, setIsDeleted] = useState(false);
    const [type, setType] = useState('all');
    const [activeButton, setActiveButton] = useState('all');
    const { isLoading, data, refetch } = useQuery(["getTemplates", comparable.toLowerCase(), type], () => makeRequest(TEMPLATE_SECTION.SECTION, TEMPLATE_SECTION.GET, { comparable: comparable.toLowerCase(), type: type }));
    const [searchQuery, setSearchQuery] = useState("");

    useEffect(() => {
        refetch()
    }, [isDeleted])

    useEffect(() => {
        if (!editor) return;
        const projectData = JSON.parse(selectedSection['project_data']),
            newSection = addSection(editor, { ...projectData, name: selectedSection['name'], sectionType: selectedSection['type_name'] });
        editor.Css.addRules(projectData['cssRules']);
        if (!newSection) return;
        editor.DomComponents.addComponent(newSection);
    }, [selectedSection])

    const handleButtonClick = (sectionType) => {
        setType(sectionType === type ? 'all' : sectionType);
        setActiveButton(sectionType === type ? 'all' : sectionType);
    }

    return (
        <div className="grid-y align-middle">
            <div className="grid-x align-center" >
                <span className="builder__section-manager__header cell" >
                    SECTIONS
                </span>
                <input
                    type="text"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    placeholder="Search sections..."
                    className="action__input cell large-10"
                />
                <div className="section-filter">
                    <button
                        className={activeButton === 'navbar' ? 'action__border' : 'action__neutral'}
                        onClick={() => handleButtonClick('navbar')}>
                        Navbar
                    </button>
                    <button
                        className={activeButton === 'body' ? 'action__border' : 'action__neutral'}
                        onClick={() => handleButtonClick('body')}>
                        Body
                    </button>
                    <button
                        className={activeButton === 'footer' ? 'action__border' : 'action__neutral'}
                        onClick={() => handleButtonClick('footer')}>
                        Footer
                    </button>
                    <button
                        className={activeButton === 'whole_page' ? 'action__border' : 'action__neutral'}
                        onClick={() => handleButtonClick('whole_page')}>
                        Page
                    </button>
                </div>
            </div>
            {isLoading ? <Loader />
                :
                <div style={{ height: "90%" }}>
                    <div className="grid-y align-justify">
                        <div className="grid-x align-center">
                            {data["data"]["templates"]?.filter((currTemplate) =>
                                currTemplate.name.toLowerCase().includes(searchQuery.toLowerCase())
                            ).map((currTemplate, index) => {
                                return <SectionBlock
                                    key={index}
                                    selectedSection={selectedSection}
                                    setSelectedSection={setSelectedSection}
                                    isDeleted={isDeleted}
                                    setIsDeleted={setIsDeleted}
                                    isAdmin={isAdmin}
                                    data={currTemplate}
                                />
                            })}
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}

export default SectionManager;