import React, { SetStateAction, Dispatch, useRef, useState, useContext } from "react";
import ConfirmModal from "../../../../components/ConfirmModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TEMPLATE_SECTION } from "../../../../helpers/constants";
import { makeRequest } from "../../../../helpers/handlers";
import { getJWToken, getUser } from "../../../../helpers/handle_token";
import { BuilderContext } from "../../../../helpers/constants";
import { getNestedComponents } from "../../../../helpers/builderFunctions";

const SectionBlock = ({ selectedSection, setSelectedSection, setIsDeleted, isDeleted, isAdmin, data }) => {
    const { editor } = useContext(BuilderContext);
    const sectionRef = useRef(null);
    const [modalActive, setModalActive] = useState(false);
    const [renameModalActive, setRenameModalActive] = useState(false);
    const [replaceModalActive, setReplaceModalActive] = useState(false);
    const [newName, setNewName] = useState('');

    function handleReplace() {
        const wrapper = editor.DomComponents.getWrapper(),
            templateClass = Math.random().toString(36).slice(2),
            projectData = getNestedComponents(editor, wrapper, templateClass);
        projectData['cssRules'] = [];


        editor.Css.getRules().forEach(rule => {
            const declaration = rule.getDeclaration(),
                parts = declaration.split('{');

            let newRuleDeclaration = parts[0] + '.' + templateClass + '{' + parts[1];
            if(rule.getAtRule())
                newRuleDeclaration = rule.getAtRule() + '{' + newRuleDeclaration + '}';

            
            const newRule = editor.CssComposer.addRules(newRuleDeclaration);
            
            projectData['cssRules'].push(newRule);
            editor.CssComposer.remove(newRule);
        });

        if (localStorage.getItem("isModuleBuilderActive"))
            return;

        makeRequest(TEMPLATE_SECTION.SECTION, TEMPLATE_SECTION.SET_PROJECT_DATA, { username: getUser(getJWToken()), name: data['name'], projectData: JSON.stringify(projectData)})
        .then(() => setReplaceModalActive(false))
    }

    function handleDelete() {
        makeRequest(TEMPLATE_SECTION.SECTION, TEMPLATE_SECTION.DELETE, { username: getUser(getJWToken()), name: data['name'] })
        .then(() => {
            setModalActive(false);
            setIsDeleted(!isDeleted);
        });
    };

    function handleRename() {
        makeRequest(TEMPLATE_SECTION.SECTION, TEMPLATE_SECTION.RENAME, {
            username: getUser(getJWToken()),
            name: name,
            newName: newName
        })
        .then(() => {
            setRenameModalActive(false);
        });
    };

    if(!data) return <></>;
    return (
        <div
            className={`cell small-12 large-6 align-middle align-center text-center builder__section-manager__template-block ${selectedSection?.name === data["name"] && "selected-section"}`}
            ref={sectionRef}
            onClick={() => {
                if(modalActive || renameModalActive) return;
                setSelectedSection(data);
            }}
            style={{ width: "47%", marginTop: "5px" }}
        >
            <div style={{ paddingRight: "5px" }} className="cell large-1 text-right flex">
                {isAdmin &&
                    <>
                        <button onClick={(ev) => { setReplaceModalActive(true); ev.stopPropagation() }} className="cell section__replace__button">
                            <FontAwesomeIcon icon={"retweet"} />
                        </button>
                        <button onClick={(ev) => {setRenameModalActive(true); ev.stopPropagation() }} className="cell section__rename__button">
                            <FontAwesomeIcon icon={"pen"} />
                        </button>
                        <button onClick={(ev) => { setModalActive(true); ev.stopPropagation() }} className="cell section__delete__button">
                            <FontAwesomeIcon icon={"xmark"} />
                        </button>
                    </>
                }
            </div>
            <span className="cell large-9">
                {data.name}
            </span>
            {replaceModalActive && (
                <div style={{ width :"300px" }}>
                    <ConfirmModal message="Do you want to replace the project data of this section?" func={handleReplace} setActive={setReplaceModalActive} confirmText="Update"/>
                </div>
            )}

            {modalActive && (
                <div style={{ width: "300px" }}>
                    <ConfirmModal message="Do you want to delete this section?" func={handleDelete} setActive={setModalActive}/>
                </div>
            )}
            {renameModalActive && (
                <div className="rename-modal" style={{ width: "200%" }}>

                    <div className="rename-modal--content grid-x grid-margin-x align-center-middle">
                        <h4 className="cell text-center white">Rename <span className="secondary_color ">{data.name}</span></h4>
                        <input
                            className="cell small-7 section__new--name__modal"
                            type="text"
                            value={newName}
                            onChange={(e) => setNewName(e.target.value)}
                            placeholder="Enter new name"
                        />
                        <div className="cell">
                            <div className="grid-x grid-margin-x align-center-middle">
                                <button className="cell small-5 action__neutral" onClick={() => setRenameModalActive(false)}>Cancel</button>
                                <button className="cell small-5 action" onClick={handleRename}>Rename</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default SectionBlock;