import { useContext, useEffect, useRef } from "react";
import { BuilderContext } from "../../../helpers/constants";

const useBlur = (editor, linkId) => {
    const { manager } = useContext(BuilderContext);
    const managerRef = useRef(manager);
    const linkRef = useRef(linkId);

    function setBlur(components, blurValue) {
        components.forEach(component => {
            if(component.getAttributes()['data-type'] !== 'section')
                return;
            const element = component.view.el; 
            element.style.filter = blurValue ? 'blur(1px)' : 'blur(0px)';
            element.style.opacity = blurValue ? '0.5' : '1';
        })
    };

    function getSectionParent(component) {
        while(component.parent() && component.getAttributes()['data-type'] !== 'section') {
            component = component.parent();
        }
        return component;
    }

    //? unblurs the selected component and blurs everything else
    function blurUnselected() {
        if(managerRef.current !== "styleManager" || linkRef.current) {
            //? deblur all
            const components = editor.DomComponents.getWrapper().find('[data-type]');
            setBlur(components, false);
            return;
        }

        const selectedComponent = editor.getSelected();
        //! disable if anchor is being set
        if (!selectedComponent) return;


        //? blur all components
        const components = editor.DomComponents.getWrapper().find('[data-type]');
        //? filtering so the add section button is not blurred
        const filteredComponents = components.filter(component => 
            component.getAttributes()['data-type'] !== 'button'
        );
        setBlur(filteredComponents, true);
        
        //? deblur all parents of the selected component 
        for(let currComponent = getSectionParent(selectedComponent); currComponent; currComponent = currComponent.parent()) {
            setBlur([currComponent], false);
        }
    }


    useEffect(() => {
        if(!editor) return;
        editor.on('component:selected', blurUnselected);
    }, [editor])

    useEffect(() => {
        linkRef.current = linkId;
        managerRef.current = manager;
        if(!editor) return;
        blurUnselected();
    }, [manager, linkId]);
}
export default useBlur;