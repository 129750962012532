import { makeRequest as makeRequestToModule } from "../../moduleBuilder/handlers";
import { BuilderContext, PROJECT_SECTION, TEMPLATE_SECTION } from "../../../../helpers/constants";
import { addSection, defaultCss } from "../../../../helpers/builderFunctions";
import { makeRequest } from "../../../../helpers/handlers";
import ChooseSectionManager from "./ChooseSectionManager";
import { useEffect, useState, useContext, useRef } from "react";
import { TypePlugin } from "../../plugin/TypePlugin";
import grapesjs from "grapesjs";
import Cookies from "js-cookie";
import { useNavigate } from "react-router";
import { getJWToken, getUser } from "../../../../helpers/handle_token";

const NewSectionModal = ({ sectionPlaceholder }) => {
    const {editor, sectionOptions, setSectionOptions, navbar, footer} = useContext(BuilderContext);
    const [selectedSection, setSelectedSection] = useState([]);
    const [previewEditor, setPreviewEditor] = useState(null);
    const numberOfCreationsRef = useRef(null);
    const navigate = useNavigate();

    const handleClose = () => {
        if(sectionOptions['type'] !== 'whole_page') {
            setSectionOptions(null);
            setSelectedSection(null);
            return;
        }
        const mainPageId = editor.Pages.getMain()['id'];
        if(editor.Pages.getSelected()['id'] !== mainPageId) {
            setSectionOptions(null);
            setSelectedSection(null);
            editor.Pages.select(mainPageId);
            return;
        }
        navigate("/projects");
    }

    function setToolbar(section) {
        const tb = section.get("toolbar");
        tb.splice(tb.length - 1, 0, {
            attributes: { class: 'fa fa-solid fa-retweet' },
            command: () => {
                const currSectionType = section.getAttributes()["data-sectionType"];
                if (!currSectionType)
                    return;

                const bodyComponents = sectionPlaceholder["body"].get("components"),
                    currIndex = bodyComponents.indexOf(section);

                setSectionOptions({ type: currSectionType, atIndex: currIndex, replaceSection: section });
            }
        })

        section.set("toolbar", tb);
    }

    function handleFinishSectionResponse(response) {
        response = response['data'];
        response.forEach((section, index) => {
            let sectionType = 'body';
            let projectData = section['project_data'];
            if(typeof projectData === 'string') 
                projectData = JSON.parse(projectData);

            if(index === 0) sectionType = 'navbar';
            if(index === response.length - 1) sectionType = 'footer';

            if(index === 0 && navbar.current) return;
            if(index === response.length - 1 && footer.current) return;
            finishSection({ ...section, ...projectData, sectionType: sectionType })
        });
    }

    function handleSubmit() {
        if(numberOfCreationsRef.current.value < 1 || numberOfCreationsRef.current.value > 10) {
            alert("Please enter a valid number of sections");
            numberOfCreationsRef.current.focus();
            return;
        }
        for(let i = 0; i < numberOfCreationsRef.current.value; i++) {
            finishSection({ ...selectedSection, sectionType: sectionOptions['type'] });
        }
        numberOfCreationsRef.current.value = 1;
    }

    function finishSection(section) {
        if(!section){
            console.log('a section is not selected');
            return;
        }
        let sectionType = section["sectionType"];
    
        if(sectionType === 'whole_page') {
            if(Object.keys(section['project_data']).length === 0){
                if(localStorage.getItem("isModuleBuilderActive")) {
                    makeRequestToModule("get", "templates", "getSectionsData", {partIds: JSON.parse(selectedSection['part_ids'])})
                    .then(response => handleFinishSectionResponse(response));
                } else {
                    makeRequest(TEMPLATE_SECTION.SECTION, TEMPLATE_SECTION.GET_SECTIONS_DATA, {ids: selectedSection['part_ids']})
                    .then(response => handleFinishSectionResponse(response))
                }
                return;
            }
        }
        if(sectionOptions['replaceSection'])
            sectionOptions['replaceSection'].remove();

        const newSection = addSection(editor, {...section, sectionType: sectionType});

        if(!newSection) {
            setSelectedSection(null);
            setSectionOptions(null);
            return;
        }

        if (section["sectionType"] === "navbar") {
            const link = newSection.find("a")[0];
            link.set({
                "removable": false,
            })
            const mainPage = editor.Pages.getMain();
            link.addAttributes({ href: `page:${mainPage['attributes']['id']}` })
        }

        if(sectionType === 'navbar')
            navbar.current = newSection;
        else if(sectionType === 'footer') 
            footer.current = newSection

        setToolbar(newSection);

        let appendOpts = {};
        if (sectionOptions["atIndex"] !== null)
            appendOpts["at"] = sectionOptions["atIndex"];

        sectionPlaceholder[sectionType].append(newSection, appendOpts);
        setSelectedSection(null);
        setSectionOptions(null);
    }

    function handleResponse(response) {
        response = response['data'];

        response.forEach((section) => {
            let projectData = section['project_data']
            if(typeof projectData === 'string')
                projectData = JSON.parse(projectData)
            
            addSection(previewEditor, projectData);
            previewEditor.Css.addRules(section['project_data']['cssRules']);
            previewEditor.Css.remove('.gjs-container');
        });
    }

    useEffect(() => {
        if (sectionOptions || !editor || !previewEditor)
            return;

        editor.select();
        setPreviewEditor(null);
        if(localStorage.getItem("isModuleBuilderActive")){
            makeRequestToModule("POST", "projects", "save", { projectData: localStorage.getItem("gjsProject"), pageUrls: localStorage.getItem("pageUrls")})
            return;
        }

        if(Cookies.get("temporarySession")) {
            makeRequest(PROJECT_SECTION.SECTION, PROJECT_SECTION.SAVE_DEMO, { sessionId: Cookies.get("temporarySession"), projectData: localStorage.getItem("gjsProject") });
            return;
        }
        makeRequest(PROJECT_SECTION.SECTION, PROJECT_SECTION.SAVE, { projectData: localStorage.getItem("gjsProject"), projectId: localStorage.getItem("projectId"), username: getUser(getJWToken()) });
    }, [sectionOptions])

    useEffect(() => {
        if (previewEditor || !sectionOptions)
            return;

        const e = grapesjs.init({
            container: "#gjs-preview",
            fromElement: true,
            storageManager: false,
            selectorManager: {
                componentFirst: 1,
            },
            deviceManager: {
                devices: [
                    {
                        width: "100vw"
                    },
                ]
            },
            panels: { defaults: [] },
            plugins: [TypePlugin],
            protectedCss: defaultCss
        });

        e.on('load', () => {
            const wrapper = e.Components.getWrapper();
            wrapper.set({
                "locked": true,
            })
        })
        setPreviewEditor(e);
    }, [previewEditor, sectionOptions])

    useEffect(() => {
        if (!previewEditor || !selectedSection)
            return;
        previewEditor.DomComponents.clear();
        if(selectedSection['sectionType'] === 'whole_page' || sectionOptions['type'] === 'whole_page') {
            if(Object.keys(selectedSection['project_data']).length === 0) {
                if(localStorage.getItem('isModuleBuilderActive')) {
                    makeRequestToModule("get", "templates", "getSectionsData", {partIds: JSON.parse(selectedSection['part_ids'])})
                    .then(response => handleResponse(response))
                }
                else {
                    makeRequest(TEMPLATE_SECTION.SECTION, TEMPLATE_SECTION.GET_SECTIONS_DATA, {ids: selectedSection['part_ids']})
                    .then(response => handleResponse(response))
                }
                return; 
            }
            //* To be removed
            addSection(previewEditor, {...selectedSection['project_data'], sectionType: 'whole_page'});
            previewEditor.Css.addRules(selectedSection['project_data']['cssRules']);
            previewEditor.Css.remove('.gjs-container');
            return;
            //* 
        }
        const newSection = addSection(previewEditor, {...selectedSection, sectionType: sectionOptions['type']});
        newSection.set({
            "locked": true,
            "highlightable": false,
        })
        if (sectionOptions["type"] === "footer") {
            newSection.setStyle({
                position: "absolute",
                bottom: "0",
                width: "100%"
            })
        }

        // previewEditor.Css.addRules(selectedSection['project_data']['cssRules']);
        // previewEditor.Css.remove('.gjs-container');
    }, [selectedSection])
    // console.log(editor.Pages.getSelected()['id']);
    if (!sectionOptions)
        return <></>;
    return (
        <div id="chooseSectionModalContainer">
            <div id="chooseSectionModal" className="grid-y align-center-middle" style={{ userSelect: "none" }}>
                <div style={{ maxHeight: "76vh" }} className="grid-x align-center-middle grid-margin-x">
                    <div style={{ maxHeight: "76vh" }} className="cell medium-12 large-5">
                        <input min="1" max="10" value="1" type="number" ref={numberOfCreationsRef}/>
                        <div className="grid-y align-center">
                            <ChooseSectionManager sectionType={sectionOptions["type"]} selectedSection={selectedSection} setSelectedSection={setSelectedSection} />
                        </div>
                        <div className="grid-x align-center grid-margin-x ">
                            {(!localStorage.getItem("isModuleBuilderActive") || sectionOptions['type'] === 'body' || 
                                sectionOptions['replaceSection'] || editor.Pages.getSelected()['id'] !== editor.Pages.getMain()['id']) &&
                                <button onClick={handleClose} className="action__neutral cell small-6">Cancel</button>
                            }

                            <button
                                className="choose--mode__modal--add-section action cell small-6 text-center"
                                onClick={handleSubmit}
                                disabled={!selectedSection || selectedSection.length === 0}
                            >
                                Add Section
                            </button>
                        </div>
                    </div>
                    <div className="large-7 small-12">
                        <div className="grid-y align-center-middle">
                            <div id="gjs-preview" />    
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default NewSectionModal;