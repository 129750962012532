import $ from "jquery";
import { TEMPLATE_SECTION } from "../../../helpers/constants";
import { makeRequest as makeRequestToSite } from "../../../helpers/handlers";

let noParamsUrl = window.location.href.split('?')[0];
if(noParamsUrl === window.location.href) {
    let lastSlashIndex = noParamsUrl.lastIndexOf('/');
    if(lastSlashIndex === -1)
        lastSlashIndex = noParamsUrl.length - 1;
    noParamsUrl = noParamsUrl.substring(0, lastSlashIndex + 1);
}

let apiUrl = `${noParamsUrl}server/index.php`;
if(process.env.REACT_APP_BUILDER_TYPE !== 'module')
    apiUrl = 'http://junio-int.amdev.ninja:8011';

export function makeRequest(method, section, command, requestParams = {}) {
    const urlParams = Object.fromEntries(new URLSearchParams(location.search));
    if(urlParams['key'])
        requestParams['key'] = urlParams['key']
    return new Promise((resolve, reject) => {
        $.ajax({
            url: `${apiUrl}/${section}/${command}`,
            method: method,
            data: requestParams,
            success: (data) => resolve(JSON.parse(data)),
            error: (error) => reject(error),
            dataType: "text"
        })
    })
}

export function moduleImportImage(method, section, command, requestParams) {
    return new Promise((resolve, reject) => {
        $.ajax({
            url: `${apiUrl}/${section}/${command}`,
            method: method,
            data: requestParams,
            mimeType: "multipart/form-data",
            crossDomain: true,
            contentType: false,
            processData: false,
            success: (data) => resolve(JSON.parse(data)),
            error: (error) => reject(error),
            dataType: "text"
        })
    })
}

export function updateTemplates() {
    makeRequestToSite(TEMPLATE_SECTION.SECTION, TEMPLATE_SECTION.GET, { comparable: "%%", type: "all"})
    .then((response) => response["data"]["templates"])
    .then(templates => {
        for(const index in templates) {
            let type = "body";
            switch(templates[index]["type_id"]) {
                case "1": 
                    type = "navbar";
                    break;
                case "2":
                    type = "body";
                    break;
                case "3":
                    type = "footer";
                    break;
                case "4":
                    type = "whole_page";
                    break;
                default:
                    break;
            }
            let params = { 
                id: templates[index]['id'],
                name: templates[index]["name"], 
                type: type, 
                projectData: templates[index]["project_data"],
            }
            if(type === 'whole_page') {
                let partIds = templates[index]['part_ids'].substring(1, templates[index]['part_ids'].length - 1);
                partIds = partIds.split(',');
                params['partIds'] = JSON.stringify(partIds);
            }
            makeRequest("POST", "templates", "create", {...params});
        }
    });        
}