import { Dispatch, SetStateAction } from "react";
import "../css/componentsCss/modal__confirm.scss";

type Props = {
    func: () => void,
    setActive: Dispatch<SetStateAction<boolean>>,
    message: string,
    confirmText?: string
}

const ConfirmModal: React.FC<Props> = ({func, setActive, message, confirmText = "Delete"}) => {
    return (

        <div className="modal__confirm grid-y grid-margin-y align-center">
            <span className="modal__question text-center">{message}</span>
            <div style={{paddingTop: "5px"}} className="grid-x align-center grid-margin-x ">
                <button className="action__neutral cell small-6" onClick={(ev) => {setActive(false); ev.stopPropagation()}}>Cancel</button>
                <button className="action__red small-6 cell " onClick={(ev) => {func(); ev.stopPropagation()}}>{confirmText}</button>
            </div>
        </div>
    )
}
export default ConfirmModal;