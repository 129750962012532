import loadable from "@loadable/component";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ConfirmModal from "./components/ConfirmModal";
import ConfirmEmail from "./pages/confirmEmail/confirmEmail";

const Ticket = loadable(() => import("./pages/tickets/Ticket"));
const TicketsPage = loadable(() => import("./pages/tickets/TicketsPage"));
const PageNotFound = loadable(() => import("./pages/404/404")); //404
const Loader = loadable(() => import("./components/Loader/Loader"));
const PrivateRoute = loadable(() => import("./components/PrivateRoute"));
const ModuleBuilder = loadable(() => import("./pages/builder/moduleBuilder/ModuleBuilder"));
const Welcome = loadable(() => import("./pages/welcome/Welcome"), { fallback: <Loader /> })
const Learn = loadable(() => import("./pages/learn/Learn"), { fallback: <Loader /> })
const Register = loadable(() => import("./pages/account/register/Register"), { fallback: <Loader /> })
const Login = loadable(() => import("./pages/account/login/Login"), { fallback: <Loader /> })
const Plans = loadable(() => import("./pages/plans/Plans"), { fallback: <Loader /> })
const Profile = loadable(() => import("./pages/profile/Profile"), { fallback: <Loader /> })
const Browser = loadable(() => import("./pages/websiteBrowser/WebsiteBrowser"), { fallback: <Loader /> })
const Suspend = loadable(() => import("./pages/account/suspend/Suspend"), { fallback: <Loader /> })
const ProjectManager = loadable(() => import("./pages/project-manager/ProjectManager"), { fallback: <Loader /> })
const FileManager = loadable(() => import("./pages/fileManager/FileManager"), { fallback: <Loader /> })
const BasicBuilder = loadable(() => import("./pages/builder/BasicBuilder"), { fallback: <Loader /> })
const AdvancedBuilder = loadable(() => import("./pages/builder/AdvancedBuilder"), { fallback: <Loader /> })
const AdminBuilder = loadable(() => import("./pages/builder/AdminBuilder"), { fallback: <Loader /> })
const AccountSettings = loadable(() => import("./pages/account/accountSettings/AccountSettings"), { fallback: <Loader /> })
const Admin = loadable(() => import("./pages/admin/Admin"), { fallback: <Loader /> })
const Error = loadable(() => import("./components/ServerError/ServerError"), { fallback: <Loader /> })
const ChangePassword = loadable(() => import("./pages/account/changeCredentials/ChangePassword"), { fallback: <Loader /> })
const SubmitNewPass = loadable(() => import("./pages/account/changeCredentials/SubmitNewPass"), { fallback: <Loader /> })
const Tutorials = loadable(() => import("./pages/learn/Tutorials/Tutorials"), { fallback: <Loader /> })
const UserGuide = loadable(() => import("./pages/learn/UserGuide/UserGuide"), { fallback: <Loader /> })
// const ChangeUsername = loadable(() => import("./pages/account/changeCredentials/ChangeUsername"), { fallback: <Loader /> })
// const ContactUs = loadable(() => import("./pages/contactUs/ContactUs"), { fallback: <Loader /> })
const ViewerEditor = loadable(() => import("./pages/builder/moduleBuilder/ViewerEditor"), { fallback: <Loader /> })
const ShopManager = loadable(() => import("./pages/shop-manager/ShopManager"), { fallback: <Loader /> });
const Shop = loadable(() => import("./pages/shop/Shop"), { fallback: <Loader /> });

const Builder = loadable(() => import("./pages/builder/Builder"), { fallback: <Loader /> })

const RouteManager: React.FC = () => {
    return (
        <Router>
            <Routes>
                <Route path="*/test" element={<Welcome />} />
                <Route path="/ticket/:id" element={<PrivateRoute Route={<Ticket />} />} />
                <Route path="/tickets" element={<PrivateRoute Route={<TicketsPage />} />} />
                <Route path="/builder" element={<Builder />} />
                <Route path='/preview/*' element={<ViewerEditor />} />
                <Route path="/" element={<PrivateRoute Route={<Welcome />} />} />
                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Register />} />
                <Route path="/suspend" element={<Suspend />} />
                {/* Add back after release*/}
                {/* <Route path="/shops" element={<ShopManager />} />
                <Route path="/shop/:id" element={<Shop />} /> */}

                {/* <Route path="/plans" element={<Plans />} /> */}
                {/* <Route path="/learn" element={<Learn />} /> */}
                {/* <Route path="/browser" element={<Browser />} /> */}
                <Route path="/confirm-email" element={<ConfirmEmail />} />
                <Route path="/error" element={<Error />} />
                <Route path="/change-password" element={<ChangePassword />} />
                {/* 
                    // ? Contact us ne raboti
                    */}
                {/* <Route path="/contactUs" element={<ContactUs />} /> */}
                <Route path="/moduleBuilder/*" element={<ModuleBuilder />} />

                {/* <Route path="/change-username" element={<PrivateRoute Route={<ChangeUsername />} />} /> */}
                <Route path="/change-pass/:passToken" element={<SubmitNewPass />} />
                <Route path="/projects" element={<PrivateRoute Route={<ProjectManager />} />} />
                <Route path="/settings" element={<PrivateRoute Route={<AccountSettings />} />} />
                <Route path="/profile" element={<PrivateRoute Route={<Profile />} />} />
                <Route path="/admin" element={<PrivateRoute Route={<Admin />} />} />
                {/* <Route path="/tutorials" element={<PrivateRoute Route={<Tutorials />} />} /> */}
                {/* <Route path="/userGuide" element={<PrivateRoute Route={<UserGuide />} />} /> */}
                <Route path="*" element={<PageNotFound />} />
                <Route path="//" element={<PageNotFound />} />
            </Routes>
        </Router>
    )
}

export default RouteManager;