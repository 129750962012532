import { useContext, useState } from "react";
import BuilderContainer from "./page-components/BuilderContainer";
import Icon from "../../components/Icon/Icon";
import SectionManager from "./page-components/SectionManager/SectionManager";
import { BuilderContext } from "../../helpers/constants";

const AdminBuilder = () => {
    const {selectedComponent, manager, changeManager} = useContext(BuilderContext);
    return (
        <>
            <div style={{ width: (!manager ? "100%" : "80vw") }}>
                <BuilderContainer/>
            </div>
            <div 
                style={{ width: (!manager ? "6vw" : "41vw"), height: "100%", flexWrap: "nowrap", margin: 0, padding: 0 }} // 41vw is the amount in which the icons dont move when manager is open
                className="builder__section-manager grid-x text-center align-center align-middle"> 
            {/* // play with the vw of the builder__section-manager to fine tune it(coresponds to the width of the side bar) */}
                <div style={{ width: (!manager ? "0%" : "80%"), display: (manager? "block" : "none"), height: "100%", overflow: "auto" }}>
                    <div className={"builder-manager"} style={{ display: manager === "blockManager" ? "block" : "none" }}>
                        <div id="js-builder__block-manager" />
                    </div>
                    <div className="builder-manager" style={{ display: manager === "sectionManager" ? "block" : "none", height: "95%" }} >
                        <SectionManager/>
                    </div>
                    <div className="builder-manager" style={{ display: manager === "styleManager" ? "block" : "none" }}>
                        <h4 style={{color: "white"}}>
                            Selected:
                            <span className="selected-component-type">
                                {` ${selectedComponent?.get("type")}`}
                            </span>
                        </h4>
                        <div id="js-builder__settings-manager"  />
                        <div id="js-builder__style-manager" />
                    </div>
                    <div style={{ display: manager === "layerManager" ? "block" : "none", height: "100%" }} id="js-builder__layer-manager" className="builder__manager" />
                </div>
                <div className="side-bar--admin" style={{ width: "50px", height: "100%", margin: 0, padding: 0 }}>
                    <div className="grid-y side-bar--admin-flex" style={{ justifyContent: "space-between" }}>
                        <div className="side-bar--admin__elements">
                            <Icon id="js-block-manager__button" name="cube" tooltip="Blocks" className={`${(manager === "blockManager" ? "action" : "actionI")} side-bar__icon`} onClick={() => changeManager("blockManager")} />
                            <Icon id="js-section-manager__button" name="puzzle-piece" tooltip="Sections" className={`${(manager === "sectionManager" ? "action" : "actionI")} side-bar__icon`} onClick={() => changeManager("sectionManager")} />
                            <Icon id="js-layer-manager__button" name="layer-group" tooltip="Layers" className={`${(manager === "layerManager" ? "action" : "actionI")} side-bar__icon`} onClick={() => changeManager("layerManager")} />
                            <Icon id="js-style-manager__button" name="paintbrush" tooltip="Style" className={`${(manager === "styleManager" ? "action" : "actionI")} side-bar__icon`} onClick={() => changeManager("styleManager")} />
                        </div>
                        <div>
                            <Icon style = "transition: all 0.3s" name="arrow-left" className={`${(manager === "blockManager" || manager === "sectionManager" || manager === "styleManager"  || manager === "colorSchemeManager" || manager === "layerManager" ? "arrow__rotate__to__right" : "")} side-bar__icon arrow`} onClick={() => {
                                if(!manager)
                                    changeManager("styleManager");
                                else
                                    changeManager("");
                            }} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AdminBuilder;