import { useRef, useState } from "react";
import { makeRequest } from "../../../../helpers/handlers";
import { getJWToken, getUser } from "../../../../helpers/handle_token";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { USER_SECTION } from "../../../../helpers/constants";

const BugReportModal = ({ visible, onClose }) => {
    const
        [error, setError] = useState(""),
        [isSubmitting, setIsSubmitting] = useState(false),
        [submissionStatus, setSubmissionStatus] = useState(null);

    const
        headerRef = useRef(null),
        contentRef = useRef(null),
        emailRef = useRef(null);

    function handleClose() {
        setError("");
        setSubmissionStatus(null);

        emailRef.current.value = "";
        headerRef.current.value = "";
        contentRef.current.value = "";

        onClose();
    }

    function handleSubmit(event) {
        event.preventDefault();
        setIsSubmitting(true);
        setSubmissionStatus(null);

        let requestParams = {
            from: emailRef.current.value,
            header: headerRef.current.value,
            message: contentRef.current.value,
        };

        if(localStorage.getItem("isModuleBuilderActive")) {
            makeRequest(USER_SECTION.SECTION, USER_SECTION.SEND_EMAIL, requestParams)
            .then(response => {
                if(response.code !== 0) {
                    setError(response.message);
                    setSubmissionStatus("fail");
                    return;
                }
                setSubmissionStatus('success')
                setTimeout(() => {
                    handleClose()
                }, 1500)
            })
            .finally(() => setIsSubmitting(false));
        } else {
            requestParams.username = getUser(getJWToken());
            
            makeRequest('tickets', 'create', requestParams, 'POST')
            .then(response => {
                if (response.code !== 0) {
                    setError(response.message);
                    setSubmissionStatus("fail");
                    return;
                }

                setSubmissionStatus("success");
                setTimeout(() => {
                    handleClose();
                }, 1500);
            }).finally(() => {
                setIsSubmitting(false);
            });
        }
    }

    return (
        <div className={`report-modal__wrapper white ${visible ? 'visible' : ''}`}>

            <form className="grid-x align-center-middle grid-margin-x" onSubmit={handleSubmit}>
                <h1 className="cell text-center">Report a problem</h1>     

                {/* Email Input */}
                <div className="cell text-center">
                    <div className="grid-x align-center-middle grid-margin-x">
                        <div className="cell small-4 medium-2 text-right report__header">
                            Your Email:
                        </div>
                        <input
                            className="cell small-8 medium-5 large-3 action__input report--input"
                            placeholder="Your Email address"
                            id="report--email"
                            type="email"
                            ref={emailRef}
                            required
                        />
                    </div>
                </div>
           
                {/* Title Input */}
                <div className="cell text-center">
                    <div className="grid-x align-center-middle grid-margin-x">
                        <div className="cell small-4 medium-2 text-right report__header">
                            Title:
                        </div>
                        <input
                            className="cell small-8 medium-5 large-3 action__input report--input"
                            placeholder="Title of the bug report"
                            id="report--title"
                            type="text"
                            ref={headerRef}
                            required
                        />
                    </div>
                </div>

                {/* Description Input */}
                <div className="cell">
                    <div className="grid-x align-center-middle grid-margin-x">
                        <div className="cell small-4 medium-3 text-right report__header">
                            Description:
                        </div>
                        <textarea
                            className="cell small-8 medium-6 large-4 report__input--description"
                            placeholder="Describe the problem"
                            id="report--description"
                            ref={contentRef}
                            required
                        />
                    </div>
                </div>

                {/* Error Message */}
                <div className="cell text-center" style={{ color: "red" }}>
                    {error}
                </div>

                {/* Buttons */}
                <div className="cell small-5">
                    <div className="grid-x grid-margin-x grid-margin-y align-center-middle">
                        <button
                            className="action__neutral cell small-12 medium-4 large-4 medium-offset-2"
                            type='button'
                            onClick={handleClose}
                            disabled={isSubmitting}
                        >
                            Cancel
                        </button>
                        <button
                            className="action cell small-12 medium-4 large-4"
                            type='submit'
                            disabled={isSubmitting}
                        >
                            {isSubmitting ? 'Submitting...' : 'Submit'}
                        </button>

                        {/* Submission Icon */}
                        <div className="cell medium-1">
                            {submissionStatus && (
                                <div className={`submission-icon ${submissionStatus === 'success' ? "icon-tick" : "icon-x"}`}>
                                    {submissionStatus === 'success' ?
                                        <FontAwesomeIcon color="black" icon={['fas', 'check']} />
                                        :
                                        <FontAwesomeIcon color="black" icon={['fas', 'x']} /> }
                                </div>  
                            )}
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
}

export default BugReportModal;
