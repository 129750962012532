class UndoManager {
    constructor(editor) {
        this.editor = editor;
        this.isPaused = false;
        this.clearStack();
        this.clearExcluded();
    }

    clearStack() {
        this.stack = {};
        this.editor.Pages.getAll().forEach(page => this.stack[page['id']] = {
            undoStack: [],
            redoStack: []
        });
    }

    clearExcluded() {
        //? default exclusions
        this.excluded = [
            (change) => change['component'].getAttributes()['data-dont-show'] !== 'all'
        ];
    }

    add(change, type = "undo") {
        if(this.isPaused) return;
        if(!this.excluded.every(predicate => predicate(change))) return;

        type += "Stack";
        //TODO: generate id
        const pageId = this.editor.Pages.getSelected()['id'];

        const currStack = this.stack[pageId][type];
        if(currStack.length > 0) {
            const lastChange = currStack[currStack.length - 1];
            if(lastChange["type"] === "add") {
                if(change['component'] === lastChange['component'].parent()) {
                    this.stack[pageId][type].pop();
                }
            } else if(lastChange['type'] === 'remove') {
                if(change['component'].parent() === lastChange['component'])
                    return;
            }
        }
        this.stack[pageId][type].push(change);
    }

    remove(id) {
        // const pageId = this.editor.Pages.getSelected()['id'];
    }

    getStack(type = "undo") {
        type += "Stack";
        const pageId = this.editor.Pages.getSelected()['id'];
        return this.stack[pageId][type];
    }

    exclude(predicate) {
        this.excluded.push(predicate);
    }

    addPage(id) {
        this.stack[id] = {
            "undoStack": [],
            "redoStack": []
        }
    }

    removePage(id) {
        delete this.stack[id];
    }
    
    stop() {
        this.isPaused = true;
    }

    start() {
        this.isPaused = false;   
    }

    revertLastChange(type = "undo") {
        type += "Stack";

        const pageId = this.editor.Pages.getSelected()['id'];

        if(this.stack[pageId][type].length === 0) {
            console.log(type + " is empty");
            return;
        }

        this.stop();
        
        const change = this.stack[pageId][type].pop();
        let invertedChange = {};
        switch(change['type']) {
            case 'add':
                //? add -> remove
                invertedChange['type'] = 'remove';
                invertedChange['collection'] = change['component'].collection;
                invertedChange['index'] = change['component'].index();
                invertedChange['component'] = change['component'];

                change['component'].remove();
                break;
            case 'remove':
                //? remove -> add
                invertedChange['type'] = 'add';
                invertedChange['component'] = change['component'];

                change['collection'].add(change['component'], { at: change['index'] });
                break;
            case 'update':
                invertedChange['type'] = 'update';
                invertedChange['component'] = change['component'];
                break;
            default:
                break;
        }

        const invertedType = type === "undoStack" ? "redoStack" : "undoStack";
        this.stack[pageId][invertedType].push(invertedChange);
        this.start();
    }
}

export default UndoManager;