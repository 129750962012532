import { useContext, useState } from "react";
import { BuilderContext } from "../../../../../helpers/constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { makeRequest } from "../../../moduleBuilder/handlers";

const PublishButtons = () => {
    const { editor } = useContext(BuilderContext);

    // State to manage submission status and loading
    const [submissionStatus, setSubmissionStatus] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false); // Track if submitting

    const handlePublish = () => {
        setIsSubmitting(true);
        makeRequest("POST", "projects", "publish")
        .then(response => setSubmissionStatus(response['code'] === 0 ? "success" : "fail"))
                  
        // Show the tick or cross for 1 second, then remove it
        setTimeout(() => {
            setSubmissionStatus(null);
            setIsSubmitting(false);
        }, 1500);
    };

    if (!localStorage.getItem("isModuleBuilderActive")) return <></>;

    return (
        <div className="grid-x grid-margin-x align-center-middle">
            <div className="cell small-4 action_color pointer"
                onClick={() => {
                    var url = window.location.href.split('?')[0];
                    window.open(url, "_blank");
                }} >
                <div className="link-preview">
                    <div className="wrapper">
                        <a className="third after" href="#">Preview</a>
                    </div>
                </div>
            </div>

            <div className="cell small-6 action"
                onClick={handlePublish}
                disabled={isSubmitting} // Disable button while submitting
            >
                Publish
            </div>

            {/* Submission Icon */}
            <div className="cell small-1">
                {submissionStatus && (
                    <div className={`submission-icon ${submissionStatus === 'success' ? "icon-tick" : "icon-x"}`}>
                        {submissionStatus === 'success' ? (
                            <FontAwesomeIcon color="black" icon={['fas', 'check']} />
                        ) : (
                            <FontAwesomeIcon color="black" icon={['fas', 'x']} />
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default PublishButtons;
