import { useContext, useState } from "react";
import { BuilderContext } from "../../../../../helpers/constants";
import CreateTemplateModal from "../../CreateTemplateModal";
import Icon from "../../../../../components/Icon/Icon";
import { makeRequest } from "../../../moduleBuilder/handlers";
import PublishButtons from "./PublishButtons";

const BuilderActionButtons = () => {
    const { builderMode, isAdmin, setCurrentTutorialPage, editor } = useContext(BuilderContext); 
    const [templateCreateActive, setTemplateCreateActive] = useState(false);


    return (
        <div className="cell small-5" id='js-builder__actions'>
            <div className="grid-x align-center-middle">

                {/* small build left cluster of buttons */}
                {/* <div className="builder--left__dropdown grid-y text-center">
                        <span className="square">
                            <a className="builder--left__dropdown--toggle before after" aria-haspopup="true" aria-expanded="false">
                                Options <span className="builder__dropdown--arrow-right">&#9656;</span>
                            </a>
                        </span>
                        <div className="builder--left__dropdown--menu" aria-labelledby="dropdownMenuButton">
                            <div id="js-builder__panels--utility__copy"></div>
                            {(builderMode === "admin" && isAdmin) &&
                                <Icon className="actionI " color="red" tooltip="Make Template" name="file" onClick={() => setTemplateCreateActive(true)} />}

                        </div>
                    </div> */}
                <CreateTemplateModal isOpen={templateCreateActive} setIsOpen={setTemplateCreateActive} />
                {/* small build right*/}
                <div className="builder--right__dropdown">
                    <span className="square">
                        <a className="builder--right__dropdown--toggle before after" aria-haspopup="true" aria-expanded="false">
                            Actions <span className="builder__dropdown--arrow-right">&#9656;</span>
                        </a>
                    </span>
                    <div className="builder--right__dropdown--menu" aria-labelledby="dropdownMenuButton">
                        <div id="js-builder__panels--utility__copy" />
                        <div id="js-builder__panels--actions__copy" />
                        {(builderMode === 'admin' && isAdmin) &&
                            <Icon className="actionI " color="red" tooltip="Make Template" name="file" onClick={() => setTemplateCreateActive(true)} />
                        }
                        <Icon
                            name="eye"
                            className="actionI"
                            color="black"
                            tooltip="Preview"
                            onClick={() => {
                                var url = window.location.href.split('?')[0];

                                url += `/${editor.Pages.getSelected()['attributes']['name'].replaceAll(" ", "_")}`
                                window.open(url, "_blank");
                            }} />
                        <Icon
                            name="save"
                            tooltip="Publish"
                            className="actionI"
                            onClick={() => {
                                makeRequest("POST", "projects", "publish")
                                    .then(response => console.log(response));
                            }}
                        />
                        {/* <Icon className="actionI" color="white" tooltip="Save Project" name="save" onClick={() => handleSave()} /> */}
                    </div>
                </div>
                <div className="cell small-3 medium-10 large-12 align-center-middle">
                    <div className="grid-x align-center-middle">
                       
                        {/* large */}
                        <div className="cell medium-6 large-5">
                            <div className="cell builder__right__No-Dropdown grid-x align-center-middle ">
                                <div id="js-builder__panels--actions" />
                                {/* <Icon className="actionI hide-for-large " color="white" tooltip="Save Project" name="save" onClick={() => handleSave()} /> */}
                            </div>
                        </div>
                         {/* large */}
                         <div className="buider__left__No-Dropdown cell align-center-middle medium-6 large-4">
                            <div id="js-builder__panels--utility" />
                            {/* {(builderMode === "admin" && isAdmin) && */}
                            {(builderMode === 'admin' && isAdmin) &&
                                <Icon className="actionI " color="red" tooltip="Make Template" name="file" onClick={() => setTemplateCreateActive(true)} />
                            }
                            {/* <div>
                                <Icon 
                                name="image"
                                tooltip="Uploaded files"
                                className="actionI"
                                onClick={() => {
                                    editor.select();
                                    const am = editor.AssetManager;
                                    const assets = am.getAll();
                                    am.render(assets);
                                    editor.runCommand('open-assets');
                                }}
                                />
                            </div> */}
                            <div style={{ display: "flex", justifyContent: "center" }} className="small-1" id='js-builder__topnav__info'>
                                <Icon name="compass" className="actionI" onClick={() => setCurrentTutorialPage(0)} tooltip="Info" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BuilderActionButtons;