import { createContext } from 'react';

export type ProjectType = {
    id: string,
    name: string,
    domain: string,
    is_public: boolean,
    last_modified: string,
    date_created: string,
    project_type: string,
    builder_mode: string
};

export interface UserType {
    [get: string]: string | boolean,
    firstname: string,
    lastname: string,
    username: string,
    phone_number: string,
    country: string,
    city: string,
    address: string,
    post_code: string,
    password: string,
    email: string,
    date_created: string,
    suspend_count: any,
    suspended: boolean,
    isAdmin: boolean
}

export interface ResponseType {
    data: any,
    code: number,
    message: string
}

export enum USER_SECTION {
    SECTION = 'users',
    GET_ALL = 'get-all',
    GET_DATA = 'get-data',
    IS_REGISTRANT = 'is-registrant',
    VALIDATE_REGISTER_DATA = 'validate-register-data',
    CHECK_IF_SUSPENDED = 'check-suspended',
    REGISTER = 'register',
    LOGIN = 'login',
    CHANGE_PASSWORD = 'change-password',
    SUBMIT_NEW_PASS = 'submit-new-password',
    SUBMIT_NEW_USERNAME = 'submit-new-username',
    UPDATE = 'update',
    SEND_VERIFICATION_CODE = 'generate-verification-code',
    SEND_EMAIL = 'send-email',
    SUSPEND = 'suspend',
    UNSUSPEND = 'unsuspend',
    CHANGE_ROLE = 'change-role',
    // PROMOTE = 'make-admin',
    // DEMOTE = 'make-user',
    RESTORE_SUSPENDED_COUNT = 'restore-suspended-count',
    RELOG = 'relog',
    DELETE = 'delete',
    REGISTER_VIA_GOOGLE = 'register-google',
    REGISTER_VIA_FB = 'register-facebook',
    VALIDATE_ROLE = 'validate-role',
    VALIDATE = 'validate',
    DEMO_SIGN_UP = 'generate-demo-session',
    VERIFY_EMAIL = 'verify-email'
}

export enum TEMPLATE_SECTION {
    SECTION = 'templates',
    GET = 'get',
    GET_TYPES = 'get-types',
    CREATE = 'create',
    CHECK = 'check',
    DELETE = 'delete',
    RENAME = 'rename',
    GET_SECTIONS_DATA = 'get-sections-data',
    SET_PROJECT_DATA = 'set-project-data'
}

export enum PROJECT_SECTION {
    SECTION = 'projects',
    GET_USER_PROJECTS = 'get-user-projects',
    GET_NAME = 'get-name',
    CREATE = 'create',
    CHECK_NAME = 'check-name',
    OPEN = 'open',
    CHANGE_NAME = 'change-name',
    SAVE = 'save',
    GENERATE_FILE = 'generate-file',
    DELETE = 'delete',
    SAVE_DEMO = 'save-demo',
    // VALIDATE_DEMO = 'validate-demo',
    GET_DEMO_EMAIL = 'get-demo-email',
    CREATE_FROM_DEMO = 'create-from-demo',
    GET_DEMO_DATA = 'get-demo-data',
    IMPORT_IMAGE = 'add-image',
    DELETE_IMAGE = 'delete-image',
    GET_ALL_IMAGES = 'get-all-images',
}

export enum TICKET_SECTION {
    SECTION = 'tickets',
    GET = 'get',
    CREATE = 'create',
    DELETE = 'delete',
    CHANGE_STATUS = 'change-status',
    GET_DATA = 'get-data',
    RESPOND = 'respond',
    GET_RESPONSES = 'get-responses'
}

export enum STORE_SECTION {
    SECTION = 'stores',
    GET_USER_STORES = 'get-user-stores',
    CREATE = 'create'
}

export enum PRODUCT_SECTION {
    SECTION = 'products',
    GET = 'get',
    ADD = 'add',
    DELETE = 'delete',
    UPDATE = 'update'
}

export enum ORDER_SECTION {
    SECTION = 'orders',
    ADD = 'add',
    GET_ALL = 'get-all',
    GET_BY_STORE = 'get-by-store',
    GET_BY_USER = 'get-by-user'
}

export const SELECTABLE_TYPES = [
    "text",
    "link",
    "image",
    // "h1",
    "button",
    "input",
    // "span",
    "textnode",
    "map",
    "video"
];

export const ICONS = [
    'facebook',
    'instagram',
    'twitter',
    'location',
    'phone',
    'email',
    'web',
];

export const REPLACEABLE_TYPES = [
    'text',
    'image',
    'span',
    'h1',
    'link',
    'icon',
    'map',
    'video',
    ...ICONS
];

export const BuilderContext = createContext('builder');

export const BaseApiURL = process.env.REACT_APP_API_URL;
