import { useEffect, useRef } from "react";
import { makeRequest } from "../../helpers/handlers";
import { useNavigate } from "react-router";
import { getEmail, getIsActive, getJWToken, getUser } from "../../helpers/handle_token";
import { USER_SECTION } from "../../helpers/constants";
import Cookies from "js-cookie";
import Icon from "../../components/Icon/Icon";
import { useQueryClient } from "react-query";
import { logout } from "../../helpers/handlers";

const ConfirmEmail = () => {
    const codeRef = useRef(null);
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    function removeCache() {
        queryClient.clear();
    }

    function handleSubmit(event) {
        event.preventDefault();
        const username = getUser(getJWToken());
        makeRequest(USER_SECTION.SECTION, USER_SECTION.VERIFY_EMAIL, {
            code: codeRef.current.value,
            username: username
        })
        .then(data => {
            if(data['code'] != 0) {
                return;
            }

            makeRequest(USER_SECTION.SECTION, USER_SECTION.RELOG, {
                username: username,
            })
            .then((data) => {
                if (data["code"] !== 0) {
                    return;
                }
                Cookies.set("sessionJWT", data["data"]);
            })
        })
        navigate("/projects");
    }

    function resendCode() {
        makeRequest(USER_SECTION.SECTION, USER_SECTION.SEND_VERIFICATION_CODE, {
            username: getUser(getJWToken()),
            email: getEmail(getJWToken())
        })
        .catch((err) => {
            navigate("/error", { state: { err: err } });
        })
    }

    useEffect(() => {
        if(!getJWToken())
            navigate("/");
        if(getIsActive(getJWToken()))
            navigate("/projects");
    }, [])

    return (
        <form onSubmit={handleSubmit} className="grid-y align-middle">
            <Icon name="arrow-left" className="action" onClick={() => { removeCache(); logout(); navigate("/") }}/>
            <div>
                <h4 style={{color: "white"}}>
                    Verification code:
                </h4>
                <input type="text" ref={codeRef} className="cell small-6"/>
                <button type='submit' className="action">
                    Submit
                </button>
                <button type="submit" onClick={resendCode} className="action">
                    Resend code
                </button>
            </div>
        </form>
    );
}

export default ConfirmEmail;