import { useEffect, useState } from "react";
import useCookies from "./useCookie";

const useCart = () => {
    const { setCookie, getCookie } = useCookies();

    //* private
    function getCartData(storeId) {
        //? Check if the cart data is from the requested store
        const cookieData = getCookie('cart')
        if(cookieData) {
            const cartData = JSON.parse(cookieData);
            if(cartData['storeId'] === storeId) return cartData;
        }

        //? Otherwise set the cart data to the default value
        const defaultCartData = {
            storeId: storeId,
            products: []
        };
        return defaultCartData;
    }
    
    //* public
    function changeQuantity(storeId, id, value) {
        const cartData = getCartData(storeId),
            productIndex = cartData['products'].findIndex(product => product['id'] === id);
            
        cartData['products'][productIndex]['quantity'] += value;
        if(cartData['products'][productIndex]['quantity'] <= 0) {
            removeFromCart(storeId, id);
            return;
        }
        setCookie('cart', JSON.stringify(cartData));
    }

    function addToCart(storeId, newProduct) {
        const cartData = getCartData(storeId);

        const exists = cartData['products'].findIndex(product => product['id'] === newProduct['id']);
        if(exists !== -1) {
            changeQuantity(storeId, newProduct['id'], 1);
            return;
        }

        newProduct['quantity'] = 1;
        cartData['products'].push(newProduct);
        setCookie('cart', JSON.stringify(cartData));
    }

    function removeFromCart(storeId, id) {
        let cartData = getCartData(storeId);
        cartData['products'] = cartData['products'].filter(product => product['id'] !== id);

        setCookie('cart', JSON.stringify(cartData));
    }

    function getAllProducts(storeId) {
        const cartData = getCartData(storeId);
        return cartData['products'];
    }

    function checkout(storeId) {
        // checkCartData(storeId);
        //* api request
    }

    return { changeQuantity, addToCart, removeFromCart, getAllProducts, checkout };
}

export default useCart;