import Icon from "../../../../components/Icon/Icon";
import ReactDOMServer from "react-dom/server";


export const getColorSector = () => ({
    name: 'Appearance',
    open: false,
});

export const getBorderSector = () => ({
    name: 'Border',
    open: false,
});

export const getTextSector = () => ({
    name: 'Text',
    open: false
})

export const getAlignSector = () => ({
    name: 'Align',
    open: false
})

export const getTextSectorProperties = (textPixels) => [
    {
        label: "Font family",
        property: "font-family",
        type: "select",
        default: "Times",
        options: [
            { value: "Arial, sans-serif", name: "Arial" },
            { value: "cursive", name: "Cursive" },
            // { value: '"Helvetica, sans-serif"', name: "Helvetica" },
            { value: "Verdana, sans-serif", name: "Verdana" },
            { value: "Tahoma, sans-serif", name: "Tahoma" },
            { value: "Georgia, serif", name: "Georgia" },
            { value: "Times", name: 'Times New Roman'},
            { value: "\"Courier New\", monospace", name: "Courier New" },
            { value: "Impact, sans-serif", name: "Impact" },
            { value: "\"Trebuchet MS\", sans-serif", name: "Trebuchet MS" },
            { value: "\"Palatino Linotype\", \"Book Antiqua\", Palatino, serif", name: "Palatino Linotype" },
            { value: "\"Century Gothic\", sans-serif", name: "Century Gothic" },
            // { value: "'Book Antiqua', serif", name: "Book Antiqua" },
            // { value: "Garamond, serif", name: "Garamond" },
            { value: "\"Franklin Gothic Medium\", \"Arial Narrow\", Arial, sans-serif", name: "Franklin Gothic Medium" },
            { value: "\"Arial Black\", sans-serif", name: "Arial Black" },
            { value: "\"Lucida Console\", Monaco, monospace", name: "Lucida Console" },
            { value: "\"Lucida Sans Unicode\", \"Lucida Grande\", sans-serif", name: "Lucida Sans Unicode" },
            { value: "Optima, sans-serif", name: "Optima" },
            // { value: "'Bodoni MT', serif", name: "Bodoni MT" },
            { value: "Rockwell, serif", name: "Rockwell" },
            { value: "Futura, sans-serif", name: "Futura" },
            { value: "\"Myriad Pro\", sans-serif", name: "Myriad Pro" },
            { value: "Candara, sans-serif", name: "Candara" },
            { value: "Constantia, serif", name: "Constantia" },
            { value: "Corbel, sans-serif", name: "Corbel" },
            // { value: "Ebrima, sans-serif", name: "Ebrima" },
            // { value: "Cambria, serif", name: "Cambria" },
            // { value: "'Calisto MT', serif", name: "Calisto MT" },
            // { value: "'Century Schoolbook', serif", name: "Century Schoolbook" },
            // { value: "'Engravers MT', serif", name: "Engravers MT" },
            { value: "\"Copperplate Gothic\", Copperplate, serif", name: "Copperplate Gothic" },
            // { value: "Perpetua, serif", name: "Perpetua" },
            { value: "\"MS Sans Serif\", Geneva, sans-serif", name: "MS Sans Serif" },
            // { value: "'MS Serif', 'New York', serif", name: "MS Serif" },
            { value: "\"Tw Cen MT\", sans-serif", name: "Tw Cen MT" },
            // { value: "'Berlin Sans FB', sans-serif", name: "Berlin Sans FB" },
            // { value: "'Eras Medium ITC', sans-serif", name: "Eras Medium ITC" },
            { value: "Vivaldi, cursive", name: "Vivaldi" },
            { value: "\"Brush Script MT\", cursive", name: "Brush Script MT" },
            // { value: "Century, serif", name: "Century" },
            // { value: "Colonna, serif", name: "Colonna" },
            { value: "\"Eccentric Std\", sans-serif", name: "Eccentric Std" },
            // { value: "Elephant, serif", name: "Elephant" },
            { value: "\"Freestyle Script\", cursive", name: "Freestyle Script" },
            { value: "\"Gill Sans\", sans-serif", name: "Gill Sans" },
            // { value: "'High Tower Text', serif", name: "High Tower Text" },
            { value: "\"Kunstler Script\", cursive", name: "Kunstler Script" },
            // { value: "'Monotype Corsiva', cursive", name: "Monotype Corsiva" },
            { value: "Papyrus, fantasy", name: "Papyrus" },
            // { value: "Ravie, fantasy", name: "Ravie" },
        ],
    }, {
        label: "Font size",
        property: "font-size",
        type: "select",
        units: ["px", "%", "rem", "em"],
        defaults: '',
        options: textPixels
    }, {
        label: "Letter spacing",
        property: "letter-spacing",
        type: "select",
        defaults: 'normal',
        options: [
            { value: "normal", name: "Normal" },
            ...textPixels,
        ],      
        
    }, {
        label: "Text align",
        property: "text-align",
        type: "radio",
        options: [
            { value: "start", label: ReactDOMServer.renderToString(<Icon color="#CDFD34" name="align-left" tooltip="Align left" />) },
            { value: "center", label: ReactDOMServer.renderToString(<Icon color="#CDFD34" name="align-center" tooltip="Align center" />) },
            { value: "end", label: ReactDOMServer.renderToString(<Icon color="#CDFD34" name="align-right" tooltip="Align right" />) }
        ]
    }, {
        label: "Bold",
        property: "font-weight",
        type: "select",
        defaults: "700",
        options: [
            { value: "400", name: "Normal" },
            { value: "700", name: "Bold" },
        ]
    }, {
        label: "Italic",
        property: "font-style",
        type: "select",
        default: "normal",
        options: [
            { value: "normal", name: "Normal" },
            { value: "italic", name: "Italic" }
        ]
    }
]

export const getColorSectorProperties = () => [
    {
        label: 'Text color',
        property: 'color',
        type: 'color',
    },
    {
        label: 'Background',
        property: 'background-color',
        type: 'color',
        default: 'rgba(0,0,0,0)'
    },
    {
        label: 'Fill (Icon color)',
        property: 'fill',
        type: 'color',
    },
    {
        name: "Box-Shadow",
        property: "box-shadow",
        type: "composite",
        properties: [
            {
                name: "Box-shadow-x",
                property: "x",
                type: "number",
                units: ["px"],
                default: '0'
            },
            {
                name: "Box-shadow-y",
                property: "y",
                type: "number",
                units: ["px"],
                default: '0'
            },
            {
                name: "Blur-radius",
                property: "radius",
                type: "number",
                units: ["px"],
                default: '0'  
            },
            {
                name: "Spread",
                property: "spread",
                type: "number",
                units: ["px"],
                default: '0'  
            },
            {
                type: "color",
                property: "box-shadow-color",
                default: 'rgba(0, 0, 0, 0)'
            },
        ]
    },
    {
        property: 'background-image',
        type: 'file',
        label: 'Background image',
        defaults: 'none',
        full: true,
        functionName: 'url',
        placeholder: 'Select an image...',
    },
    {
        property: 'background-position',
        default: '0% 0%', 
        type: "select",
        options: [
            { value: "0% 0%", name: "Left top" },
            { value: "0% 50%", name: "Left center" },
            { value: "0% 100%", name: "Left bottom" },
            { value: "100% 0%", name: "Right top" },
            { value: "100% 50%", name: "Right center" },
            { value: "100% 100%", name: "Right bottom" },
            { value: "50% 0%", name: "Center top" },
            { value: "50% 50%", name: "Center center" },
            { value: "50% 100%", name: "Center bottom" },
        ]
    },
    {
        property: 'background-repeat',
            type: 'select',
            label: 'Background Repeat',
            defaults: 'repeat',
            options: [
                { value: 'repeat', name: 'Repeat' },
                { value: 'no-repeat', name: 'No Repeat' },
                { value: 'repeat-x', name: 'Repeat Horizontally' },
                { value: 'repeat-y', name: 'Repeat Vertically' },
                { value: 'space', name: 'Space' },
                { value: 'round', name: 'Round' }
            ],
    },
    {
        property: 'background-size',
            type: 'select',
            label: 'Background Size',
            defaults: 'auto',
            options: [
                { value: 'auto', name: 'Auto' },
                { value: 'cover', name: 'Cover' },
                { value: 'contain', name: 'Contain' },
                { value: '100% 100%', name: 'Stretch' },
            ]
    },
    {
        label: 'Blur',
        property: 'filter',
        type: 'slider',
        units: ['px'],
        min: 0,
        max: 10,
        step: 1,
        defaults: '0px',
        full: true,
        functionName: 'blur',
    },   
    {
        name: 'Opacity',
        property: 'opacity',
        type: 'slider',
        units: [],
        min: 0,
        max: 1,
        step: 0.1,
        defaults: 1,   
    },
];

export const getBorderSectorProperties = (borderPixels) => [
    {
        label: 'Border width',
        type: 'composite',
        properties: [
            { 
                type: 'select',
                units: ['px'], 
                default: '0px', 
                property: 'border-top-width',
                options: borderPixels,
            },
            { 
                type: 'select',
                units: ['px'], 
                default: '0px', 
                property: 'border-bottom-width', 
                options: borderPixels,
            },
            { 
                type: 'select',
                units: ['px'], 
                default: '0px', 
                property: 'border-left-width', 
                options: borderPixels,
            },
            { 
                type: 'select',
                units: ['px'], 
                default: '0px', 
                property: 'border-right-width', 
                options: borderPixels,
            },
        ]
    },
    { 
        type: 'color',
        default: 'black', 
        property: 'border-color' 
    },
    {
        type: 'select',
        default: 'none',
        property: 'border-style',
        options: [
            { value: 'none', name: 'None' },
            { value: 'solid', name: 'Solid' },
            { value: 'dotted', name: 'Dotted' },
            { value: 'dashed', name: 'Dashed' },
        ],
    },
    {
        type: 'select',
        units: ["px"],
        default: '0px',
        property: 'border-radius',
        options: borderPixels,
    },
];

export const getAlignSectorProperties = () => [
    {
        name: "Direction",
        type: "radio",
        property: "flex-direction",
        defaults: "row",
        options: [
            { value: "row", label: ReactDOMServer.renderToString(<Icon color="#CDFD34" name="arrow-right" tooltip="row" />) },
            { value: "row-reverse", label:  ReactDOMServer.renderToString(<Icon color="#CDFD34" name="arrow-left" tooltip="row-reverse" />) },
            { value: "column", label:  ReactDOMServer.renderToString(<Icon color="#CDFD34" name="arrow-down" tooltip="column" />) },
            { value: "column-reverse", label:  ReactDOMServer.renderToString(<Icon color="#CDFD34" name="arrow-up" tooltip="column-reverse" />) }
        ]
    }, {
        name: "Horizontal align",
        type: "radio",
        property: "justify-content",
        defaults: "start",
        options: [
            { value: "start", label: ReactDOMServer.renderToString(<Icon color="#CDFD34" name="align-left" tooltip="align left" />) },
            { value: "center", label: ReactDOMServer.renderToString(<Icon color="#CDFD34" name="align-center" tooltip="align center" />) },
            { value: "end", label: ReactDOMServer.renderToString(<Icon color="#CDFD34" name="align-right" tooltip="align right" />) }
        ]
    }, {
        name: "Vertical align",
        type: "radio",
        property: "align-items",
        defaults: "start",
        options: [
            { value: "start", label: ReactDOMServer.renderToString(<Icon color="#CDFD34" name="align-left" tooltip="align left" />) },
            { value: "center", label: ReactDOMServer.renderToString(<Icon color="#CDFD34" name="align-center" tooltip="align center" />) },
            { value: "end", label: ReactDOMServer.renderToString(<Icon color="#CDFD34" name="align-right" tooltip="align right" />) }
        ]
    }
]