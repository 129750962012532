import { useEffect, useState, useContext } from "react";
import { BuilderContext } from "../../../../helpers/constants";
import "./BuilderTutorial.scss";
import { useRef } from "react";
import tippy from "tippy.js";

const BuilderTutorial = () => {
    const tutorialData = require("./TutorialData.json");
    const { currentTutorialPage, setCurrentTutorialPage, builderMode } = useContext(BuilderContext);
    const [currentPage, setCurrentPage] = useState(0);
    const tooltipTemplateRef = useRef(null);
    const [tippyInstance, setTippyInstance] = useState(null);

    function skipIrrelevantTutorials() {
        let tutorialBuilderMode = tutorialData[currentTutorialPage]['builderMode'];
        if (tutorialBuilderMode) {
            if (!tutorialBuilderMode.includes(builderMode)) {
                setCurrentTutorialPage(currentTutorialPage + 1);
                return;
            }
        }
        tutorialBuilderMode = tutorialData[currentTutorialPage]['data'][currentPage]['builderMode'];
        if (tutorialBuilderMode) {
            if (!tutorialBuilderMode.includes(builderMode)) {
                setCurrentPage(currentPage + 1);
                return;
            }
        }
    }

    useEffect(() => {
        if (currentTutorialPage === -1) {
            if (tippyInstance) tippyInstance.destroy();
            setCurrentPage(0);
            return;
        }

        if (currentTutorialPage >= tutorialData.length) setCurrentTutorialPage(-1);
    }, [currentTutorialPage]);

    useEffect(() => {
        if (currentTutorialPage === -1 || currentTutorialPage >= tutorialData.length) return;

        if (currentPage >= tutorialData[currentTutorialPage]['data'].length) {
            setCurrentPage(0);
            setCurrentTutorialPage(currentTutorialPage + 1);
            return;
        }

        skipIrrelevantTutorials();

        if (tippyInstance) tippyInstance.destroy();

        setTippyInstance(
            tippy(tutorialData[currentTutorialPage]['data'][currentPage]['selector'], {
                triggerTarget: document.getElementById('js-builder__topnav__info'),
                trigger: 'click',
                content(reference) {
                    return tooltipTemplateRef.current;
                },
                allowHTML: true,
                showOnCreate: true,
                interactive: true,
                theme: 'tnd',
            })[0]
        );
    }, [currentTutorialPage, currentPage]);

    if (currentTutorialPage === -1 || currentPage === -1) return <></>;
    if (currentTutorialPage >= tutorialData.length) return <></>;
    if (currentPage >= tutorialData[currentTutorialPage]['data'].length) return <></>;

    const lastPageIndex =
        builderMode === "admin"
            ? tutorialData[currentTutorialPage]['data'].length - 2
            : tutorialData[currentTutorialPage]['data'].length - 1;

    const isLastPage = currentPage === lastPageIndex;

    return (
        <template id="tooltipTemplate">
            <div ref={tooltipTemplateRef}>
                {tutorialData[currentTutorialPage]['data'][currentPage]['text']}
                <div className="grid-x align-center-middle tippy-container">
                    {!isLastPage && (
                        <>
                            <button
                                className="action cell small-3"
                                onClick={() => setCurrentPage(currentPage + 1)}
                            >
                                {currentTutorialPage === tutorialData.length ? "Close" : "Next"}
                            </button>
                            <button
                                className="action cell small-4"
                                onClick={() => setCurrentTutorialPage(-1)}
                            >
                                Skip all
                            </button>
                        </>
                    )}
                    {isLastPage && (
                        <button
                            className="action cell small-4"
                            onClick={() => setCurrentTutorialPage(-1)}
                        >
                            Close
                        </button>
                    )}
                </div>
            </div>
        </template>
    );
};

export default BuilderTutorial;
