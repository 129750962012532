import $ from "jquery";
import { BaseApiURL, PROJECT_SECTION } from "./constants";
import { getJWToken, getUser } from "./handle_token";
import Cookies from "js-cookie";

export function makeRequest(section, command, requestParams, method = "POST") {
    return new Promise((resolve, reject) => {
        const token = getJWToken();
        $.ajax({
            url: `${BaseApiURL}/${section}/${command}`,
            headers: {
                "Authorization": `${token}`,
            },
            method: method,
            data: requestParams,
            success: (data) => handleResponse(resolve, reject, data),
            error: (error) => reject(error),
            dataType: "text"
        })
    })
}

export function importPicture(formData, addToPublic = false) {
    formData.append("command", "add_image")
    formData.append("username", getUser(getJWToken()));
    formData.append("isPublic", addToPublic);
    let url = BaseApiURL;
    
    if(addToPublic) {
        url = "https://tndapi.amdev.ninja"
    }

    return new Promise((resolve, reject) => {
        $.ajax({
            url: `${url}/${PROJECT_SECTION.SECTION}/${PROJECT_SECTION.IMPORT_IMAGE}`,
            method: 'POST',
            data: formData,
            mimeType: "multipart/form-data",
            crossDomain: true,
            contentType: false,
            processData: false,
            success: (response) => handleResponse(resolve, reject, response),
            error: (error) => reject(error),
            dataType: 'text',
        });
    })
}

export function isJsonString(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

export function handleResponse(resolve, reject, response) {
    if (!isJsonString(response) || JSON.parse(response)["status"] >= 404) {
        reject(response);
        return;
    }
    response = JSON.parse(response);
    if (response["code"] === 1002 &&
        !localStorage.getItem("isModuleBuilderActive") &&
        window.location.pathname !== "/builder") {
        window.location.href = '/login';
    }
    resolve(response);
}



//#region external api requests
export function fetchCoutries() {
    return new Promise((resolve, reject) => {
        $.ajax({
            url: BaseApiURL + "/external/get-countries",  
            method: 'GET',
            success: function (response) {
                if (!isJsonString(response)) {
                    reject(response);
                }
                
                response = JSON.parse(response);
                let countries = response[1]["countries"];
                resolve(Object.entries(countries));
            },
            error: function (error) {
            },
            dataType: 'text'
        });
    })
}

export function logout() {
    Cookies.remove("sessionJWT");
}
//#endregion
