import { getComponent, setLayer } from "../../../helpers/builderFunctions";
import { makeRequest as makeRequestToModule } from "../moduleBuilder/handlers";
import { makeRequest } from "../../../helpers/handlers";
import Cookies from "js-cookie";
import { PROJECT_SECTION, REPLACEABLE_TYPES, SELECTABLE_TYPES } from "../../../helpers/constants";
import { getUser, getJWToken } from "../../../helpers/handle_token";

export const EventPlugin = (
    editor, { 
    changeManager, 
    hoveredComponent,
    setSectionOptions, 
    builderMode,
    setReplacingElement 
}) => {
    let hoveredComponentId = null;

    editor.on('storage:start:store', function (event) {
        let projectData = event.pages[0]["frames"][0]["component"]["components"];
        if (!projectData) return;

        projectData.forEach(function (component, index) {
            if (!component["attributes"]) return;
            if (component["attributes"]["data-preview"] || component['attributes']['data-dont-show'] === 'all')
                projectData.splice(index, 1);

        });

        return projectData
    });

    editor.on("storage:store", () => {
        if(localStorage.getItem("isModuleBuilderActive")) {
            makeRequestToModule("POST", "projects", "save", { projectData: localStorage.getItem("gjsProject"), pageUrls: localStorage.getItem("pageUrls") });
            return;
        }

        if(Cookies.get("temporarySession")) {
            makeRequest(PROJECT_SECTION.SECTION, PROJECT_SECTION.SAVE_DEMO, { sessionId: Cookies.get("temporarySession"), projectData: localStorage.getItem("gjsProject") })
            return;
        }

        makeRequest(PROJECT_SECTION.SECTION, PROJECT_SECTION.SAVE, { projectData: localStorage.getItem("gjsProject"), projectId: localStorage.getItem('projectId'), username: getUser(getJWToken()) });
    })

    editor.on("rte:enable", (component) => {
        editor.RichTextEditor.getToolbarEl().style.visibility = component.getClasses().includes('gjs-icon') ? 'hidden' : 'visible';
        component.model.set("badgable", false);
    })

    editor.on("rte:disable", (component) => {
        component.model.set("badgable", true);
        if(component['el'].innerHTML !== "")
            return;
        const innerText = component['el'].innerText;
        const regex = new RegExp('[a-zA-Z0-9]+');
        if(innerText.length !== 0 && regex.test(innerText))
            return;
        
        alert("Your text field can't be empty");
        component['el'].innerText = 'text';
    })

    editor.on("component:hover", (component) => {
        if (!component) return;
        hoveredComponent.current = component;
        const attributes = component.getAttributes()
        if (builderMode !== "advanced") return;
        // + above / below  
        if (attributes["data-type"] === "section" && hoveredComponentId !== attributes["id"]) {
            hoveredComponentId = attributes["id"];
            const bodyPlaceholder = getComponent(editor, ".bodyPlaceholder"),
                plusAbove = getComponent(editor, ".plusAbove"),
                plusBelow = getComponent(editor, ".plusBelow");
            if (!plusAbove || !plusBelow) return;
            plusAbove.remove({ temporary: true });
            let index = bodyPlaceholder.get("components").indexOf(component);
            bodyPlaceholder.append(plusAbove, { at: index });
            plusBelow.remove({ temporary: true });
            index = bodyPlaceholder.get("components").indexOf(component);
            bodyPlaceholder.append(plusBelow, { at: index + 1 });
            
            const displayValue = index <= -1 ? "none" : "flex";
            plusAbove.setStyle({ display: displayValue });
            plusBelow.setStyle({ display: displayValue });
        }
    })

    function setToolbar(component) {
        const attributes = component.view.model.get("attributes");
        let tb = [];
        if(builderMode === 'advanced' && REPLACEABLE_TYPES.includes(component.get('type'))) {
            tb.push({
                attributes: {
                    class: "fa fa-solid fa-retweet",
                },
                command: () => setReplacingElement(true)
            })
        }

        tb.push({
            attributes: { class: 'fa fa-paint-brush' },
            command: () => changeManager("styleManager")
        })

        if (attributes["data-type"] === "section") {
            tb.push({
                attributes: { class: 'fa fa-solid fa-retweet' },
                command: () => {
                    const sectionType = attributes["data-sectionType"];
                    if (!sectionType)
                        return;

                    const bodyPlaceholder = getComponent(editor, ".bodyPlaceholder"),
                        index = bodyPlaceholder.get("components").indexOf(component);

                    setSectionOptions({ type: sectionType, atIndex: index, replaceSection: component });
                }
            })
        }

        if (component.get("type") === "link") {
            tb.push({
                attributes: { class: 'fa fa-anchor' },
                command: "component:anchor"
            })
        }

        if (component.get('removable') && attributes['data-sectionType'] !== 'navbar' && attributes['data-sectionType'] !== 'footer') {
            tb.push({
                attributes: { class: 'fa fa-trash-o' },
                command: 'tlb-delete'
            });
        } 
        
        component.set('toolbar', tb);
    }

    editor.on("component:add", (component) => {
        if (builderMode === "admin") {
            const newClass = Math.random().toString(36).replace(/[^a-z]+/g, '').substring(2, 10);
            component.addClass(newClass);
        } 

        let sectionName = component.getAttributes()["section-name"];
        if (!sectionName) return;
        editor.LayerManager.setName(component, sectionName);

    })
    editor.on("run:core:component-delete:after", (component) => {
        editor.UndoManager.skip(() => {
            //Todo: add an attribute for container components instead of listing all classes
            if(component.getClasses().includes("gjs-container") || 
                component.getClasses().includes("gjs-row") || 
                component.getClasses().includes("gjs-cell")
            ) {
                const parent = component.parent();
                if(parent.components().length === 0)
                    parent.remove();
            }
        });
    });

    const fontFamilyMapping = {
        "Times": "Times",
        "serif": "Times"  // Treat both "Times" and "serif" as "Times"
    };    
    editor.on('component:selected', (component) => {
        if (builderMode === 'admin') {
            return;
        }
        if (!component || !component.getEl()) return;
        const attributes = component.getAttributes();
        const model = component.view.model;
        if(attributes['data-type'] === 'section')
            component.getEl().scrollIntoView({ behavior: "smooth" });
        // This is the modal method
        if (attributes["data-sectionType"] && attributes["data-type"] === "button") {
            // Take parent because the circle is a child of the container
            const bodyPlaceholder = getComponent(editor, ".bodyPlaceholder");
            if (bodyPlaceholder) {
                const index = bodyPlaceholder.get("components").indexOf(component.parent());
                setSectionOptions({ type: attributes["data-sectionType"], atIndex: index });
            }
        }
        const domElement = component.getEl();
        const computedStyle = window.getComputedStyle(domElement);
        const styleObject = {};
        editor.StyleManager.getSectors().forEach((sector) => {
            sector.get('properties').each((property) => {
                const propertyName = property.get('property').toLowerCase();
                let propertyValue = computedStyle[propertyName];
                
                if (propertyName === 'font-family' && fontFamilyMapping[propertyValue]) {
                    propertyValue = fontFamilyMapping[propertyValue];
                }
                if (propertyName.startsWith('box-shadow')) 
                    return; 
                styleObject[propertyName] = propertyValue;
                property.upValue(propertyValue, { noTarget: true });
            });
        });
        delete styleObject['background-color'];
        delete styleObject.width;
        delete styleObject.height;
        component.addStyle(styleObject);

        //* In progress
        /*
        const defaultStyle = model['attributes']['default-style'] || {};
        const isTransparent = (color) => {
            // Check if the color is an rgba value with a transparent alpha channel
            return color.startsWith('rgba') && color.endsWith(', 0)');
        };

        const findFirstNonTransparentParentBackground = (comp) => {
            let parent = comp.parent();
            while (parent && parent.getEl()) {
                const parentBackground = window.getComputedStyle(parent.getEl()).backgroundColor;
                if (!isTransparent(parentBackground)) {
                    return parentBackground; // Return the first non-transparent background color
                }
                parent = parent.parent(); // Continue to the next parent
            }
            return 'rgb(255, 255, 255)'; // Default to white if all parents are transparent
        };
        let hasChanges = false;
        editor.StyleManager.getSectors().forEach((sector) => {
            sector.get('properties').each((property) => {
                const propertyName = property.get('property').toLowerCase();
                let propertyValue = computedStyle[propertyName];
                // Apply font-family normalization
                if (propertyName === 'font-family' && fontFamilyMapping[propertyValue]) {
                    propertyValue = fontFamilyMapping[propertyValue];
                }
                // If background is transparent, use the parent's background color
                if (propertyName === 'background-color' && isTransparent(propertyValue)) 
                    propertyValue = findFirstNonTransparentParentBackground(component);

                if (defaultStyle[propertyName] !== undefined) {
                    if (defaultStyle[propertyName] !== propertyValue) {
                        console.log(defaultStyle[propertyName], propertyValue);
                        hasChanges = true;
                    }
                }
                styleObject[propertyName] = propertyValue;
                property.upValue(propertyValue, { noTarget: true });                
            });
        });
        // Add the style object to the component
        if (!model['attributes']['default-style']) 
            model.set({ 'default-style': styleObject });        

        delete styleObject.width;
        delete styleObject.height;
        component.addStyle(styleObject);
        editor.StyleManager.getConfig().clearProperties = hasChanges;
        editor.StyleManager.render();
        */
    });

    editor.on('component:styleUpdate:background-color', (component, value) => {
        
        const color = value['style']['background-color'];
    
        const isTransparent = (color) => {
            // Check if the color is an rgba value with a transparent alpha channel
            return color.startsWith('rgba') && color.endsWith(', 0)');
        };
    
        if (isTransparent(color)) {
            // Convert rgba(x, x, x, 0) to rgba(x, x, x, 1)
            const opaqueColor = color.replace(/[^,]+(?=\))/, ' 1');
            component.addStyle({ 'background-color': opaqueColor });
        }
    });

    function setTraits(component) {
        component.removeTrait(['title', 'id']);
        const attributes = component.getAttributes();

        if(component.get('type') === 'text' || component.get('type') === 'link') {
            component.addTrait({
                name: 'text',
                type: 'innerText'
            })
        } 

        if (attributes["data-sectionType"] === "navbar") {
            component.addTrait({
                type: "addLink",
                name: "addLink"
            })
        }

        if(attributes['data-email']) {
            component.addTrait({
                type: "data-email",
                name: "data-email"
            })
        }
    }

    function setSelectable(component) {
        const model = component.view.model,
            attributes = model.get("attributes"),
            modelOpts = {
                selectable: false,
                hoverable: false,
                layerable: false,
                draggable: false,
            };
        
        if(attributes['data-unselectable']) {
            component.view.model.set({"selectable": false, "hoverable": false, "resizable": false});
        }

        if (builderMode === "admin") 
            return;

        if (component.getClasses().includes("bodyPlaceholder")) {
            editor.LayerManager.setName(component, "SECTIONS");
            setTimeout(() => {
                setLayer(component);
            }, 10);
            editor.Layers.setOpen(component, true);
        }

        if (component.getClasses().includes("bodyPlaceholder") || attributes["data-type"] === "section"
            || component.getClasses().includes("addSectionButton")) {
            modelOpts["layerable"] = true;
        }
        
        if (SELECTABLE_TYPES.includes(component.get("type")) || component.getClasses().includes("gjs-cell")
        || attributes["data-type"] === "section" || attributes['data-selectable'] || attributes['data-type'] === 'button') {
            modelOpts["selectable"] = true;
            modelOpts["hoverable"] = true;
        }

        if (attributes["data-sectionType"] === "body" && attributes['data-type'] !== 'button') { 
            modelOpts["draggable"] = ".bodyPlaceholder";
        }

        if(attributes["data-unhoverable"] || attributes["data-uneditable"]) {
            modelOpts["hoverable"] = false;
            modelOpts["selectable"] = false;
            modelOpts["highlightable"] = false;
        }
        
        if(attributes["data-uneditable"]) {
            modelOpts["deletable"] = false;
            modelOpts["editable"] = false;  
            modelOpts["propagate"] = ["editable", "selectable", "hoverable"];
        }

        model.set(modelOpts, { silent: true })
    }

    function setEventAttributes(component) {
        let eventFunction = '', eventType = '';
        
        if(component.get('type') === 'image'){
            eventFunction = 'fullscreenImage';
            eventType = 'click';
        }
        if(component.get('type') === 'link') {
            eventFunction = 'goToLink';
            eventType = 'click';
        }
        if(eventFunction === '' || eventType === '') return;

        const attributes = component.getAttributes();
        attributes['data-eventType'] = eventType;
        attributes['data-eventFunction'] = eventFunction;
        component.setAttributes(attributes);
    }

    editor.on("component:mount", (component) => {
        setTraits(component);
        setToolbar(component);
        setSelectable(component);

        setEventAttributes(component);

        //? ???
        setTimeout(() => {
            setLayer(component, false);
        }, 10);
    })

    //? Remove useless option from rich text editor
    editor.on("load", () => {
        const undoButton = editor.Panels.getButton("js-builder__panels--actions", "js-builder__actions--undo"),
        redoButton = editor.Panels.getButton("js-builder__panels--actions", "js-builder__actions--redo");

        undoButton.set("disable", true);
        redoButton.set("disable", true);
        editor.RichTextEditor.remove('wrap');
        editor.on("component:update undo redo", () => {
            //Todo: do the same for actions-copy
            const undoButton = editor.Panels.getButton("js-builder__panels--actions", "js-builder__actions--undo"),
                redoButton = editor.Panels.getButton("js-builder__panels--actions", "js-builder__actions--redo");
    
            undoButton.set("disable", !editor.UndoManager.hasUndo());
            redoButton.set("disable", !editor.UndoManager.hasRedo());
        })    
    })

    editor.on("component:remove component:add load", () => {
        const bodyPlaceholder = getComponent(editor, ".bodyPlaceholder"),
        addSectionButton = getComponent(editor, ".addSectionButton");
        if (!bodyPlaceholder || !addSectionButton) return;
        editor.UndoManager.skip(() => {
            addSectionButton.setStyle({
                display: (bodyPlaceholder.get("components").length <= 2 ? "flex" : "none")
            })
        })
    })

    const modal = editor.Modal;
    const createConfirmationModal = () => {
        const modalContent = document.createElement('div');
        modalContent.classList.add('delete-component--modal', 'grid-x', 'grid-margin-x');
        const header = document.getElementsByClassName("gjs-mdl-title")[0];
        header.innerHTML = 'Are you sure you want to delete this component ?';
        
    
        const confirm = document.createElement('button');
        confirm.classList.add("action__red", 'cell', 'small-4', 'small-offset-1');
        confirm.innerHTML = 'Delete';
        
    
        confirm.addEventListener('click', () => {
            const selected = editor.getSelectedAll();
            const isRemovable = selected.every(component => component.get('removable'));
            if (!isRemovable) {
                header.innerHTML = "Error";
                header.classList.add("alert__message--title")
                modalContent.innerHTML = "You are trying to remove non-removable content!";
                modalContent.classList.add("alert__message");
                return;
            }
    
            selected.forEach((component) => {
                //removal of links
                if(component.getAttributes()['data-gjs-type'] === 'link') {   
                    component.parents().forEach((parent) => {
                        if (parent.getAttributes()["data-sectionType"] === "navbar") {
                            component.parent().destroy();
                        }
                    })
                }
                component && component.destroy();
            });
            editor.select(null);
            modal.close();
        });
        
        const cancel = document.createElement('button');
        cancel.classList.add("action__neutral", 'cell', 'small-4');
        cancel.innerHTML = 'Cancel';
        cancel.addEventListener('click', () => modal.close());
    
        
        const buttonWrapper = document.createElement('div');
        buttonWrapper.classList.add('delete-element--options');
        buttonWrapper.appendChild(cancel);
        buttonWrapper.appendChild(confirm);
    
        // Append the wrapper div to the modal content
        modalContent.appendChild(buttonWrapper);
    
        modal.setContent(modalContent);
    };
    

    editor.on('run:core:component-delete:before', options => {
        if(builderMode === 'basic') {
            options.abort = true;
            return;
        }
        options.abort = true;
        modal.open();
        createConfirmationModal();
    });

    editor.on("undo", () => {
        const components =  editor.Components.getComponents();
        if(!components || components.length == 0) {
            const pageId = editor.Pages.getSelected()['id'];
            editor.Pages.select(editor.Pages.getMain());
            editor.Pages.remove(pageId);
        }
    })

    let lastUnitsSelected = {};
    editor.on("load", () => {
        const props = ["width", "height"];
        props.forEach(prop => {
            const propertyContainer = document.getElementsByClassName(`gjs-sm-property__${prop}`)[0];
            if(!propertyContainer)
                return;
            
            const propertyUnitsInput = propertyContainer.getElementsByClassName('gjs-input-unit')[0];
            propertyUnitsInput.addEventListener("change", () => {            
                lastUnitsSelected[prop] = propertyUnitsInput.value;
            })
        })

        const undoButton = editor.Panels.getButton("js-builder__panels--actions", "js-builder__actions--undo"),
        redoButton = editor.Panels.getButton("js-builder__panels--actions", "js-builder__actions--redo");

        undoButton.set("disable", true);
        redoButton.set("disable", true);
        editor.RichTextEditor.remove('wrap');
        editor.on("component:update undo redo", (component) => {
            // console.log(editor.UndoManager.getStack());
            //Todo: do the same for actions-copy
            const undoButton = editor.Panels.getButton("js-builder__panels--actions", "js-builder__actions--undo"),
                redoButton = editor.Panels.getButton("js-builder__panels--actions", "js-builder__actions--redo");
            
            undoButton.set("disable", !editor.UndoManager.hasUndo());
            redoButton.set("disable", !editor.UndoManager.hasRedo());

            const smallUndoButton = editor.Panels.getButton("js-builder__panels--actions__copy", "js-builder__actions--undo"),
                smallRedoButton = editor.Panels.getButton("js-builder__panels--actions__copy", "js-builder__actions--redo");

            smallUndoButton.set("disable", !editor.UndoManager.hasUndo());
            smallRedoButton.set("disable", !editor.UndoManager.hasRedo());
        })    
    })

    editor.on("component:styleUpdate", (component, property) => {
        const key = Object.keys(property['style'])[0];
        let value = Object.values(property['style'])[0];
        if((key !== 'width' && key !== 'height') || !value || !lastUnitsSelected[key])
            return;

        if(!lastUnitsSelected[key] || lastUnitsSelected[key] === "")
            return;

        console.log(lastUnitsSelected);
        value = value.match(/\d+/g) + lastUnitsSelected[key];
        
        const propertyContainer = document.getElementsByClassName(`gjs-sm-property__${key}`)[0];
        if(!propertyContainer)
            return;
    
        const propertyUnitsInput = propertyContainer.getElementsByClassName('gjs-input-unit')[0];
        propertyUnitsInput.value = lastUnitsSelected[key];
        lastUnitsSelected[key] = "";
        component.setStyle({ [key]: value });
    })
}