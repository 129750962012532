export const defaultCss = `
*, body{
    margin: 0;
    padding: 0;
}

body{  
    height: 100vh;
}

.flex-center, .carousel-item-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.carousel-item {
    display: none;
    justify-content: center;
    align-items: center;
    width: 50vw;
    height: 30vh;
    border: 1px solid black;
    border-radius: 5px;
}

[data-gjs-type="wrapper"]{
    height: 100vh;
}

.gjs-container{
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width:100%;
    height: 100vh;
    max-height: 100%;
}

.gjs-row{
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex-basis: 100%;
    max-height: 100%;
}

.gjs-cell{
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    height: 100%;
    flex-basis: 100%;
}

.image-container, image-tooltip{
    display: flex;
    justify-content: center;
    align-items: center;
}

.image-tooltip{
    position: absolute;
    display: flex;
    text-align: center;
    align-items: center;
    pointer-events: none;
    opacity: 70%;
    z-index: 2;
    padding: 10px;
    width: 100px;
    height: 100px;
    font-size: 12px;
    font-weight: bold;
    border-radius: 3px;
}

a{
    text-decoration: none;
}
.icon {
    width: 50px;
    height: 50px;
}
.section{
    width: 100vw;
}
.gjs-icon {
    display: block;
    width: 50px;
    height: 50px;
}
.gjs-image {
    margin: 0% !important;
    aspect-ratio: auto;
    max-width: 100%;
    max-height: 100%;
}
#imagePreview{
    display: none;
    position: absolute;
    width: 100vw;
    height: 100vh;
    z-index: 999,
    background-repeat: no-repeat;
    background-size: cover;
    background-color: rgb(0, 0, 0, 0.7);
    background-position: center center;
}
.addSectionPlus {
    display: none;
    position: absolute;
    width: 100%;
    height: 0vh;
    z-index: 3 !important;
    justify-content: center;
    align-items: center;
}

.addSectionPlus > div {
    border: 2px solid black;
    border-radius: 100%;
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.addSectionButton{
    justify-content: center;
    align-items: center;
}
[data-gjs-type='text'], [data-gjs-type='span'] {
    z-index: 1;
}

h1, span {
    padding: 2%;
}

.addSectionPlus > div:hover::after{
    position: absolute;
    background-color: black;
    border: 1px solid white;
    color: #CDFD34;
    padding: 1%;
    top: 25px;
    border-radius: 5px;
    z-index: 9999;
    pointer-events: none;
    content: 'Add a new section';
}
.form-block{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 12px;
    font-weight: bold;
    height: 40vh;
}
.space-between{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 30%;
    
}
.space-between input, .space-between textarea{
    border-radius: 0.3em;
    border: 1px solid gray;
    padding: 9px;
    width: 50%;
    transition: all 0.1s;
    
}
.space-between input:hover, .space-between textarea:hover{
    box-shadow: 0 0 7px -2px
}

.form__button{
    padding: 6px 16px;
    background-color: #CDFD34;
    color: black;
    border: 0;
    align-self: center;
    border-radius: 0.3em;
    transition: all 0.1s;
}
.form__button:hover{
    cursor: pointer;
    background-color: black;
    color: #CDFD34;
    border: 0;
}

.button{
    padding: 7px 2px;
    color: black;
    background-color: #CDFD34;
    border-radius: 0.3em;
    border: 0;
    transition: all 0.1s;
    cursor: pointer;
}

.button:hover{
    background-color: black;
    color: #CDFD34;
}

[data-gjs-type="video"], [data-gjs-type="map"] {
    max-width: 100vw;
}
`;

export function getComponent(editor, selector) {
    if (!editor.DomComponents.getWrapper())
        return undefined;
    return editor.DomComponents.getWrapper().find(selector)[0];
}

export function getNestedComponents(editor, component, templateClass = "") {
    const attributes = component.getAttributes();
    if (attributes["data-dont-show"] === "template"
        || attributes["data-dont-show"] === "all") return;
    
    if (component.get('type') === 'textnode') return component.view.el.textContent;
    
    const nestedComponents = component.components(),
        json = {
            tagName: component.get("tagName"),
            components: [],
            attributes: [],
            "gjs-type": component.get("type")
        };
    
    nestedComponents.forEach(component => {
        const currComponent = getNestedComponents(editor, component, templateClass);
        if (currComponent)
            json["components"].push(currComponent);
    })

    if(json['gjs-type'] === 'text') {
        console.log(component.components());
        json['components'] = [component.view.el.innerHTML];
    }

    for (const name in attributes) {
        if (name === 'class')
            attributes[name] += " " + templateClass;
        json["attributes"].push({ [name]: attributes[name] });
    }
    return json;
}

export function addSection(editor, selectedSection) {
    if (!editor || !selectedSection) return false;

    if(selectedSection['pages']) {
        selectedSection = selectedSection['pages'][0]['frames'][0]['component'];
    }
    
    let attributes = {
        "data-type": "section",
        "data-sectionType": selectedSection['sectionType'],
    };

    if (selectedSection['name']) {
        attributes = {
            ...attributes,
            'section-name': selectedSection['name'],
        };
    }

    if(!selectedSection['components'])
        selectedSection = selectedSection['project_data'];

    const mainComponent = selectedSection["components"][0];
    if (!mainComponent) return false;
    
    let components = [];
    mainComponent['attributes'].forEach(attr => attributes = { ...attributes, ...attr });
    mainComponent['components'].forEach(component => {
        if (component)
            components.push(renderComponent(editor, component));
    });

    const newSection = editor.DomComponents.addComponent({
        tagName: mainComponent['tagName'],
        style: {
            display: 'flex',
        },
        attributes: attributes,
        components: components,
    });

    newSection.set({
        "draggable": ".bodyPlaceholder",
        "resizable": false,
    });

    selectedSection['cssRules'].forEach(rule => editor.Css.addRules(rule));

    editor.Css.remove('.gjs-container');
    if (selectedSection['sectionType'] === 'navbar') {
        for (let pageIndex = 1; pageIndex < editor.Pages.getAll().length; pageIndex++) {
            const page = editor.Pages.getAll()[pageIndex];
            addLinkToNavBar(newSection, page.getName(), page['id']);
        }
    }
    return newSection;
}

export function deletePreviewedSection(editor) {
    if (!editor)
        return;

    const previewComponents = editor.DomComponents.getComponents().filter(
        component => component.getAttributes()["data-preview"]);
    previewComponents.forEach((component) => {
        component.remove();
    })
}

export function renderComponent(editor, component) {
    if (!component) return;
    if (typeof component === 'string') return component;

    let attributes = {
    };
    if (Array.isArray(component['attributes']))
        component["attributes"].forEach(object => attributes = { ...attributes, ...object });
    else {
        attributes = component['attributes'];
    }

    if (attributes["data-dont-show"] === "all") return;

    const types = editor.DomComponents.getTypes(),
        currType = types.filter((type) => component["gjs-type"] === type['id']);
    let componentParams = {}
    if (currType.length > 0)
        componentParams["type"] = component["gjs-type"];
    else
        componentParams["tagName"] = component["tagName"];

    componentParams['attributes'] = attributes;

    //? else if attributes['data-blank'] render inner components from the type
    //? else render inner components from the template
    if (!attributes['data-blank']) {
        componentParams['components'] = [];
        for (let index in component["components"]) {
            componentParams['components'].push(renderComponent(editor, component['components'][index]));
        }
    }
    return componentParams;
}

export function addLinkToNavBar(component, text, id = null) {
    const existingLinks = component.find("a");
    let length = existingLinks.length;
    const containerElement = existingLinks[length - 1].parent(),
        newLink = containerElement.clone();

    newLink.components()['models'][0].empty();
    newLink.components()["models"][0].set({
        "removable": true,
    })
    newLink.components()['models'][0].append(text);

    if(id)
        newLink.components()['models'][0].addAttributes({ href: `page:${id}` })

    let index = containerElement.parent().components().indexOf(containerElement);
    containerElement.parent().append(newLink, { at: index + 1 });
}

//* Front End


export function truncateText(text, maxLength) {
    if (text.length > maxLength)
        return text.slice(0, maxLength) + '...';
    return text;
}

export function lenghtenText(ref, value, class_name) {
    if (!ref.current) return;
    ref.current.style.transition = "all 0.1s";
    ref.current.classList.add(class_name);
    ref.current.textContent = value;
}

export function reTruncateInput(ref, value, maxChars, class_name) {
    if (!ref.current) return;
    ref.current.classList.remove(class_name);
    ref.current.textContent = truncateText(value, maxChars);
}

export function capitalizeFirstLetters(str) {
    return str.replace(/(^\s*\w|(\.\s*)\w)/g, function (match) {
        return match.toUpperCase();
    });
}

export function formatDate(dateString) {
    const [year, month, day] = dateString.split('-');
    const paddedDay = (parseInt(day, 10) < 10 ? '0' : '') + parseInt(day, 10);
    const paddedMonth = (parseInt(month, 10) < 10 ? '0' : '') + parseInt(month, 10);
    return `${paddedDay}.${paddedMonth}.${year}`;
}

export function capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export function setLayer(component, removeMove = true) {
    if(!component || !component.viewLayer) return;
    const el = component.viewLayer.el;
    if (!el) return;
    const layer = el.querySelector('.gjs-layer-item');
    if (!layer) return;
    layer.classList.remove("gjs-layer-vis");
    layer.removeAttribute("data-toggle-visible");
    const svgVis = el.querySelector('.gjs-layer-vis');
    if (!svgVis) return;
    svgVis.remove();
    if (removeMove) {
        layer.classList.remove("gjs-layer-move");
        layer.removeAttribute("data-toggle-move");
        const svgMove = el.querySelector('.gjs-layer-move');
        if (!svgMove) return;
        svgMove.remove();
    }
}

export function appendAddSectionPluses(editor, bodyPlaceholder) {
    const addSectionPlus = {
        tagName: 'div',
        badgable: false,
        components: {
            tagName: 'div',
            badgable: false,
            attributes: {
                'data-type': 'button',
                'data-sectionType': 'body',
                'data-selectable': true,
                // 'data-uneditable': true
            },
            content: '<svg fill="#000000" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="32px" height="32px" viewBox="-4.54 -4.54 54.48 54.48" xml:space="preserve" stroke="#000000" stroke-width="0.00045402" transform="rotate(0)matrix(1, 0, 0, 1, 0, 0)"><g id="SVGRepo_bgCarrier" stroke-width="0" transform="translate(0,0), scale(1)"><rect x="-4.54" y="-4.54" width="54.48" height="54.48" rx="27.24" fill="#ffffff" strokewidth="0"></rect></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" stroke="#0cafcf" stroke-width="0.09080400000000001"></g><g id="SVGRepo_iconCarrier"> <g> <path d="M41.267,18.557H26.832V4.134C26.832,1.851,24.99,0,22.707,0c-2.283,0-4.124,1.851-4.124,4.135v14.432H4.141 c-2.283,0-4.139,1.851-4.138,4.135c-0.001,1.141,0.46,2.187,1.207,2.934c0.748,0.749,1.78,1.222,2.92,1.222h14.453V41.27 c0,1.142,0.453,2.176,1.201,2.922c0.748,0.748,1.777,1.211,2.919,1.211c2.282,0,4.129-1.851,4.129-4.133V26.857h14.435 c2.283,0,4.134-1.867,4.133-4.15C45.399,20.425,43.548,18.557,41.267,18.557z"></path> </g> </g></svg>'
        }
    };

    bodyPlaceholder.append(
        editor.addComponents({
            ...addSectionPlus,
            attributes: {
                class: 'addSectionPlus plusAbove',
                'data-dont-show': 'all',
            },
        })[0]
    );

    bodyPlaceholder.append(
        editor.addComponents({
            ...addSectionPlus,
            attributes: {
                class: 'addSectionPlus plusBelow',
                'data-dont-show': 'all',
            },
        })[0]
    );
}