import { useEffect, useContext, useState, useRef } from "react";
import BuilderPageTab from "./BuilderPageTab";
import { BuilderContext } from "../../../../../helpers/constants";


const BuilderPages = () => {
    const { editor } = useContext(BuilderContext);
    const [currentPage, setCurrentPage] = useState();
    const [pages, setPages] = useState([]);
    const [deletePageId, setDeletePageId] = useState("");
    const addPageButtonRef = useRef(null);

    function deletePage(event) {
        event.preventDefault();
        editor.Pages.remove(deletePageId);
        
        setPages(editor.Pages.getAll());
        if (currentPage === deletePageId)
            editor.Pages.select(editor.Pages.getMain());
        setDeletePageId(null);
    }

    function addPage() {
        if (pages.length >= 3 || editor.Pages.getAll().length >= 3) {
            addPageButtonRef.current.style.display = "none";
            setPages(editor.Pages.getAll());
            return;
        }
        let counter = 0,
            pageName = `Page ${pages.length + 1}`;
        //? If the page name currently exists add '(${counter})' to it
        while(pages.filter(page => page['attributes']['name'] === pageName).length > 0) {
            counter += 1;
            pageName = `Page ${pages.length + 1}(${counter})`;
        }

        editor.Pages.add({
            id: Math.random().toString(36).slice(2),
            name: pageName,
            component: ``
        });
        setPages(editor.Pages.getAll());
        addPageButtonRef.current.style.display = pages.length >= 2 ? "none" : "flex";
    }

    useEffect(() => {
        if (!editor) return;
        setCurrentPage(editor.Pages?.getSelected()?.attributes?.id);
        editor.on("page:select", page => setCurrentPage(page['id']));
        editor.on("page:remove", (page) => {
            setPages(editor.Pages.getAll());
        })
        editor.on("load", () => {
            setPages(editor.Pages.getAll());
            const pageUrls = JSON.parse(localStorage.getItem("pageUrls"));
            // editor.Pages.select();
        });
    }, [editor]);

    useEffect(() => {
        if (!editor) return;
        editor.Pages.select(currentPage);
    }, [currentPage, editor]);

    useEffect(() => {
        if (addPageButtonRef.current) {
            addPageButtonRef.current.style.display = pages.length >= 3 ? "none" : "flex";
        }
    }, [pages]);

    return (
        <div className="builder__pages-container">
            <div className="builder__page-tabs align-left grid-x">
                {deletePageId &&
                    <form
                        className="builder--delete-page-modal__tabs grid-x align-center-middle text-center grid-margin-x"
                        onSubmit={deletePage}
                    >
                        <h5 className="cell bold">
                            Are you sure you want to delete
                            <span>
                                {` ${editor.Pages.get(deletePageId)?.attributes["name"]}`}
                            </span>
                            ?
                        </h5>
                        <div className="cell">
                            <div className="grid-x grid-margin-x align-center-middle grid-margin-y">
                                <button
                                    className="action__neutral cell large-5"
                                    onClick={() => setDeletePageId(null)}
                                >
                                    Cancel
                                </button>
                                <button
                                    className="action__red cell large-5"
                                    type="submit"
                                >
                                    Delete
                                </button>
                            </div>
                        </div>
                    </form>
                }
                {pages.map((page, index) =>
                    <BuilderPageTab
                        key={index}
                        editor={editor}
                        attributes={page['attributes']}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        setDeletePageId={setDeletePageId}
                    />
                )}
                <div style={{display: "flex"}} className="builder__pages__add-btn--container" ref={addPageButtonRef}>
                    <button onClick={addPage} className="builder__pages__add-btn text-center">
                        <div className="builder__pages__add-btn--circle">
                            +
                        </div>
                    </button>
                </div>
            </div>
        </div>
    );
}

export default BuilderPages;
