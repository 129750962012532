import doubleClick from "../../../../images/doubleClick.webp";
const textStyleProperties = ["font-family", "font-size", "letter-spacing", "text-align", 
                            "color", "margin", "padding", "background-color", 
                            "border", "border-radius", 
                            "Margin", "Padding", "width", "height", "align-items", "justify-content", "box-shadow", "position"];

let script = function () {
    const prev = this.getElementsByClassName("carousel-prev")[0],
        next = this.getElementsByClassName("carousel-next")[0],
        itemContainer = this.getElementsByClassName("carousel-item-container")[0],
        items = itemContainer.getElementsByClassName("carousel-item");
    let index = 0;

    function deselectItems() {
        for (let index = 0; index < items.length; index++) {
            items[index].style.display = "none";
        }
    }

    function selectItem(index) {
        items[index].style.display = "flex";
        itemContainer.setAttribute("data-index", index);
    }


    prev.addEventListener("click", () => {
        index -= 1;
        if (index < 0)
            index = items.length - 1;
        deselectItems();
        selectItem(index);
    })

    next.addEventListener("click", () => {
        index += 1;
        if (index >= items.length)
            index = 0;

        deselectItems();
        selectItem(index);
    })
    selectItem(index);
}

export const spanComponentConfig = {
    model: {
        defaults: {
            tagName: "span",
            style: {
                display: "flex"
            },
            stylable: [...textStyleProperties, "Margin-composite", "Padding-composite"],
            components: {
                type: "text",
                content: "Add your text here"
            }
        },
    }
};

export const h1ComponentConfig = {
    model: {
        defaults: {
            tagName: "h1",
            style: {
                display: "flex"
            },
            stylable: [...textStyleProperties, "Margin-composite", "Padding-composite"],
            components: {
                type: "text",
                content: "Add your text here"
            }
        },
    }
};

export const getImageComponentConfig = (editor) => {
    const componentConfig = {
        model: {
            defaults: {
                stylable: ["Margin", "Padding", "width", "height", "border-radius", "position", "z-index", "box-shadow"],
                draggable: "[data-gjs-type='gjs-container']",
                traits: [
                    {
                        name: "ratio",
                        type: "select",
                        default: "auto",
                        changeProp: 1,
                        options: [
                            { value: "auto", name: "auto" },
                            { value: "1/1", name: "1/1" },
                            { value: "16/9", name: "16/9" },
                            { value: "4/3", name: "4/3" },
                            { value: "2/1", name: "2/1" },
                            { value: "1/2", name: "1/2" },
                            { value: "3/4", name: "3/4" },
                            { value: "9/16", name: "9/16" }
                        ]
                    },
                    {
                        type: "openAsset",
                    },
                ],
            },
            init() {
                const height = this.getStyle()["height"];
                const width = this.getStyle()["width"];
                this.on('change:ratio', (component) => {
                    if (component.get("ratio") === "auto") {
                    //     component.setStyle({ width: width, height: height })
                        return;
                    }
                    const rect = component.view.el.getBoundingClientRect(),
                        ratios = component.get("ratio").split("/"),
                        heightRatio = parseInt(ratios[1]) / parseInt(ratios[0]),
                        newHeight = parseInt(rect.width * heightRatio);
                    component.setStyle({
                        width: rect.width + "px",
                        height: newHeight + "px"
                    })
                })
            }
        }
    }

    return componentConfig;
}

export const listItemConfig = {
    model: {
        defaults: {
            tagName: "li",
            traits: [
                {
                    type: "addListItem",
                    name: "addListItem"
                }
            ],
            components: [{
                type: "span",
                traits: [{
                    type: "addListItem",
                    name: "addListItem"
                }]
            }]
        },

    }
};

export const listComponentConfig = {
    model: {
        defaults: {
            stylable: textStyleProperties,
            draggable: "[data-gjs-type='gjs-container']",
            tagName: "ul",
            traits: [
                'class',
                {
                    type: "addListItem",
                    name: "addListItem",
                    label: ""
                }
            ],
            components: [{
                type: "list-item"
            }]
        }
    }
};

export const inputComponentConfig = {
    model: {
        defaults: {
            tagName: "input",
            stylable: [...textStyleProperties, "width", "height", "background-color", "border", "border-radius", "Margin-composite", "Padding-composite"],
            draggable: "[data-gjs-type='gjs-container']",
            traits: [
                'placeholder',
                {
                    type: "checkbox",
                    name: "required"
                }, {
                    type: "select",
                    label: "Type",
                    name: "type",
                    default: "text",
                    options: [
                        { id: "text", name: "Text" },
                        { id: "email", name: "Email" },
                        { id: "password", name: "Password" },
                        { id: "number", name: "Number" }
                    ]
                }
            ]
        }
    }
};

export const linkComponentConfig = {
    model: {
        defaults: {
            stylable: [...textStyleProperties, "background-color", "border-radius", "fill"],
            draggable: "[data-gjs-type='gjs-container']",
            tagName: "a",
            // components: {
                // type: "text",
                components: "text",
            // },
            attributes: {
                "data-gjs-type": "link",
                href: ""
            },
            traits: [
                {
                    type: "setHref",
                    name: "href",
                    label: "link to",
                }
            ]
        }
    }
};

export const carouselItemComponentConfig = {
    model: {
        defaults: {
            stylable: ["background-color", "width", "height", "border-radius"],
            draggable: "[data-gjs-type='gjs-container']",
            tagName: "div",
            components: {
                tagName: "img",
                type: "image",
                attributes: {
                    "data-gjs-type": "image",
                    src: doubleClick
                },
                style: {
                    "max-width": "100%",
                    "max-height": "100%"
                }
            },
            attributes: {
                class: "carousel-item"
            },
            traits: [{
                type: "carousel",
                name: "carousel",
                label: "carousel"
            }]
        }
    }
};

export const carouselComponentConfig = {
    model: {
        defaults: {
            draggable: "[data-gjs-type='gjs-container']",
            script,
            tagName: "div",
            attributes: {},
            traits: [
                {
                    type: "carousel",
                    name: "carousel",
                    label: "carousel"
                }
            ],
            components: [{
                tagName: "button",
                components: "<",
                attributes: {
                    class: "carousel-prev"
                }
            }, {
                tagName: "div",
                components: [
                    { type: "carousel-item" },
                    { type: "carousel-item" },
                    { type: "carousel-item" },
                ],
                attributes: {
                    class: "carousel-item-container",
                    "data-index": 0
                },
            }, {
                tagName: "button",
                components: ">",
                attributes: {
                    class: "carousel-next"
                }
            }],
            style: {
                display: "flex",
                "align-items": "center",
                "justify-content": "center",
                width: "100%",
            }
        }
    }
};

export const cellComponentConfig = {
    model: {
        defaults: {
            draggable: "[data-gjs-type='gjs-container']",
            tagName: "div",
            attributes: {
                class: "gjs-cell",
                "data-gjs-type": "gjs-container",
            },
            traits: [{
                type: "addRow",
                name: "addRow"
            }, {
                type: "addCol",
                name: "addCol"
            }]
        }
    }
}

export const rowComponentConfig = {
    model: {
        defaults: {
            draggable: "[data-gjs-type='gjs-container']",
            tagName: "div",
            attributes: {
                class: "gjs-row",
                "data-gjs-type": "gjs-container",
            },
            traits: [{
                type: "addRow",
                name: "addRow"
            },
            {
                type: "addCol",
                name: "addCol"
            }],
            components: [{
                type: "cell"
            }],
        }
    }
}

export const containerComponentConfig = {
    model: {
        defaults: {
            tagName: "div",
            attributes: {
                class: "gjs-container",
            },
            traits: [{
                type: "addRow",
                name: "addRow"
            }],
            components: [{
                type: "row"
            }]
        }
    }
}

export const tableCellComponentConfig = {
    model: {
        defaults: {
            tagName: "td",
            draggable: "tr",
            traits: [{
                type: "addTableRow",
                name: "addTableRow"
            }, {
                type: "addTableCell",
                name: "addTableCell"
            }],
            type: "container",
            components: [{
                type: "span",
                traits: [{
                    type: "addTableRow",
                    name: "addTableRow"
                }, {
                    type: "addTableCell",
                    name: "addTableCell"
                }],
            }]
        }
    }
}

export const tableRowComponentConfig = {
    model: {
        defaults: {
            tagName: "tr",
            draggable: "table",
            traits: [{
                type: "addTableRow",
                name: "addTableRow"
            }, {
                type: "addTableCell",
                name: "addTableCell"
            }],
            components: [{
                type: "tableCell"
            }]
        }
    }
}

export const tableComponentConfig = {
    model: {
        defaults: {
            stylable: [...textStyleProperties, "width", "height", "border", "background-color"],
            tagName: "table",
            traits: [{
                type: "addTableRow",
                name: "addTableRow"
            }, {
                type: "addTableCell",
                name: "addTableCell"
            }],
            attributes: {
                "data-gjs-type": "table"
            },
            components: [
                { type: "tableRow" },
            ]
        }
    }
}

export const pageComponentConfig = {
    model: {
        defaults: {
            stylable: [...textStyleProperties, "width", "height", "border", "background-color"],
            draggable: "[data-gjs-type='gjs-page-container'",
            tagName: "div",
            traits: [{
                type: "addPage",
                name: "addPage"
            }, {
                type: "removeCurrentPage",
                name: "removeCurrentPage"
            }],
            attributes: {
                // "data-gjs-type": "gjs-page-component"
            },
            components: [{
                type: "span"
            }]
        }
    }
}

export const facebookComponentConfig = {
    view: {
        onActive: () => {}
    },
    model: {
        defaults: {
            attributes: {
                'data-unselectable': true
            },
            components:
                `<svg data-unselectable=true xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path d="M512 256C512 114.6 397.4 0 256 0S0 114.6 0 256C0 376 82.7 476.8 194.2 504.5V334.2H141.4V256h52.8V222.3c0-87.1 39.4-127.5 125-127.5c16.2 0 44.2 3.2 55.7 6.4V172c-6-.6-16.5-1-29.6-1c-42 0-58.2 15.9-58.2 57.2V256h83.6l-14.4 78.2H287V510.1C413.8 494.8 512 386.9 512 256h0z"/>
                </svg>`
        }
    }
}

export const instagramComponentConfig = {
    view: {
        onActive: () => {}
    },
    model: {
        defaults: {
            attributes: {
                'data-unselectable': true,
            },
            components:
                `<svg data-unselectable=true xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                    <path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"/>
                </svg>`
        }
    }
}

export const twitterComponentConfig = {
    view: {
        onActive: () => {}
    },
    model: {
        defaults: {
            attributes: {
                'data-unselectable': true
            },
            components:
                `<svg data-unselectable=true xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path d="M459.4 151.7c.3 4.5 .3 9.1 .3 13.6 0 138.7-105.6 298.6-298.6 298.6-59.5 0-114.7-17.2-161.1-47.1 8.4 1 16.6 1.3 25.3 1.3 49.1 0 94.2-16.6 130.3-44.8-46.1-1-84.8-31.2-98.1-72.8 6.5 1 13 1.6 19.8 1.6 9.4 0 18.8-1.3 27.6-3.6-48.1-9.7-84.1-52-84.1-103v-1.3c14 7.8 30.2 12.7 47.4 13.3-28.3-18.8-46.8-51-46.8-87.4 0-19.5 5.2-37.4 14.3-53 51.7 63.7 129.3 105.3 216.4 109.8-1.6-7.8-2.6-15.9-2.6-24 0-57.8 46.8-104.9 104.9-104.9 30.2 0 57.5 12.7 76.7 33.1 23.7-4.5 46.5-13.3 66.6-25.3-7.8 24.4-24.4 44.8-46.1 57.8 21.1-2.3 41.6-8.1 60.4-16.2-14.3 20.8-32.2 39.3-52.6 54.3z"/>
                </svg>`
        }
    }
}
export const locationComponentConfig = {
    view: {
        onActive: () => {}
    },
    model: {
        defaults: {
            attributes: {
                'data-unselectable': true
            },
            components:
                `<svg data-unselectable=true xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M429.6 92.1c4.9-11.9 2.1-25.6-7-34.7s-22.8-11.9-34.7-7l-352 144c-14.2 5.8-22.2 20.8-19.3 35.8s16.1 25.8 31.4 25.8H224V432c0 15.3 10.8 28.4 25.8 31.4s30-5.1 35.8-19.3l144-352z"/></svg>`
        }
    }
}
export const phoneComponentConfig = {
    view: {
        onActive: () => {}
    },
    model: {
        defaults: {
            attributes: {
                'data-unselectable': true
            },
            components:
                `<svg data-unselectable=true xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.-->
                    <path d="M280 0C408.1 0 512 103.9 512 232c0 13.3-10.7 24-24 24s-24-10.7-24-24c0-101.6-82.4-184-184-184c-13.3 0-24-10.7-24-24s10.7-24 24-24zm8 192a32 32 0 1 1 0 64 32 32 0 1 1 0-64zm-32-72c0-13.3 10.7-24 24-24c75.1 0 136 60.9 136 136c0 13.3-10.7 24-24 24s-24-10.7-24-24c0-48.6-39.4-88-88-88c-13.3 0-24-10.7-24-24zM117.5 1.4c19.4-5.3 39.7 4.6 47.4 23.2l40 96c6.8 16.3 2.1 35.2-11.6 46.3L144 207.3c33.3 70.4 90.3 127.4 160.7 160.7L345 318.7c11.2-13.7 30-18.4 46.3-11.6l96 40c18.6 7.7 28.5 28 23.2 47.4l-24 88C481.8 499.9 466 512 448 512C200.6 512 0 311.4 0 64C0 46 12.1 30.2 29.5 25.4l88-24z"/>
                </svg>`
        }
    }
}
export const emailComponentConfig = {
    view: {
        onActive: () => {}
    },
    model: {
        defaults: {
            attributes: {
                'data-unselectable': true
            },
            components:
                `<svg data-unselectable=true xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48H48zM0 176V384c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V176L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z"/></svg>`
        }
    }
}
export const webComponentConfig = {
    view: {
        onActive: () => {}
    },
    model: {
        defaults: {
            attributes: {
                'data-unselectable': true
            },
            components:
                `<svg data-unselectable=true xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M352 256c0 22.2-1.2 43.6-3.3 64H163.3c-2.2-20.4-3.3-41.8-3.3-64s1.2-43.6 3.3-64H348.7c2.2 20.4 3.3 41.8 3.3 64zm28.8-64H503.9c5.3 20.5 8.1 41.9 8.1 64s-2.8 43.5-8.1 64H380.8c2.1-20.6 3.2-42 3.2-64s-1.1-43.4-3.2-64zm112.6-32H376.7c-10-63.9-29.8-117.4-55.3-151.6c78.3 20.7 142 77.5 171.9 151.6zm-149.1 0H167.7c6.1-36.4 15.5-68.6 27-94.7c10.5-23.6 22.2-40.7 33.5-51.5C239.4 3.2 248.7 0 256 0s16.6 3.2 27.8 13.8c11.3 10.8 23 27.9 33.5 51.5c11.6 26 20.9 58.2 27 94.7zm-209 0H18.6C48.6 85.9 112.2 29.1 190.6 8.4C165.1 42.6 145.3 96.1 135.3 160zM8.1 192H131.2c-2.1 20.6-3.2 42-3.2 64s1.1 43.4 3.2 64H8.1C2.8 299.5 0 278.1 0 256s2.8-43.5 8.1-64zM194.7 446.6c-11.6-26-20.9-58.2-27-94.6H344.3c-6.1 36.4-15.5 68.6-27 94.6c-10.5 23.6-22.2 40.7-33.5 51.5C272.6 508.8 263.3 512 256 512s-16.6-3.2-27.8-13.8c-11.3-10.8-23-27.9-33.5-51.5zM135.3 352c10 63.9 29.8 117.4 55.3 151.6C112.2 482.9 48.6 426.1 18.6 352H135.3zm358.1 0c-30 74.1-93.6 130.9-171.9 151.6c25.5-34.2 45.2-87.7 55.3-151.6H493.4z"/></svg>`
        }
    }
}

export const buttonComponentConfig = {
    view: {
        onActive: () => {}
    },
    model: {
        defaults: {
            tagName: "button",
            attributes: {
                "data-gjs-type": "button",
                class: 'form__button'
            },
            components: {
                type: "text",
                content: "click me"
            }
        }
    }
}