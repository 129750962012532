import { useContext, useEffect, useRef } from "react";
import { BuilderContext } from "../../../helpers/constants";
import useAnchor from "../hooks/useAnchor";
import useBlur from "../hooks/useBlur";

const AnchorHover = () => {
    const { editor, selectedComponent, manager } = useContext(BuilderContext);
    const { linkId } = useAnchor(editor, selectedComponent);
    const thisRef = useRef(null);
    const hoveredComponent = useRef(null);
    useBlur(editor, linkId);


    function substractScrollFromAnchorHover() {
        const rect = hoveredComponent.current.view.el.getBoundingClientRect();

        thisRef.current.style["width"] = rect.width + "px";
        thisRef.current.style["height"] = rect.height + "px";
        thisRef.current.style["left"] = rect.left + "px";
        thisRef.current.style["top"] = rect.top + "px";
    }

    useEffect(() => {
        if(!editor) return;
        editor.on("component:hover", (component) => {
            if(!component) return;
            hoveredComponent.current = component;
            
            const rect = component.view.el.getBoundingClientRect();
            thisRef.current.style["width"] = rect.width + "px";
            thisRef.current.style["height"] = rect.height + "px";
            thisRef.current.style["left"] = rect.left + "px";
            thisRef.current.style["top"] = rect.top + "px";
        }) 
    }, [editor]);


    useEffect(() => {
        if(!editor) return;
        thisRef.current.style["display"] = (!linkId ? "none" : "block");

        const builderDocument = editor.Canvas.getDocument();
        if(linkId){
            builderDocument.addEventListener('scroll', substractScrollFromAnchorHover);
            return;
        }
        
        builderDocument.removeEventListener('scroll', substractScrollFromAnchorHover);
    }, [linkId]);

    // useEffect(() => {
    //     if (manager !== "styleManager") {
    //         //? Unblur all components
    //         const allComponents = editor.DomComponents.getWrapper().find('[data-type]');
    //         allComponents.forEach(component => blurComponent(component, false));
    //         return;
    //     }

    //     const selectedComponent = editor.getSelected();
    //     if (!selectedComponent || anchorHoverRef.current.style["display"] === "block") return;

    //     editor.on('component:selected', handleSelection);

    //     if (selectedComponent.getAttributes()['data-type'] === "section") {
    //         handleSelection(selectedComponent);
    //         return;
    //     }

    //     let sectionComponent = getSectionParent(selectedComponent);            
    //     if (!sectionComponent) {
    //         blurAllComponentsExcept(null);
    //     }

    //     handleSelection(sectionComponent);  
    // }, [manager, thisRef.current]);

    return (
        <>
            <div className="choose-anchor-popup text-center" style={{ display: (!linkId ? "none" : "block") }}>
                <span>
                    Choose an element
                    for the anchor
                </span>
            </div>
            <div className="anchor-hover" ref={thisRef} />
        </>
    );
}

export default AnchorHover;