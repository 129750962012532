import Icon from "../../../../../components/Icon/Icon";
import Modal from "../../../../../components/Modal/Modal";
import "../BuilderTopSection.scss";
import { useState, useRef, useEffect } from "react";
import { makeRequest } from "../../../moduleBuilder/handlers";

const BuilderPageTab = ({ editor, attributes, currentPage, setCurrentPage, setDeletePageId }) => {
    const inputRef = useRef(attributes['name']);
    const pageUrlRef = useRef(null);

    const [name, setName] = useState(attributes["name"]);
    const [currentPageUrl, setCurrentPageUrl] = useState("");
    const [pageSettingsModalOpen, setPageSettingsModalOpen] = useState(false);

    function isNameUnique() {
        return editor.Pages.getAll().filter((page) => page["attributes"]["name"] === inputRef.current.value).length <= 1;
    }

    function submitNameAndUrl(event) {
        if (event) event.preventDefault();

        if (!isNameUnique()) {
            alert("The name of every page needs to be unique");
            inputRef.current.focus();
            return false;
        }
        
        setName(inputRef.current.value);
        
        if(attributes["id"] === editor.Pages.getMain()["id"]) return true;

        const newUrl = pageUrlRef.current.value;
        if (!newUrl) {
            alert("Your page URL can't be empty");
            pageUrlRef.current.focus();
            return false;
        }
        if (newUrl.includes("/") || newUrl.includes("?")) {
            alert("Your page URL can't contain '/' or '?'");
            pageUrlRef.current.focus();
            return false;
        }

        const pageUrls = JSON.parse(localStorage.getItem("pageUrls")) || [];
        const pagesWithSameUrl = pageUrls.filter((page) => page["url"] === newUrl && page["id"] !== attributes["id"]);
        if (pagesWithSameUrl.length > 0) {
            alert("You already have a page with this URL");
            pageUrlRef.current.focus();
            return false;
        }

        const page = editor.Pages.get(attributes["id"]);
        if (page && name && isNameUnique()) {
            page.setName(name);
        }

        let urlUpdated = false;
        pageUrls.forEach((pageUrl) => {
            if (pageUrl["id"] === attributes["id"]) {
                pageUrl["url"] = newUrl;
                urlUpdated = true;
            }
        });

        if (!urlUpdated) {
            pageUrls.push({
                id: attributes["id"],
                url: newUrl,
            });
        }

        localStorage.setItem("pageUrls", JSON.stringify(pageUrls));
        alert("Page name and URL updated successfully!");
        setPageSettingsModalOpen(false);
        makeRequest("POST", "projects", "save", {
            projectData: localStorage.getItem("gjsProject"),
            pageUrls: localStorage.getItem("pageUrls"),
        });

        return true;
    }

    useEffect(() => {
        setName(attributes["name"]);
    }, [attributes]);

    useEffect(() => {
        if (!editor) return;
        editor.on("page:select", () => {
            const pageUrls = JSON.parse(localStorage.getItem("pageUrls")) || [];
            const selectedPageUrl = pageUrls.find((url) => url["id"] === editor.Pages.getSelected()["id"]);
            setCurrentPageUrl(selectedPageUrl ? selectedPageUrl["url"] : "");
            setPageSettingsModalOpen(false);
        });
    }, [editor]);

    return (
        <>
            <Modal
                className="change-url__modal"
                isOpen={pageSettingsModalOpen}
                setIsOpen={setPageSettingsModalOpen}
            >
                <form
                    className="grid-x grid-margin-x grid-margin-y align-center-middle"
                    onSubmit={submitNameAndUrl}
                >
                    <h3 className="cell text-center white">"{name}" Settings</h3>
                    <div className="cell">
                        <div className="grid-x grid-margin-x align-center-middle">
                            <label
                                htmlFor="name"
                                style={{ fontSize: "18px" }}
                                className="white text-center bold cell small-2"
                            >
                                Name:
                            </label>
                            <input
                                type="text"
                                name="name"
                                className="cell small-7 action__input"
                                ref={inputRef}
                            />
                            <div className="cell small-1"></div>
                        </div>
                    </div>
                    {(attributes["id"] !== editor.Pages.getMain()["id"]) &&
                        <div className="cell">
                            <div className="grid-x grid-margin-x align-center-middle">
                                <label
                                    htmlFor="url"
                                    style={{ fontSize: "18px" }}
                                    className="white text-center bold cell small-2"
                                >
                                    URL:
                                </label>
                                <input
                                    placeholder={currentPageUrl}
                                    name="url"
                                    className="cell small-7 action__input"
                                    type="text"
                                    ref={pageUrlRef}
                                />
                                <div className="cell small-1"></div>
                            </div>
                        </div>
                    }
                    <button type="submit" className="action cell small-5">
                        Save
                    </button>
                </form>
            </Modal>

            <div
                className={
                    "cell small-3 large-2 builder__page-tab " +
                    (currentPage === attributes["id"] ? "builder__page-tab--active" : "")
                }
            >
                <div className="grid-x align-center-middle">
                    <div
                        className="cell small-8 flex"
                        style={{ justifyContent: "left", paddingLeft: "6%", height: "100%", userSelect: "none" }}
                        onClick={() => setCurrentPage(attributes["id"])}
                    >
                        <span className="tab__text">{name}</span>
                    </div>
                    <div className="cell small-1 flex hover-rotate--wrapper">
                        {/* {currentPage === attributes["id"] && attributes["id"] !== editor.Pages.getMain()["id"] && ( */}
                            <Icon
                                className="hover-rotate"
                                name="gear"
                                onClick={() => setPageSettingsModalOpen(true)}
                            />
                        {/* )} */}
                    </div>
                    <div className="cell small-1"></div>

                    <div className="cell small-1 flex hover-scale">
                        {attributes["id"] !== editor.Pages.getMain()["id"] && (
                            <Icon
                                name="x"
                                className="builder__page-tab__delete"
                                onClick={() => setDeletePageId(attributes["id"])}
                            />
                        )}
                    </div>
                    <div className="cell small-1"></div>
                </div>
            </div>
        </>
    );
};

export default BuilderPageTab;