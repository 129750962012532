import { useContext, useEffect, useState } from "react";
import { BuilderContext, REPLACEABLE_TYPES, ICONS } from "../../../../helpers/constants";
import "./ReplaceElementModal.scss";
import ReplaceableTypeBox from "./ReplaceableTypeBox";

const ReplaceElementModal = () => {
    const { 
        replacingElement: visible,
        setReplacingElement: setVisible,
        builderMode, 
        editor, 
        selectedComponent, 
    } = useContext(BuilderContext);
    const [newComponentType, setNewComponentType] = useState(null);
    const [showIconModal, setShowIconModal] = useState(false);
    const [iconBlocks, setIconBlocks] = useState([]);
    const [selectedIcon, setSelectedIcon] = useState(null);
 
    const isTransparent = (color) => {
        // Check if the color is an rgba value with a transparent alpha channel
        return color.startsWith('rgba') && color.endsWith(', 0)');
    };

    const findFirstNonTransparentParentBackground = (comp) => {
        let parent = comp.parent();
        while (parent && parent.getEl()) {
            const parentBackground = window.getComputedStyle(parent.getEl()).backgroundColor;
            if (!isTransparent(parentBackground)) {
                return parentBackground; // Return the first non-transparent background color
            }
            parent = parent.parent(); // Continue to the next parent
        }
        return 'rgb(255, 255, 255)'; // Default to white if all parents are transparent
    };

    function replaceElement(ev) {
        ev.preventDefault();
        if (!newComponentType && !selectedIcon) return;

        let componentToReplace = selectedComponent;
        while(REPLACEABLE_TYPES.includes(componentToReplace.parent().get('type')) || 
        REPLACEABLE_TYPES.includes(componentToReplace.parent().get('tagName'))) {
            componentToReplace = componentToReplace.parent();
        }

        let replacedCss = componentToReplace.getStyle();

        const collection = componentToReplace.collection,
            at = collection.indexOf(componentToReplace);

        if (selectedIcon) {
            const iconBlock = iconBlocks.find(block => block.get('id') === selectedIcon);
            const blockContent = iconBlock.get('content');
            const background = findFirstNonTransparentParentBackground(componentToReplace);
            if (background === "rgb(0, 0, 0)") {
                replacedCss.fill = "rgb(255, 255, 255)";
            }
            collection.add(blockContent, { at });
        } else {
            //! if you replace an h1 it places the new element in the h1
            let content = "";
            if (newComponentType !== "video") content = "text";
            collection.add({ type: newComponentType, content: content }, { at });
        }

        collection.remove(componentToReplace);
        collection['models'][at].setStyle(replacedCss);
        editor.select(collection["models"][at]);
        setVisible(false);
        setShowIconModal(false);
        setNewComponentType(null);
        setSelectedIcon(null);
    }
    useEffect(() => {
        if (!visible) {
            setNewComponentType(null);
            setSelectedIcon(null);
        }
    }, [visible]);

    useEffect(() => {
        if (!visible) return;
        const blocks = editor.BlockManager.getAll();
        const icons = blocks.filter(block => ICONS.includes(block.get('id')));
        setIconBlocks(icons);
    }, [visible]);
    if(builderMode === 'admin' || !editor || !selectedComponent || !visible)
        return <></>;
    return (
        <form onSubmit={replaceElement} className='builder__replace-element-modal__form grid-y align-center'>
            <div className="replaceable-type-container grid-x grid-margin-x grid-margin-y align-center">
                {REPLACEABLE_TYPES.filter(type => !ICONS.includes(type)).map((type, index) => (
                    <ReplaceableTypeBox 
                        key={index} 
                        type={type} 
                        activeType={newComponentType} 
                        setActiveType={() => {
                            if (type === "icon") {
                                setShowIconModal(true);
                            } else if (ICONS.includes(type)) {
                                setShowIconModal(true);
                            } else {
                                setNewComponentType(type);
                            }
                        }} 
                    />
                ))}
            </div>
            {showIconModal && (
                <div className="icon-modal">
                    <div className="icon-container grid-x grid-margin-x grid-margin-y align-center">
                        {iconBlocks.map((block, index) => (
                            <div 
                                key={index}
                                className={`cell select__element white text-center small-6 ${selectedIcon === block.get('id') ? "box--active" : ""}`}
                                onClick={() => setSelectedIcon(block.get('id'))}
                                dangerouslySetInnerHTML={{ __html: block.get('label') }}
                            />
                        ))}
                    </div>
                    <div className="grid-x grid-margin-x align-center">
                        <button className="action__neutral cell small-6" onClick={() => {
                                setShowIconModal(false)
                                setSelectedIcon(null);
                            }
                        }>
                            Cancel
                        </button>
                        <button className="action cell small-6" onClick={replaceElement}>
                            Submit
                        </button>
                    </div>
                </div>
            )}
            <div className="grid-x grid-margin-x align-center">
                <button className="action__neutral cell small-6" onClick={() => setVisible(false)}>
                    Cancel
                </button>
                <button className="action cell small-6" type="submit">
                    Submit
                </button>
            </div>
        </form>
    );
}

export default ReplaceElementModal;