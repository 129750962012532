import { addLinkToNavBar, getComponent } from "../../../helpers/builderFunctions";

export const TraitPlugin = (editor, {
        builderMode, 
        changeManager 
    }) => {
    const traitManager = editor.TraitManager;

    // traitManager.addType("animation". {
    //     createInput
    // })

    traitManager.addType("data-email", {
        createInput({ trait, component }) {
            const input = document.createElement("input"),
                attributes = component.getAttributes();
            input.type = "text";
            input.value = attributes['data-email'];
            input.addEventListener("change", () => {
                component.getEl().setAttribute("data-email", input.value);
            });

            return input;
        }
    })

    traitManager.addType("product", {
        createInput({ trait, component }) {
            const selectElement = document.createElement("select"),
                products = [{
                    id: 1,
                    name: "Product 1",
                    price: 3.75
                }, {
                    id: 2,
                    name: "Product 2",
                    price: 12.50
                }];
        
            products.forEach(product => {
                const productElement = document.createElement("option");
                productElement.setAttribute("value", JSON.stringify(product));
                productElement.innerText = product['name'];
                selectElement.appendChild(productElement);
            })

            selectElement.addEventListener("change", (ev) => {
                const nameElement = component.getEl().getElementsByClassName("productName"),
                    priceElement = component.getEl().getElementsByClassName("productPrice"),
                    productData = JSON.parse(ev.target.value);

                nameElement.innerHTML = productData['name'];
                priceElement.innerHTML = productData['price'];
            });

            return selectElement;
        }
    })

    traitManager.addType("innerText", { 
        createInput({ trait, component}) {
            const input = document.createElement("textarea");
            input.classList.add('action__input');
            input.classList.add('builder__text-area');
            input.value = component.getEl().innerText;
            input.addEventListener("change", (ev) => {
                if(ev.target.value.length === 0) {
                    alert("Your text field can't be empty");
                    input.value = component.getEl().innerText;
                    return;
                }
                component.empty();
                component.append(ev.target.value);
            })

            // const targetNode = document.getElementById("some-id");
            const config = { characterData: true, attributes: false, childList: false, subtree: true };
            const callback = (mutationList, observer) => {
                for (const mutation of mutationList) {
                    if (mutation.type === "characterData")
                        input.value = component.getEl().innerText;
                }
            };

            const observer = new MutationObserver(callback);
            observer.observe(component.getEl(), config);

            return input;
        }
    })

    traitManager.addType("animation", {
        createInput({ trait, component }) {
            const options = [
                { id: "none", name: "None" },
                { id: "bounce", name: "Bounce" }
            ];
            const el = document.createElement("div");
            el.innerHTML = `
                <select class="animation-type">
                    ${options.map(opt => `<option value="${opt.id}>${opt.name}</option>`).join('')}
                </select>
            `;

            const inputAnimationType = el.querySelector('animation-type');
            inputAnimationType.addEventListener("change", ev => {
                // switch(ev.target.value) {
                //     case 'none':
                //         break;
                //     case 'bounce':
                //         break;
                //     default:
                //         break;
                // }
                if(ev.target.value === "none") {
                    //TODO: remove animation class
                    return;
                }
                component.addClass(`animation--${ev.target.value}`);
            })
            return el;
        }
        // type: "select",
        // label: "Animation",
        // name: "animation",
        // options: [
        //     { value: 'bounce', name: "Bounce" }
        // ]
        // createInput({ trait, component }) {
            /*
            *    - trigger
            *        :hover, :click, 
            *   - type of animation
            *       bounce
            *   - delay
            *   - duration
            *   
            */
            
        // }
    })

    traitManager.addType("addPage", {
        createInput({ trait, component }) {
            const button = document.createElement("button")
            button.innerText = "addPage";
            button.classList.add("active")
            button.addEventListener("click", () => {
                const pageContainer = component.parent();
                pageContainer.append({ type: "pageComponent" });

                // Create a button and link it to the page
                // Make pages visible only when active
                //      default: 1
            })
            return button;
        }
    })

    traitManager.addType("removeCurrentPage", {
        createInput({ trait, component }) {
            const button = document.createElement("button")
            button.innerText = "removeCurrentPage";
            button.classList.add("active")

            button.addEventListener("click", () => {
                component.remove();
            })
            return button;
        }
    })

    traitManager.addType("carousel", {
        createLabel({ trait, component }) {
            const div = document.createElement("div"),
                indexLabel = document.createElement("span"),
                sizeLabel = document.createElement("span");

            div.appendChild(indexLabel);
            div.appendChild(sizeLabel);

            return div;
        },
        createInput({ trait, component }) {
            const div = document.createElement("div"),
                addButton = document.createElement("button"),
                removeButton = document.createElement("button");


            addButton.innerHTML = "add";
            addButton.classList.add("add__carousel__img");

            removeButton.innerHTML = "remove";
            removeButton.classList.add("remove__carousel__img");

            let itemContainer = component.parent();
            if (component.get("type") === "carousel")
                itemContainer = component.components().filter(component => component.getAttributes()["class"] === "carousel-item-container")[0];

            addButton.addEventListener("click", () => {
                const newItem = editor.addComponents({ type: "carousel-item" })[0],
                    currIndex = itemContainer.getEl().getAttribute("data-index");
                itemContainer.append(newItem);

                const items = itemContainer.components()["models"];

                items[currIndex].getEl().style.display = "none";
                items[items.length - 1].getEl().style.display = "flex";
                itemContainer.getEl().setAttribute("data-index", items.length - 1);
                // open asset manager to add an image to the new item
            })

            removeButton.addEventListener("click", () => {
                let index = itemContainer.getEl().getAttribute("data-index"),
                    items = itemContainer.components()["models"];

                if (items.length <= index || items.length <= 1)
                    return;
                items[index].remove();
                items = itemContainer.components()["models"];
                items[0].getEl().style.display = "flex";
                itemContainer.getEl().setAttribute("data-index", 0);
            })

            div.appendChild(removeButton);
            div.appendChild(addButton);
            return div;
        }
    })

    traitManager.addType("addListItem", {
        createInput({ trait, component }) {
            const button = document.createElement("button");
            button.className ="trait-button";
            button.innerHTML = "add item";

            button.addEventListener("click", () => {
                let listContainer = component;
                if (component.get("type") === "text")
                    listContainer = component.parent().parent();
                else if (component.get("type") === "list-item")
                    listContainer = component.parent()

                listContainer.append({ type: "list-item" });
            })

            return button;
        }
    })

    traitManager.addType("addTableRow", {
        createInput({ trait, component }) {
            const button = document.createElement("button");
            button.className = "trait-button"
            button.innerHTML = "Add row";

            button.addEventListener("click", () => {
                let table = component;
                if (component.get("type") === "text")
                    table = component.parent().parent().parent();
                else if (component.get("type") === "tableCell")
                    table = component.parent().parent();
                else if (component.get("type") === "tableRow")
                    table = component.parent();

                const row = table.append({ type: "tableRow" })[0];
                if (table.get("components").length > 0) {
                    const firstRow = table.get("components")["models"][0];
                    firstRow.get("components").each((currRow, index) => {
                        if (index === 0)
                            return;

                        row.append({ type: "tableCell" })
                    })
                }
            })
            return button;
        }
    })

    traitManager.addType("addTableCell", {
        createInput({ trait, component }) {
            const button = document.createElement("button");
            button.className="trait-button"
            button.innerHTML = "Add col";

            button.addEventListener("click", () => {
                let table = component;
                if (component.get("type") === "text")
                    table = component.parent().parent().parent();
                else if (component.get("type") === "tableCell")
                    table = component.parent().parent();
                else if (component.get("type") === "tableRow")
                    table = component.parent();

                table.get("components").each(row => row.append({ type: "tableCell" }));
            })

            return button;
        }
    })

    traitManager.addType("addRow", {
        createInput({ trait, component }) {
            if(builderMode !== "admin")
                return "";
            const button = document.createElement("button");
            button.className = "trait-button";
            button.innerHTML = "Add row &darr;";
            button.addEventListener("click", () => {
                const type = component.get("type");
                if (type === "cell")
                    component.parent().parent().append({ type: "row" })
                else if (type === "row")
                    component.parent().append({ type: "row" });
                else if (type === "container")
                    component.append({ type: "row" })

            });
            return button;
        }
    })

    traitManager.addType("addCol", {
        createInput({ trait, component }) {
            if(builderMode !== "admin")
                return "";
            const button = document.createElement("button");
            button.className = "trait-button";
            button.innerHTML = "Add col &rarr;";
            button.addEventListener("click", () => {
                const type = component.get("type");
                if (type === "cell")
                    component.parent().append({ type: "cell" })
                else if (type === "row")
                    component.append({ type: "cell" })

            });
            return button;
        }
    })

    traitManager.addType("setHref", {
        createInput({ trait, component }) {
            const container = document.createElement("div"),
                linkTypeSelect = document.createElement("select"),
                options = ["url", "page", "element", "file"],
                goToLink = document.createElement("button"),
                attributes = component.getAttributes(),
                inputs = {
                    "url": document.createElement("input"),
                    "page": document.createElement("select"),
                    "element": document.createElement("button"),
                    "file": document.createElement("file")
                };
            //* Choose link type options
            options.forEach(optionText => {
                const currOption = document.createElement("option")
                currOption.value = optionText;
                currOption.text = optionText;
                linkTypeSelect.appendChild(currOption);
            })
            inputs['url'].placeholder = 'https://';

            const pages = editor.Pages.getAll();

            pages.forEach(page => {
                const currOption = this.addPage(page);
                inputs["page"].appendChild(currOption);
            });

            editor.on("page:add", page => {
                const currOption = this.addPage(page)
                inputs["page"].appendChild(currOption);
            });

            //* Update the href input
            if (attributes["href"])
                inputs["url"].value = attributes["href"];

            inputs["url"].style.padding = "5px";
            inputs["url"].style.fontSize = "1rem";
            inputs["url"].addEventListener("change", () => {
                let hrefValue = inputs["url"].value;
                if (!hrefValue.includes("https://") && !hrefValue.includes("http://"))
                    hrefValue = `https://${hrefValue}`;
                component.addAttributes({ href: hrefValue });
            })

            inputs["page"].addEventListener("change", () => {
                const selectedPage = inputs["page"].selectedOptions[0];
                component.addAttributes({ href: `page:${selectedPage.getAttribute("data-page-id")}` })
            })

            inputs["element"].innerText = "Choose anchor element";
            inputs["element"].classList.add("action");
            // inputs["element"].addEventListener("click", () => {
            //     setLinkId(attributes["id"]);
            // })
            inputs['element'].addEventListener("click", () => editor.runCommand("component:anchor"));

            linkTypeSelect.addEventListener("change", () => {
                for (const index in inputs) {
                    if (index !== linkTypeSelect.value)
                        inputs[index].style.display = "none";
                    else
                        inputs[linkTypeSelect.value].style.display = "block";
                }
            })

            inputs["file"].innerText = "Link file";
            inputs["file"].classList.add("action");
            inputs["file"].addEventListener("click", () => {
                editor.AssetManager.open({
                    select(asset) {
                        const assetURL = asset.get("src");
                        if (assetURL) {
                            component.addAttributes({ href: assetURL });
                        }
                    }
                });
                editor.AssetManager.open();
            });


            goToLink.innerText = "Follow link";
            goToLink.classList.add("action");
            goToLink.addEventListener("click", () => {
                const href = component.getAttributes()["href"];

                if (!href) return;
                if (linkTypeSelect.value === "url" || linkTypeSelect.value === "file")
                    window.open(href, "_blank");
                else if (linkTypeSelect.value === "page") {
                    editor.Pages.select(href.split(':')[1]);
                } else {
                    const linkTarget = getComponent(editor, href);
                    if (!linkTarget)
                        return;
                    linkTarget.getEl().scrollIntoView();
                }
            })


            container.appendChild(linkTypeSelect);
            for (const index in inputs) {
                inputs[index].style.display = "none";
                container.appendChild(inputs[index]);
            }

            let hrefType = "element";
            
            if (attributes["href"].includes("."))
                hrefType = "url";
            if (attributes["href"].includes("page:"))
                hrefType = "page";

            inputs[hrefType].style.display = "block";
            linkTypeSelect.value = hrefType;
            container.appendChild(goToLink);
            return container;
        },
        addPage(page) {
            const currOption = document.createElement("option");
            currOption.innerText = page.attributes["name"];
            currOption.setAttribute("data-page-id", page.attributes["id"]);
            return currOption;
        }
    })
    
    traitManager.addType("openAsset", {
        createInput({ trait, component }) {
            const button = document.createElement("button");
            button.className = 'trait-button'
            button.innerHTML = "Change Image";
            button.addEventListener("click", () => {
                changeManager("");
                editor.AssetManager.open({
                    select(asset, complete) {
                      const selected = editor.getSelected();
                      if (selected && selected.is('image')) {
                        selected.addAttributes({ src: asset.getSrc() });
                        complete && assetManager.close();
                      }
                    }
                });
            });
            return button;
        }
    })

    traitManager.addType("addLink", {
        createInput({ trait, component }) {
            const button = document.createElement("button");
            button.className = 'trait-button';
            button.innerHTML = "Add Link";
            button.addEventListener("click", () => addLinkToNavBar(component));
            return button;
        }
    })
}
