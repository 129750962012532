import {
    getColorSector,
    getColorSectorProperties,
    getBorderSector,
    getBorderSectorProperties,
    getTextSector,
    getTextSectorProperties,
    getAlignSector,
    getAlignSectorProperties,
} from "./Settings/DefaultSettings.js";

import {
    getOffsetSector,
    getOffsetSectorProperties,
    getSizeSector,
    getSizeSectorProperties,
    getPositionSector,
    getPositionSectorProperties
} from "./Settings/AdvancedSettings.js";

export const StylePlugin = (editor, {builderMode}) => {
    const styleManager = editor.StyleManager;
    function generateInterval(start, end, step) {
        const arr = [];
        for (let i = start; i <= end; i += step) {
            arr.push({
                name: `${i}px`,
                value: `${i}px`
            })
        }
        return arr;
    }
    
    function implementDefaultSettings(styleManager) {
        const borderPixels = generateInterval(0, 40, 1);
        const textPixels = generateInterval(2, 40, 2);
        const colorSector = styleManager.addSector('Color', getColorSector());
        const borderSector = styleManager.addSector('Border', getBorderSector());
        const textSector = styleManager.addSector('Text', getTextSector());
        const alignSector = styleManager.addSector('Align', getAlignSector());
        const positionSector = styleManager.addSector('Position', getPositionSector())

        colorSector.addProperty(getColorSectorProperties());
        borderSector.addProperty(getBorderSectorProperties(borderPixels));
        textSector.addProperty(getTextSectorProperties(textPixels));
        alignSector.addProperty(getAlignSectorProperties());
        positionSector.addProperty(getPositionSectorProperties());
    }

    function implementFullSettings(styleManager) {
    const sizeSector = styleManager.addSector('Size', getSizeSector());
        const offsetSector = styleManager.addSector('Offset', getOffsetSector());

        sizeSector.addProperty(getSizeSectorProperties());
        offsetSector.addProperty(getOffsetSectorProperties());
    }

    //deliver styleManager settings depending of what is the mode of
    if (builderMode !== "basic") {
        implementFullSettings(styleManager);
    }
    implementDefaultSettings(styleManager);
}