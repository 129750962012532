import { useState } from "react";
import { getUser, getJWToken } from "../../../helpers/handle_token";
import Cookies from "js-cookie";
import { USER_SECTION } from "../../../helpers/constants";
import { makeRequest } from "../../../helpers/handlers";
import Error from "../../../components/Error";

const RelogModal = ({ isOpen, setIsOpen, setIsButtonDisabled, exitProject }) => {
    const [isRelogFailed, setIsRelogFailed] = useState(false);
    const [relogBtnMsg, setRelogBtnMsg] = useState("Relog");
    const [err, setErr] = useState("");

    function relogProfile() {
        if (localStorage.getItem("isModuleBuilderActive"))
            return;
        //?FIX
        makeRequest(USER_SECTION.SECTION, USER_SECTION.RELOG, { username: getUser(getJWToken()) })
            .then((data) => {
                if (data["code"] !== 0) {
                    setIsRelogFailed(true);
                    setErr(data["message"]);
                    setRelogBtnMsg("Exit");
                    return;
                }
                setIsOpen(false);
                setIsButtonDisabled(false);
                Cookies.set("sessionJWT", data["data"]);
            })
    }

    if(!isOpen)
        return <></>;

    return (
        <div className="relog__modal">
            <div style={{ padding: "30px" }}>
                <h1 className="head-msg">Your session has expired!</h1>
                <p className="msg">Relog to continue.</p>
                {isRelogFailed && <Error message={err}></Error>}
                <p className="action" onClick={() => {
                    if (relogBtnMsg === "Relog") {
                        relogProfile();
                    } else {
                        exitProject();
                    }
                }}>{relogBtnMsg}</p>
            </div>
    </div>
    );
}

export default RelogModal;