import  jwt_decode from "jwt-decode";
import Cookies from 'js-cookie';
import { makeRequest } from "./handlers";
import { USER_SECTION } from "./constants";
import { addSection } from "./builderFunctions";

function isTokenExpired(token: any) {
    if (!token)
        return true;

    let decodedToken: any = jwt_decode(token!);
    let currentDate = new Date();
    if (!decodedToken.nbf && decodedToken.iss !== window.location.hostname) {
        Cookies.remove("sessionJWT");
        return true;
    }

    return (decodedToken.nbf * 1000 <= currentDate.getTime());
}

function setExpToCurrTime(token: any) {
    if (!token)
        return false;

    let decodedToken: any = jwt_decode(token!);
    let currentDate = new Date();

    decodedToken.nbf = currentDate.getTime();
    return true;
}

function isRegistered(token: any) {
    if (!token)
        return false;

    let decodedToken: any = jwt_decode(token!);
    return decodedToken.isRegistered;

}

function getTempExp(token: any) {
    if (!token || isTokenExpired(token!))
        return "";

    let decodedToken: any = jwt_decode(token!);
    return decodedToken.nbf;
}

function getUser(token: any) {
    if (!token) {
        return "";
    }

    let decodedToken: any = jwt_decode(token!);
    if (decodedToken.username) {
        return decodedToken.username;
    }

    return decodedToken.name;
}

function getIsActive(token: any) {
    if(!token) return false;

    let decodedToken: any = jwt_decode(token!);
    return decodedToken.isActive;
}

function getEmail(token: any) {
    if (!token || isTokenExpired(token!))
        return "";

    let decodedToken: any = jwt_decode(token!);
    return decodedToken.email;
}

function isUserAdmin(token: any) {
    if(localStorage.getItem("isModuleBuilderActive"))
        return false;
    return makeRequest(USER_SECTION.SECTION, USER_SECTION.VALIDATE_ROLE, {username: getUser(token)})
    .then((res: any) => {
        return (res["data"] === "admin");
    });
}

function getTokenData(token: any, propName: string) {
    if (!token || isTokenExpired(token!))
        return "";

    let decodedToken: any = jwt_decode(token!);
    if (!decodedToken[propName])
        return "";

    return decodedToken[propName];
}

function getJWToken() {
    // const jwtToken = localStorage.getItem("token");
    const jwtToken = Cookies.get('sessionJWT');
    if (!jwtToken)
        return null;


    return jwtToken!;
}

function singInWithGoogle(googleCredentioal: string) {
    Cookies.set("sessionJWT", googleCredentioal);
    if (!googleCredentioal || isTokenExpired(googleCredentioal!))
        return false;

    return true
}

function singInWithFacebook(responceJWT: string) {
    Cookies.set("sessionJWT", responceJWT);
    localStorage.setItem("isFacebookLogged", "true");
    if (!responceJWT || isTokenExpired(responceJWT!))
        return false;

    return true
}

function decodeToken(token: any) {
    if (!token)
        return "";

    return jwt_decode(token);
}


export {
    isTokenExpired, getUser, getTokenData, getJWToken, isUserAdmin,
    singInWithGoogle, decodeToken, singInWithFacebook, isRegistered,
    getEmail, getTempExp, setExpToCurrTime, getIsActive
}
