import * as settings from "./Settings/TypeSettings";

export const TypePlugin = (editor) => {
    editor.DomComponents.addType('image', settings.getImageComponentConfig(editor));
    editor.DomComponents.addType('span', settings.spanComponentConfig);
    editor.DomComponents.addType('list-item', settings.listItemConfig);
    editor.DomComponents.addType('list', settings.listComponentConfig);
    editor.DomComponents.addType('input', settings.inputComponentConfig);
    editor.DomComponents.addType('link', settings.linkComponentConfig);
    editor.DomComponents.addType('carousel-item', settings.carouselItemComponentConfig);
    editor.DomComponents.addType('carousel', settings.carouselComponentConfig);
    editor.DomComponents.addType('cell', settings.cellComponentConfig);
    editor.DomComponents.addType('row', settings.rowComponentConfig);
    editor.DomComponents.addType('container', settings.containerComponentConfig);
    editor.DomComponents.addType('tableCell', settings.tableCellComponentConfig);
    editor.DomComponents.addType('tableRow', settings.tableRowComponentConfig);
    editor.DomComponents.addType('table', settings.tableComponentConfig);
    editor.DomComponents.addType('pageComponent', settings.pageComponentConfig);
    // editor.DomComponents.addType('text', settings.textComponentConfig);
    editor.DomComponents.addType('facebook', settings.facebookComponentConfig);
    editor.DomComponents.addType('instagram', settings.instagramComponentConfig);
    editor.DomComponents.addType('twitter', settings.twitterComponentConfig);
    editor.DomComponents.addType('location', settings.locationComponentConfig);
    editor.DomComponents.addType('phone', settings.phoneComponentConfig);
    editor.DomComponents.addType('email', settings.emailComponentConfig);
    editor.DomComponents.addType('web', settings.webComponentConfig);
    editor.DomComponents.addType('button', settings.buttonComponentConfig);
    editor.DomComponents.addType('h1', settings.h1ComponentConfig);
    // editor.DomComponents.addType('pageContainer', settings.pageContainerComponentConfig);
}