import ReactDOM from 'react-dom/client';
import App from './App';
import "./index.scss";
import { QueryClient, QueryClientProvider } from 'react-query'
import { GoogleOAuthProvider } from "@react-oauth/google";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false, // default: true
    },
  },
});
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <GoogleOAuthProvider clientId="424425392601-hrfcncdpfrueul5ns3818hc3367n3fbh.apps.googleusercontent.com">
    <QueryClientProvider client={queryClient}>
      <App />
    </QueryClientProvider>
  </GoogleOAuthProvider>
);