import ModuleBuilder from "./pages/builder/moduleBuilder/ModuleBuilder";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

const ModuleRoute = () => {
    return (
        <Router>
            <Routes>
                <Route path="*" element={<ModuleBuilder />}/>
                <Route path="/" element={<ModuleBuilder />}/>
                {/* <ModuleBuilder /> */}
            </Routes>
        </Router>
    );
}

export default ModuleRoute;