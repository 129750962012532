import "./Loader.scss"

const Loader: React.FC = () => {
    return(
        <div className="grid-x align-center align-middle loader-background">
            <div className="newtons-cradle">
                <div className="newtons-cradle__dot"></div>
                <div className="newtons-cradle__dot"></div>
                <div className="newtons-cradle__dot"></div>
                <div className="newtons-cradle__dot"></div>
            </div>
        </div> 
    )
}
export default Loader;